import {Grid, Typography} from "@mui/material";
import {CenteredCardLayout} from "../../components/CenteredLayout";
import "./TransactionDetails.css"
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {TransactionService} from "../../services/payments/TransactionService";
import {TransactionDTO} from "../../models/payments/TransactionDTO";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import { strings } from "../../localization/Localization";
export function TransactionDetails(){
    const {id} = useParams();
    const [transactionDTO, setTransactionDTO] = useState<TransactionDTO>()
    useEffect(()=>{
        if(id){
            TransactionService.getTransactionById(+id).then(res=>{
                setTransactionDTO(res);
            });
        }
    }, [])

    return(
        <>
            <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0}>
                <Grid item lg={8} md={12} xs={12}>
                    <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content">
                        <Grid container className="mt-5">
                            <Grid item lg={6} md={12} xs={12}>
                                <Typography className="typography-label mb-1">{strings.transactionId}</Typography>
                                <Typography className="typography-value mb-4">{transactionDTO?.transactionId}</Typography>

                                <Typography className="typography-label mb-1">{strings.status}</Typography>
                                <Typography className="typography-value mb-4">{transactionDTO?.transactionStatus?.description}</Typography>

                                <Typography className="typography-label mb-1">{strings.type}</Typography>
                                <Typography className="typography-value mb-4">{transactionDTO?.transactionType?.description}</Typography>

                                <Typography className="typography-label mb-1">{strings.dateOfCreation}</Typography>
                                <Typography className="typography-value mb-4">{DateTimeUtils.formatDateObject(transactionDTO?.dateCreated)}</Typography>

                                <Typography className="typography-label mb-1">{strings.dateOfApproval}</Typography>
                                <Typography className="typography-value mb-4">{DateTimeUtils.formatDateObject(transactionDTO?.dateApproved)}</Typography>

                            </Grid>
                            <Grid item lg={6} md={12} xs={12}>
                                <Typography className="typography-label mb-1">{strings.fromUser}</Typography>
                                <Typography className="typography-value mb-4">{transactionDTO?.fromFirstName + " " + transactionDTO?.fromLastName}</Typography>

                                <Typography className="typography-label mb-1">{strings.fromWallet}</Typography>
                                <Typography className="typography-value mb-4">{transactionDTO?.fromWallet}</Typography>

                                <Typography className="typography-label mb-1">{strings.toUser}</Typography>
                                <Typography className="typography-value mb-4">{transactionDTO?.toFirstName + " " + transactionDTO?.toLastName}</Typography>

                                <Typography className="typography-label mb-1">{strings.toWallet}</Typography>
                                <Typography className="typography-value mb-4">{transactionDTO?.toWallet}</Typography>

                                <Typography className="typography-label mb-1">{strings.amount}</Typography>
                                <Typography className="typography-value mb-4">{transactionDTO?.amount}</Typography>
                            </Grid>
                        </Grid>
                    </CenteredCardLayout>
                </Grid>
            </Grid>
        </>
    )
}