
import { useState } from "react";
import { Octagon } from "./Octagon";
import { Logo } from "./Logo";
import { Page } from "./Page";
import LoadingSpin from "react-loading-spin";


export function LoadingComponent() {
    const [setAnimation, setAnimationState] = useState(false);
    
 
    const loadingComponent = <div className="container">
        <p className="h5 text-center" style={{ color: '#FFF', fontFamily: 'Varsity, sans-serif', marginTop: '110px'}}>
            We have sent a code <br /> to your mobile
        </p>
        <div className="d-flex justify-content-center align-items-center mt-5">
            <LoadingSpin animationDuration="3s" primaryColor="#22FF90" secondaryColor="#920DAF" />
            </div>
    </div>

    return (
        <Page
            component={
                <>
                    <Logo className="mt-3" />
                    <div className="container">
                        <div className="d-flex justify-content-center mt-5">
                            <Octagon elements={loadingComponent} classAnimate={setAnimation ? "container-out" : "container-in"} size={450} />
                        </div>
                    </div>
                </>
            }
        />
    );
}
