import {StorageKeys} from "./StorageKeys";

export class StorageService {
    static saveAuthToken(token: string): void {
        localStorage.setItem(StorageKeys.AUTH_TOKEN, token);
    }

    static hasAuthToken(): boolean {
        return localStorage.getItem(StorageKeys.AUTH_TOKEN) !== null;
    }

    static clearAuthToken(): void {
        localStorage.removeItem(StorageKeys.AUTH_TOKEN);
    }
    static clearUserRole(): void {
        localStorage.removeItem(StorageKeys.USER_ROLE);
    }

    static saveUserRole(role: string): void {
        localStorage.setItem(StorageKeys.USER_ROLE, role);
    }

    static getUserRole(): string | null {
        return localStorage.getItem(StorageKeys.USER_ROLE);
    }
    
    static saveUserId(id: string): void {
        localStorage.setItem(StorageKeys.USER_ID, id);
    }

    static getUserId(): string | null {
        return localStorage.getItem(StorageKeys.USER_ID);
    }

    static setLanguage(language: string): void {
        localStorage.setItem(StorageKeys.ACTIVE_LANGUAGE, language);
    }

    static getLanguage(): string | null {
        return localStorage.getItem(StorageKeys.ACTIVE_LANGUAGE);
    }

    static hasLanguage(): boolean {
        return this.getLanguage() !== null;
    }
}
