import React, { useEffect, useState } from "react";
import { ListItem } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import { SidebarItem } from "./SidebarItem";
export interface SideBarProps {
    item: SidebarItem,
    className?: string,
}

export function SideBar(props: SideBarProps) {
    return (
        <>
            <List sx={{ maxWidth: "90px", zIndex: "5" }}>
                            <ListItem key={props.item.icon} disablePadding
                                aria-haspopup="true"
                            >
                                <div>
                                    <ListItemButton component={Link} to={props.item.path}>
                                        <ListItemIcon
                                        >
                                            {props.item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={props.item.name} style={{ display: "none" }} />
                                    </ListItemButton>
                                </div>
                            </ListItem>
            </List>
        </>
    )
}
