
import { CountryDTO } from "../../models/config/CountryDTO";
import axiosInstance from "../../network/AxiosInstance";

export class CountryService {
    static async getAllCountries():Promise<Array<CountryDTO>>{
        const response = await axiosInstance.get<Array<CountryDTO>>(`/rest/country/all`);
        const data =response.data;
        return data.map((country)=>new CountryDTO(country))

    }
}