import { Page } from "../../components/Page";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Octagon } from "../../components/Octagon";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Routes } from "../../router/Routes";
import { useState } from "react";
import { strings } from "../../localization/Localization";


const formType = {
    FAN: "fan",
    CLUB: "club",
    LOGIN: "login"
}


export function Register() {

    const [setAnimation, setAnimationState] = useState(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();

    const chooseForm = (value: string) => {
        setAnimationState(true)

        setTimeout(() => {
            setAnimationState(false)
            if (value === formType.FAN) {
                navigate(Routes.REGISTERFAN);
            }
            else if (value === formType.CLUB) {
                navigate(Routes.REGISTERCLUBATHLETE);
            }
            else{
                navigate(Routes.LOGIN)
            }
        }, 600);
    }

    const chooseRegister = <div className="container mt-4">
        <Grid container direction="column" sx={{ marginTop: { sm: 1, md: 5, lg: 5, xl: 5 } }}>
            <Grid item sx={{ marginBottom: { xs: 1, sm: 3, md: 3 } }}>
                <Typography variant="h3" color="white">
                    {strings.register}
                </Typography>
                <Typography variant="body2" color="white">
                    {strings.addYourDetailsToRegister}
                </Typography>
            </Grid>
            <Grid item>
                <Box sx={{ marginBottom: { xs: 3, sm: 3, md: 3 }, marginTop: { xs: 3, sm: 3, md: 3} }}>
                    <Button className="btn-sm greenBtn octagonButton" onClick={() => chooseForm(formType.FAN)} style={{width:"100%"}}>
                        {strings.fans}
                    </Button>
                </Box>
                <Box sx={{ marginBottom: { xs: 2, sm: 3, md: 3 } }}>
                    <Button className="btn-sm greenBtn octagonButton" onClick={() => chooseForm(formType.CLUB)} sx={{width:"100%"}}>
                    {strings.clubsAthletes}
                    </Button>
                </Box>
            </Grid>
            <Grid item>
                <Typography variant="body2" color="white">
                    {strings.alreadyHaveAnAccount}
                    <Button component="label" style={{ color: 'var(--green)', fontSize: '12px', textDecoration: 'none', minWidth:"0px", padding:"3px" }} onClick={() => chooseForm('login')}>
                        {strings.login}
                    </Button>
                </Typography>
            </Grid>
        </Grid>
    </div>

    return (
        <Page 
            component={
                <>
                    <Logo className="mt-3" />
                    <div className="container">
                        <div className="d-flex justify-content-center mt-5">
                            {matches ?
                                <Octagon elements={chooseRegister} classAnimate={setAnimation ? "container-out" : "container-in"} size={550} />
                                :
                                <>{chooseRegister}</>}
                        </div>
                    </div>
                </>
            }
        />
    );
}
