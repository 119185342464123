import {createBrowserRouter} from "react-router-dom";
import { ErrorRoute } from "../pages/error/ErrorRoute";
import { AuthGuard } from "./guards/AuthGuard";
import { GuardedRoute } from "./guards/GuardedRoute";
import { NonAuthGuard } from "./guards/NonAuthGuard";
import { Routes } from "./Routes";
import { Roles } from "./Roles";
import { Login } from "../pages/login/Login";
import { FAQ } from "../pages/faqs/FAQ";
import { UnauthorizedPage } from "../pages/UnauthorizedPage";
import { Conversations } from "../pages/conversations/Conversations";
import {Profile} from "../pages/profile/Profile";
import { Main } from "../pages/main/Main";
import Transactions from "../pages/transactions/Transactions";
import {User} from "../pages/user/User";
import { Homepage } from "../pages/homepage/Homepage";
import { Register } from "../pages/register/Register";
import { RegisterFan } from "../pages/register/RegisterFan";
import { RegisterClubAthlete } from "../pages/register/RegisterClubAthlete";
import { TransactionsUser } from "../pages/transactions/TransactionsUser";
import {TransactionDetails} from "../pages/transactions/TransactionDetails";
import { ResetPasswordMobile } from "../pages/reset_password/ResetPasswordMobile";
import { NewPassword } from "../pages/reset_password/NewPassword";
import { ResetPasswordCode } from "../pages/reset_password/ResetPasswordCode";
import { LoadingComponent } from "../components/LoadingComponent";
import { Clubs } from "../pages/clubs/Clubs";
import { Athletes } from "../pages/athletes/Athletes";
import { SportAmbassadors } from "../pages/sport-ambassadors/SportAmbassadors";
import { SupportAuthGuard } from "./guards/FaqAuthGuard";
import { AddFAQ } from "../pages/faqs/AddFAQ";
import { ConversationsUser } from "../pages/conversations/ConversationsUser";
import { AddConversation } from "../pages/conversations/AddConversation";
import { VerifyCode } from "../pages/register/VerifyCode";
import { ConversationDetails } from "../pages/conversations/ConversationDetails";
import {Users} from "../pages/users/Users";
import {Supports} from "../pages/support/Supports";
import {Admins} from "../pages/admins/Admins";
import { Wallets } from "../pages/wallets/Wallets";
import {Affiliate} from "../pages/users/Affiliate";
import {ComingSoon} from "../pages/coming-soon/ComingSoon"

export function createRouter() {
    return createBrowserRouter([

        {
            path: Routes.HOMEPAGE,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<Homepage/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },

        {
            path: Routes.PROFILE,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Profile/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },

        {
            path: Routes.LOGIN,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<Login/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },

        {
            path: Routes.RESETPASSWORDMOBILE,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<ResetPasswordMobile/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },

        {
            path: Routes.RESETPASSWORDCODE,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<ResetPasswordCode/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },

        {
            path: Routes.LOADINGPAGE,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<LoadingComponent/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.COMINGSOON,
            element: (
                <GuardedRoute
                guards={[
                    new AuthGuard(),
                ]}
                component={<ComingSoon/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },

        {
            path: Routes.NEWPASSWORD,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<NewPassword/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },

        {
            path: Routes.REGISTER,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<Register/>}
                />
            ),
            errorElement: <ErrorRoute/>,
        },
        {
            path: Routes.MAIN,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Main />}
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.USER_WITH_ID,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<User />}
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.FAQ,
            element: (
                <GuardedRoute
                    guards={[
                        new SupportAuthGuard(),
                    ]}
                    component={<FAQ />}
                />
            ),
            errorElement: <ErrorRoute />,
        },

        {
            path: Routes.ADD_NEW_FAQ,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<AddFAQ/>}
                    requiredRoles={[String(Roles.support)]}
                />
            ),
            errorElement: <ErrorRoute />,
        },

        {
            path: Routes.CONVERSATIONS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Conversations />}
                    requiredRoles={[String(Roles.admin), String(Roles.support)]}
                />
            ),
            errorElement: <ErrorRoute />,
        },

        {
            path: Routes.CONVERSATIONS_USER,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ConversationsUser />}
                    requiredRoles={[String(Roles.athlete), String(Roles.club), String(Roles.sportAmbassador), String(Roles.user)]}
                />
            ),
            errorElement: <ErrorRoute />,
        },

        {
            path: Routes.CONVERSATION_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ConversationDetails />}
                />
            ),
            errorElement: <ErrorRoute />,
        },

        {
            path: Routes.ADD_NEW_CONVERSATION,
            element: (
                <GuardedRoute
                    guards={[
                        new SupportAuthGuard(),
                    ]}
                    component={<AddConversation/>}
                />
            ),
            errorElement: <ErrorRoute />,
        },

        {
            path: Routes.UNAUTHORIZED,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<UnauthorizedPage />}
                />
            )
        },
        {
            path: Routes.REGISTERFAN,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<RegisterFan />}
                />
            )
        },
        {
            path: Routes.REGISTERCLUBATHLETE,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<RegisterClubAthlete />}
                />
            )
        },
        {
            path: Routes.VERIFYCODE,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<VerifyCode />}
                />
            )
        },
        {
            path: Routes.TRANSACTIONS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Transactions />}
                    requiredRoles={[String(Roles.admin), String(Roles.support)]}
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.TRANSACTIONS_USER,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<TransactionsUser />}
                   
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.TRANSACTION_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<TransactionDetails />}

                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.CLUBS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Clubs />}

                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.ATHLETES,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Athletes />}

                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.SPORT_AMBASSADORS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<SportAmbassadors />}

                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.USERS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Users />}

                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.SUPPORTS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Supports />}

                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.ADMINS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Admins />}

                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.WALLET,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Wallets />}

                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.AFFILIATE,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Affiliate />}

                />
            ),
            errorElement: <ErrorRoute />,
        }
    ]);
}
