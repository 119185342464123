import Box from '@mui/material/Box';
import { Routes } from "../router/Routes";
import { StorageService } from "../storage/StorageService"
import { SideBar } from './Sidebar';
import { SidebarItem } from './SidebarItem';
import dashboardIcon from '../assets/images/dashboardIcon.svg'
import dashboardHoverIcon from '../assets/images/dashboardHoverIcon.svg'
import usersIcon from '../assets/images/usersIcon.svg'
import usersHoverIcon from '../assets/images/usersHoverIcon.svg'
import kixxIcon from '../assets/images/kixxIcon.svg'
import kixxHoverIcon from '../assets/images/kixxHoverIcon.svg'
import walletIcon from '../assets/images/walletIcon.svg'
import walletHoverIcon from '../assets/images/walletHoverIcon.svg'
import transactionsIcon from '../assets/images/transactionsIcon.svg'
import transactionsHoverIcon from '../assets/images/transactionsHoverIcon.svg'
import tradingIcon from '../assets/images/tradingIcon.svg'
import tradingHoverIcon from '../assets/images/tradingHoverIcon.svg'
import gamingIcon from '../assets/images/gamingIcon.svg'
import gamingHoverIcon from '../assets/images/gamingHoverIcon.svg'
import nftIcon from '../assets/images/nftIcon.svg'
import nftHoverIcon from '../assets/images/nftHoverIcon.svg'
import conversationsIcon from '../assets/images/conversationsIcon.svg'
import conversationsHoverIcon from '../assets/images/conversationsHoverIcon.svg'
import faqIcon from '../assets/images/faqIcon.svg'
import faqHoverIcon from '../assets/images/faqHoverIcon.svg'
import './styles/SidebarIcons.css'
import {Roles} from "../router/Roles";
import { CSSProperties } from '@nextui-org/react/types/theme';

export default function SidebarIcons(props: any) {

  function createStyle(primaryImg : any, secondaryImg : any){
    const imgStyle = { "--primary-img" : `url(${primaryImg})`, "--secondary-img" : `url(${secondaryImg})` } as CSSProperties
    return imgStyle
  }

  function buildItems(): SidebarItem[] | null {
    if (StorageService.hasAuthToken()) {
      if(StorageService.getUserRole()===Roles.admin){
        return [
          new SidebarItem(
              "Dashboard",
              Routes.MAIN,
              <img style={createStyle(dashboardIcon,dashboardHoverIcon)} className='iconImg'/>,
              [

              ],
               true
          ),
          new SidebarItem(
              "Users",
              Routes.CLUBS,
              <img style={createStyle(usersIcon,usersHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "Buy Kixx",
              Routes.COMINGSOON,
              <img style={createStyle(kixxIcon,kixxHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),

          new SidebarItem(
              "Wallet",
              Routes.WALLET,
              <img style={createStyle(walletIcon,walletHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "Transactions",
              Routes.TRANSACTIONS,
              <img style={createStyle(transactionsIcon,transactionsHoverIcon)} className='iconImg'/>,
              [

              ],
              true

          ),
          new SidebarItem(
              "Trading",
              Routes.COMINGSOON,
              <img style={createStyle(tradingIcon,tradingHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "Gaming",
              Routes.COMINGSOON,
              <img style={createStyle(gamingIcon,gamingHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "Nft",
              Routes.COMINGSOON,
              <img style={createStyle(nftIcon,nftHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),

        ];
      }
      else if(StorageService.getUserRole()===Roles.user){
        return [
          new SidebarItem(
              "Dashboard",
              Routes.MAIN,
              <img style={createStyle(dashboardIcon,dashboardHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "FAQ",
              Routes.FAQ,
              <img style={createStyle(faqIcon,faqHoverIcon)} className='iconImg'/>,
              [

              ],
              true

          ),
          new SidebarItem(
            "Conversations",
            Routes.CONVERSATIONS_USER,
            <img style={createStyle(conversationsIcon,conversationsHoverIcon)} className='iconImg'/>,
            [

            ],
            true

        )
        ]
      }
      else if(StorageService.getUserRole()===Roles.support){
        return [
          new SidebarItem(
              "Dashboard",
              Routes.MAIN,
              <img style={createStyle(dashboardIcon,dashboardHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "FAQ",
              Routes.FAQ,
              <img style={createStyle(faqIcon,faqHoverIcon)} className='iconImg'/>,
              [

              ],
               true

          ),
          new SidebarItem(
            "Conversations",
            Routes.CONVERSATIONS,
            <img style={createStyle(conversationsIcon,conversationsHoverIcon)} className='iconImg'/>,
            [

            ],
            true

        )
        ]
      }
      else if(StorageService.getUserRole()===Roles.club){
        return [
          new SidebarItem(
              "Dashboard",
              Routes.MAIN,
              <img style={createStyle(dashboardIcon,dashboardHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "FAQ",
              Routes.FAQ,
              <img style={createStyle(faqIcon,faqHoverIcon)} className='iconImg'/>,
              [

              ],
              true

          ),
          new SidebarItem(
              "Conversations",
              Routes.CONVERSATIONS_USER,
              <img style={createStyle(conversationsIcon,conversationsHoverIcon)} className='iconImg'/>,
              [

              ],
              true

          )
        ]
      }
      else if(StorageService.getUserRole()===Roles.athlete){
        return [
          new SidebarItem(
              "Dashboard",
              Routes.MAIN,
              <img style={createStyle(dashboardIcon,dashboardHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "FAQ",
              Routes.FAQ,
              <img style={createStyle(faqIcon,faqHoverIcon)} className='iconImg'/>,
              [

              ],
              true

          ),
          new SidebarItem(
              "Conversations",
              Routes.CONVERSATIONS_USER,
              <img style={createStyle(conversationsIcon,conversationsHoverIcon)} className='iconImg'/>,
              [

              ],
              true

          )
        ]
      }
      else if(StorageService.getUserRole()===Roles.sportAmbassador){
        return [
          new SidebarItem(
              "Dashboard",
              Routes.MAIN,
              <img style={createStyle(dashboardIcon,dashboardHoverIcon)} className='iconImg'/>,
              [

              ],
              true
          ),
          new SidebarItem(
              "FAQ",
              Routes.FAQ,
              <img style={createStyle(faqIcon,faqHoverIcon)} className='iconImg'/>,
              [

              ],
              true

          ),
          new SidebarItem(
              "Conversations",
              Routes.CONVERSATIONS_USER,
              <img style={createStyle(conversationsIcon,conversationsHoverIcon)} className='iconImg'/>,
              [

              ],
              true

          )
        ]
      }
      return []
    } else {
      return null;
    }
  }
  const items = buildItems();
  return (
      <Box className='sidebarMenu'>
        {items?.map((sideBarItem, index) => (
          <SideBar item={sideBarItem} key={index} className="sidebaritem" />
        ))}
      </Box>
  );
}
