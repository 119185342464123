import { TextField, InputLabel } from "@mui/material";
import { CustomSelect } from "../CustomSelect";
import { NullableCheckbox } from "../NullableCheckbox";
import { NullableDatePicker } from "../NullableDatePicker";
export function TableFilter({ row }: any) {
    return (
        <div>
            {row.type === "text" && (<>
                            <TextField 
                               sx={{
                                "& .MuiInput-root": {
                                    borderBottom: '1px solid white'
                                },
                            }} onChange={row.handleChange} variant={row.variant} label={row.label} inputProps={{ style: { fontSize: 15 } }}
                            />
            </>)}
            {row.type === "boolean" &&
                <NullableCheckbox value={row.value} onValueChanged={row.handleChange} />
            }
            {row.type === "number" &&
            <>
                            <TextField 
                                sx={{
                                    "& .MuiInput-root": {
                                        borderBottom: '1.5px solid white'
                                    },
                                }} onChange={row.handleChange} variant={row.variant} label={row.label} inputProps={{ style: { fontSize: 15 } }} type="number"
                            />
            </>
            }
            {row.type === "dropdown" &&
                <CustomSelect styling={row.styling} id={row.id} label={row.label} inputLabel={row.inputLabel} customWidth={row.customWidth} elements={row.elements} handleChange={row.handleChange} variant={row.variant} handleRemoveFilter={row.handleRemoveFilter} value={row.value} />
            }
            {row.type === "datePicker" &&
                <NullableDatePicker onChange={row.handleChange}></NullableDatePicker>
            }

        </div>
    )

}