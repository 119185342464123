export class ClubDTO {
    id: number
    representativeName: string;
    address: string;
    phoneNumber: string;
    shortDescription: string;
    name: string;
    constructor(json:any) {
        this.id=json.id
        this.representativeName=json.representativeName
        this.address=json.address
        this.phoneNumber=json.phoneNumber
        this.shortDescription=json.shortDescription
        this.name = json.name
    }
}