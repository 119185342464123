import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { strings } from "../../localization/Localization";
import { UserFilter } from "../../models/filters/users/UserFilter";
import { UserDTO } from "../../models/users/UserDTO";
import { Routes } from "../../router/Routes";
import { UserService } from "../../services/users/UserService";
import { PageTable } from "../../shared-components/table/PageTable";
import {UsersMenu} from "../users/UsersMenu";

export function Users() {
    const [firstName, setFirstName] = useState<string | undefined>(undefined);
    const [lastName, setLastName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
    const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
    const [users, setUsers] = useState<UserDTO[]>([])
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalElements, setTotalElements] = useState<number>(0);
    const navigate = useNavigate();
    function handleChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
        setFirstName(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handleChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
        setLastName(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handlePhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPhoneNumber(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handleEnableChange(event: boolean) {
        setEnabled(event);
        setPage(0);
    }
    const headerRows = [
        {
            id: "email",
            label: strings.emailAddress,
            search: true,
            type: "text",
            handleChange: handleChangeEmail,
            variant: "standard",
        },
        {
            id: "firstName",
            label: strings.firstName,
            search: true,
            type: "text",
            handleChange: handleChangeFirstName,
            variant: "standard",
        },
        {
            id: "lastName",
            label: strings.lastName,
            search: true,
            type: "text",
            handleChange: handleChangeLastName,
            variant: "standard",
        },
        {
            id: "enabled",
            label: strings.enabled,
            search: true,
            handleChange: handleEnableChange,
            type: "boolean",
        },
        {
            id: "phoneNumber",
            label: strings.mobileNumber,
            search: true,
            type: "text",
            handleChange: handlePhoneNumberChange,
            variant: "standard",
        }
    ];
    const rows = users.map((element) => {
        return {
            id: element.id,
            values: [
                {
                    type: "text",
                    name: "email",
                    value: element.email,
                },
                {
                    type: "text",
                    name: "First name",
                    value: element?.person?.firstName,
                },
                {
                    type: "text",
                    name: "Last name",
                    value: element?.person?.lastName,
                },
                {
                    type: "boolean",
                    name: "enabled",
                    value: element.enabled,
                },
                {
                    type: "text",
                    name: "phoneNumber",
                    value: element?.person?.phoneNumber,
                }
            ],
        };
    });
    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }


    useEffect(() => {
        async function getAllAthletes() {
            const response = await UserService.getAllUsersPaged(page, rowsPerPage, new UserFilter(email, enabled, firstName, lastName, phoneNumber))
            setUsers(response.content);
            setTotalElements(response.totalElements);
            setPage(page)
        }
        getAllAthletes()
            .then(() => {
            });
    }, [page, rowsPerPage, firstName, lastName, email, enabled, phoneNumber]);
    function handleOpen(id: number) {
        navigate(Routes.USER_WITH_ID.replace(":id", id.toString()));
    }
    return (
        <>
            <Grid
                container
                alignItems={"flex-start"}
                alignContent={"flex-start"}
                justifyContent={"center"}
                spacing={0}
                rowGap={0}
            >
                <Grid item xs={12} lg={8} md={12} sm={12}>
                    <CenteredCardLayout
                        footer={true}
                        navbar={true}
                        sidebar={true}
                        className="main-content"
                        header={true}
                        title={strings.users}
                    >
                        <UsersMenu/>
                        <div className="tableContainer">
                            <PageTable
                                headerRows={headerRows}
                                rows={rows}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                totalElements={totalElements}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={handleChangeRowsPerPage}
                                handleOpen={handleOpen}
                                clickable={true}
                            />
                        </div>
                    </CenteredCardLayout>
                </Grid>
            </Grid>
        </>

    )
}