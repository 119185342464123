import { CenteredCardLayout } from "../../components/CenteredLayout";
import profile from '../../assets/images/profilePicture.svg'
import './Main.css'
import { Button, Grid, MenuItem } from "@mui/material";
import kixxtokenSvg from '../../assets/images/kixxToken.svg'
import kixxtokenPng from '../../assets/images/kixxToken.png'
import octagonAffiliate from '../../assets/images/octagonAffiliate.png'
import euro from '../../assets/images/euroSlanted.png'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserDTO } from "../../models/users/UserDTO";
import { UserService } from "../../services/users/UserService";
import { StorageKeys } from "../../storage/StorageKeys";
import { TransactionService } from "../../services/payments/TransactionService";
import { TransactionDTO } from "../../models/payments/TransactionDTO";
import octagonBorder from '../../assets/images/octagonBorder.svg';
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { PageTable } from "../../shared-components/table/PageTable";
import { Routes } from "../../router/Routes";
import { AffiliateInfoDTO } from "../../models/affiliates/AffiliateInfoDTO";
import { ImageService } from "../../utils/ImageService";
import { LoggedUserTransactionsDTO } from "../../models/filters/payments/LoggedUserTransactionsDTO";
import { strings } from "../../localization/Localization";

export function Main() {
  const navigate = useNavigate();
  const token = localStorage.getItem(StorageKeys.AUTH_TOKEN)
  const [page, setPage] =useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [user,setUser] = useState<UserDTO>()
  const [transactions, setTransactions] = useState<TransactionDTO[]>([])
  const [totalElements, setTotalElements] =useState(0)
  const [affiliates, setAffiliates] = useState<AffiliateInfoDTO[]>([])
  const [countClubs, setCountClubs] = useState<number>();
  const [countAthletes, setCountAthletes] = useState<number>();
  const [countAmbassadors, setCountAmbassadors] = useState<number>();
  function getUserInfo(token: string | null) {
    if (token != null) {
      UserService.getLoggedUser(token)
          .then((res) => {
            setUser(res);
          })
          .catch((error) => {
          });
    }
  }
  useEffect(() => {
    getUserInfo(token);
    async function getAllTransactionsForUserPaged(page: number, token:string): Promise<void> {
      const response = await TransactionService.getAllTransactionsForUserPaged(
          page,
          rowsPerPage,
          new LoggedUserTransactionsDTO(undefined,undefined,undefined,null)
      );
      setTotalElements(response.totalElements);
      setTransactions(response.content);
      setPage(page)
    }
    async function getUserAffiliatyes(): Promise<void> {
      const response = await UserService.getUserAffiliates();
      setAffiliates(response)
    }
    if(token!==null){
      getAllTransactionsForUserPaged(page, token).then(_=>{})
    }
      getUserAffiliatyes().then(_ => {});
  }, [page, rowsPerPage]);

  useEffect(() => {
    getUserInfo(token);
    async function getCountOfClubs(): Promise<void> {
      const response = await UserService.getCountOfClubs();
      setCountClubs(response)
    }
    async function getCountOfAthletes(): Promise<void> {
      const response = await UserService.getCountOfAthletes();
      setCountAthletes(response)
    }
    async function getCountOfAmbassadrors(): Promise<void> {
      const response = await UserService.getCountOfAmbassadors();
      setCountAmbassadors(response)
    }
      getCountOfClubs().then(_ => {});
      getCountOfAthletes().then(_ => {});
      getCountOfAmbassadrors().then(_ => {});
  }, []);
  function navigateToProfile(){
    navigate("/profile")
  }
  function navigateToClubs(){
    navigate(Routes.CLUBS)
  }
  function navigateToAthletes(){
    navigate(Routes.ATHLETES)
  }
  function navigateToAmbassadors(){
    navigate(Routes.SPORT_AMBASSADORS)
  }
  function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
    setPage(newPage)
  }
  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  function handleRowClick(id: number){
    navigate(Routes.TRANSACTION_DETAILS.replace(":id", id.toString()));
}
  const rows = transactions.map((element) => {
    return {
      id: element.id,
      values: [
        {
          type: "text",
          name: "dateCreated",
          value:  DateTimeUtils.formatDate(element?.dateCreated?.toString())
        },
        {
          type: "text",
          name: "amount",
          value: element.amount
        },
        {
          type: "text",
          name: "dateModified",
          value: element.fromWallet,
        },
        {
          type: "text",
          name: "email",
          value: element.toWallet,
        },

      ],
    };
  })
  return (
      <>
        <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
          <Grid item xs={12} lg={8} md={12} sm={12}>
            <CenteredCardLayout footer={true} sidebar={true} navbar={true} className="main-content" dashboard={true}>
              <div className="users-horizontal-menu mt-3 pb-3">
              <MenuItem key={"1"}  onClick={navigateToClubs}>{strings.clubs}: {countClubs}</MenuItem>
              <MenuItem key={"2"}  onClick={navigateToAthletes}>{strings.athletes}: {countAthletes}</MenuItem>
              </div>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 3, sm: 10, md: 3 }} sx={{ textAlign: "left" }} className = "dashboardBottomContent">
                <Grid item xs={12} sm={12} md={12} xl={6} lg={6}>
                  <div className="d-flex justify-content-around">
                    {user?.profilePicture?.length !== 0 && (
                      <span className="poligonProfileDashboard link-success">
                        <label>
                          <img src={
                            'data:${imageType};base64,' +
                              ImageService.arrayBufferToBase64(user?.profilePicture)
                          }
                               alt=""
                               width="200px"
                               height="200px"
                          />
                        </label>
                      </span>
                        )}
                    {user?.profilePicture?.length ===0 &&(
                        <img src={profile} className="profilePicture" alt=""></img>
                    )}

                    <div>
                      <div className="whiteText pb-3">{strings.welcome} <br/><p>{user?.person && user?.person.firstName + " " + user?.person.lastName}</p>
                        <p>{user?.clubInfo && user?.clubInfo.representativeName }</p>
                        <p>{user?.athleteInfo && user?.athleteInfo.name}</p>
                      </div>
                      <Button className="greenBtn" onClick={navigateToProfile}>{strings.myProfile}</Button>
                    </div>
                  </div>
                  <div className="d-flex ml-3">
                  <div className="divcontainer mt-3 ml-3">
                  </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={12} xl={6}>
                <div className="greenDiv">
                    <h3>{strings.accountingBalance}</h3>
                  </div>
                  <div className="d-flex justify-content-around">
                    <div className="divcontainer mt-3">
                      <div>
                        <img src={kixxtokenSvg} alt="" style={{width:"67px"}} />
                        <img src={octagonBorder} style={{width:"67px"}}/>
                        <div className="tokens"><span className="whiteText">50 <br/> Token </span></div>
                      </div>
                      <div>
                        <img src={euro} alt="" style={{width:"67px", marginLeft:"25px"}} />
                        <img src={octagonBorder} style={{width:"67px"}}/>
                        <div className="euros"><span className="whiteText">200 </span><br/> Euros</div>
                      </div>
                    </div>
                  </div>
                </Grid>
              <Grid item xs={12} sm={12} lg={6} md={12} xl={6}>
                <div className="greenDiv mt-5">
                  <h3>{strings.transactionHistory}</h3>
                </div>
                <PageTable
                  searchRows={null}
                  rows={rows}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  totalElements={totalElements}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleOpen={handleRowClick}
                  clickable={true}
                />
                <p className="whiteText" style={{ cursor: "pointer" }} onClick={() => navigate(Routes.TRANSACTIONS_USER)}>{strings.more}</p>
              </Grid>
              <Grid item xs={12} sm={12} lg={6} md={12} xl={6}>
                <div className="greenDiv mt-5">
                  <h3>{strings.myAffiliates}</h3>
                </div>
                {affiliates.map((affiliate, index) => {
                  return (
                    <div key={index} className="affiliateTable greenTextUpperCase  mb-3 p-3">
                      <div className="affiliateName">
                        {affiliate?.profilePicture !== null && (
                          <span className="link-success">
                            <label>
                              <img className="poligonAffiliate"
                                src={
                                  `data:${'.jpg'};base64,` +
                                  ImageService.arrayBufferToBase64(affiliate?.profilePicture)
                                }
                                alt=""
                                width="250px"
                                height="250px"
                              />
                            </label>
                          </span>
                        )}
                        {affiliate?.profilePicture === null && (
                          <span className="link-success">
                            <label>
                              <img className="poligonAffiliate"
                                src={
                                  octagonAffiliate
                                }
                                alt=""
                                width="50px"
                                height="50px"
                              />
                            </label>
                          </span>
                        )}


                        <span className="whiteText alignAffiliateName">{affiliate.name}</span>
                      </div>
                      <div className="d-flex">
                      <span className="poligonAffiliate">
                       <label className="labelColor purpleText">
                        {affiliate.kixxTokens}
                       </label>
                      </span>
                      <img src={kixxtokenPng} alt="" className="tokenIcon"/>
                      &nbsp; &nbsp;
                      <span className="poligonAffiliate">
                       <label className="labelColor purpleText">
                        {affiliate.euros}
                       </label>
                      </span>
                      <img src={euro} alt="" className="tokenIcon"/>
                    </div>
                    </div>
                  )
                })}
              </Grid>

            </Grid>

          </CenteredCardLayout>
        </Grid>
         
        </Grid>
      </>
  )

}
