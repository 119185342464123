import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import '../../src/swal/Swal.css'
export const SwalService={
    showSwalMessage:async(title:string, message:string, icon:any)=>{
        const MySwal = withReactContent(Swal);
        await MySwal.fire({
            title: <strong>{title}</strong>,
            html:<> <p>{message}</p>   <Button className="btn-sm greenBtn" onClick={()=>Swal.close()}>OK</Button></>,
            iconHtml: icon,
            showConfirmButton:false,
            background: "var(--gradient)",
            customClass: {
              icon: 'no-border',  
            }
          });
        },
      

    }


    
