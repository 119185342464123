import { Box, Container, Grid } from "@mui/material";
import React, {useRef} from "react";
import CssBaseline from '@mui/material/CssBaseline';
import SidebarIcons from "./SidebarIcons";
import Navbar from '../components/Navbar';
import greenLayer from '../assets/images/GreenLayer.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import border from '../assets/images/Border.svg'
type LayoutProps = {
  children?: React.ReactNode;
  minWidth?: string;
  maxWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  className?: string;
  background?: string;
  footer?: boolean;
  header?: boolean;
  menu?: boolean;
  navbar?: boolean;
  sidebar?: boolean;
  title?: string;
  dashboard?: boolean;
};

export function CenteredCardLayout(props: LayoutProps) {
  const windowSize = useRef([window.innerWidth,window.innerHeight]);
  return (
    <>
      <CssBaseline />
      <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
        <Grid item className="sidebarIcon">
          {props.sidebar &&
            <SidebarIcons />
          }
        </Grid>
        <Grid item xs={12} lg={10} md={12} sm={12} xl={11} className="containerGrid">
          <Container disableGutters>
            <Box sx={ props.background ? { background: props.background} : { background: 'var(--gradient)'}} className={props.className + " boxShadow"}>
              {props.navbar &&
                <Navbar dashboard={props.dashboard} />
              }
              <div className="centeredLayoutContainer" style={{marginTop: props.dashboard ? "0" : "0"}}>
              {props.header && windowSize.current[0]<=1200 &&(
                <>
                <img src={greenLayer} alt="" className="greenLayer" />
                </>
              )}
              {props.title && (
                <div className="col-5" style={{width:"46%"}}>
                <h1 className="greenTextUpperCase mainTitle">{props.title}</h1>
                </div>
              )}

              <div className="children-content">
                {props.children}
              </div>
              </div>
              {props.footer && (
                <>
                <div className="footerDiv">
                <img src={border} alt="" className="greenLayer" />
                <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={5} rowGap={0}>
                <Grid item className="boxxUnitedFooter" md={3.5} lg={3.5} marginBottom={1}><span className="greenText">BoXX United Limited&nbsp; &nbsp;</span> <br/>
                 <span className="text-white">Registration Number: 121657 </span><br/> <span className="text-white">REID: GICO.121657-29&nbsp; &nbsp; </span> <br/><span className="greenText">VASP Licence</span>
                 </Grid>
                 <Grid item className="worldTradeFooter" md={3.5} lg={3.5} marginBottom={1}>
                 <span className="text-white">Unit 5.29, World Trade Center </span><br/> <span className="text-white"> Bayside Road 6&nbsp; &nbsp; </span> <br/><span className="text-white">GX111AA Gibraltar&nbsp; &nbsp; </span><br/> <span className="greenText">info@boxxunited.com</span>
                 </Grid>
                 <Grid item md={2.5} lg={2.5} className="mt-2 socialMediaFooter" style={{color:'#22FF91'}}>
                  <FacebookIcon style={{fontSize:"30px"}}/>
                  <LinkedInIcon style={{fontSize:"30px"}}/>
                  <InstagramIcon style={{fontSize:"30px"}}/>
                 </Grid>
                 <Grid item md={2} lg={2} className="pb-4 whitePaperFooter">
                 <span className="greenText">Read our legal page</span><br/>
                 <span className="greenText">Whitepaper</span>
                 </Grid>
                </Grid>
                </div>
                </>
              )}
             
            </Box>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
