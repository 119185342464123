import { ConversationDetailsDTO } from "../../models/conversation/ConversationDetailsDTO";
import { ConversationDTO } from "../../models/conversation/ConversationDTO";
import { ConversationFilter } from "../../models/filters/conversation/ConversationFilter";
import { UserConversationFilter } from "../../models/filters/conversation/UserConversationFilter";
import axios from "../../network/AxiosInstance";
import { Page } from "../../pagination/Page";


export class ConversationService {

    static async getAllConversationsPaged(page: number, size: number, conversationFilter: ConversationFilter): Promise<Page<ConversationDTO>> {
        const response = await axios.post<Page<ConversationDTO>>(
            `/rest/conversation/all-paged/`, conversationFilter, { params: { page, size } }
        );
        const data = response.data

        return new Page<ConversationDTO>(
            data.content.map(conversation => new ConversationDTO(conversation)),
            data.totalElements
        )
    }

    static async findAllPagedForUser(id: number, page: number, size: number, userConversationFilter: UserConversationFilter): Promise<Page<ConversationDTO>> {
        const response = await axios.post<Page<ConversationDTO>>(
            `/rest/conversation/all-paged/` + id, userConversationFilter, { params: { page, size } }
        );
        const data = response.data

        return new Page<ConversationDTO>(
            data.content.map(conversation => new ConversationDTO(conversation)),
            data.totalElements
        )
    }

    static async closeConversation(id: number): Promise<void> {
        await axios.post<void>(
            `/rest/conversation/close/` + id,
        );
    }
    static async markAsRead(id: number): Promise<void> {
        await axios.post<void>(
            `/rest/conversation/mark-as-read/` + id,
        );
    }

    static async openConversation(id: number): Promise<void> {
        await axios.post<void>(
            `/rest/conversation/open/` + id,
        );
    }

    static async assigneeConversation(id: number): Promise<void> {
        await axios.post<void>(
            `/rest/conversation/assignee/` + id,
        );
    }

    static async reassignConversation(id: number | undefined, userId: number): Promise<void> {
        await axios.post<void>(
            `/rest/conversation/reassign/` + id, {}, { params: { userId }});
    }

    static async getConversationById(id:number): Promise<ConversationDetailsDTO> {
        const response = await axios.get<ConversationDetailsDTO>(
            `/rest/conversation/get/` +  id, {}
        );
        const data = response.data

        return new ConversationDetailsDTO(
            data
        )
    }

}