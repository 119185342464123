import { Box, Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { strings } from "../localization/Localization";

type OnValueChangedFunction = {
    (value: boolean | undefined): void
};

interface IndeterminateCheckboxProps {
    onValueChanged?: OnValueChangedFunction;
    value: boolean | undefined;
}

export function NullableCheckbox(props: IndeterminateCheckboxProps) {
    function calculateChecked(value: boolean | undefined): boolean {
        return !!value;
    }

    function calculateNextValue(value: boolean | undefined): boolean | undefined {
        if (value) {
            return undefined;
        } else if (value === undefined) {
            return false;
        }

        return true;
    }

    const [value, setValue] = useState<boolean | undefined>(props.value);
    const [checked, setChecked] = useState<boolean>(calculateChecked(props.value));

    function isIndeterminate() {
        if (value === undefined) return true;
        if (value) return false;
        if (!value) return false;
    }

    function handleChangeActive(): void {
        const newValue = calculateNextValue(value);
        setValue(newValue);

        setChecked(calculateChecked(newValue));

        if (props.onValueChanged) {
            props.onValueChanged(newValue);
        }
    }

    return <FormControlLabel className="styledLabel" value="correct" control={<Checkbox
        className="success-color"
        indeterminate={isIndeterminate()}
        checked={checked}
        value={value}
        onChange={handleChangeActive}/>} label={<Box component="div" fontSize={11} color="white" textAlign="left">{value ? strings.active : value==undefined ? strings.both : strings.inactive }</Box>} />
}
