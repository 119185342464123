export class UserConversationFilter {
    name : string | undefined;
    email : string | undefined;
    conversationStatusId : number | undefined;
    conversationTypeId: number | undefined;

    constructor(name : string | undefined, email : string | undefined, conversationStatusId : number | undefined, conversationTypeId : number | undefined) {
        this.name = name;
        this.email = email;
        this.conversationStatusId = conversationStatusId;
        this.conversationTypeId = conversationTypeId;
    }
}