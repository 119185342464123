import { Box, Button, Container, Grid, IconButton, InputAdornment, InputLabel, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Logo } from "../../components/Logo"
import { Octagon } from "../../components/Octagon"
import { Page } from "../../components/Page"
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UserService } from "../../services/users/UserService";
import './Register.css';
import { useNavigate } from "react-router-dom";
import { Routes } from "../../router/Routes";
import './Register.css';
import { strings } from "../../localization/Localization";

type RegisterForm = {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    password: string,
    confirmPassword: string
}

export function RegisterFan() {
    
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [setAnimation, setAnimationState] = useState(false);
    const { register, handleSubmit, getValues, control, formState: { errors } } = useForm<RegisterForm>({
    });
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();

    function togglePassword(): void {
        setShowPassword(prev => !prev);
    }

    function toggleConfirmPassword(): void {
        setShowConfirmPassword(prev => !prev);
    }

    const animationForm = (toRedirect: string) => {
        setAnimationState(true)

        setTimeout(() => {
            setAnimationState(false)
            if (toRedirect === 'verify')
                navigate(Routes.VERIFYCODE)
            else
                navigate(Routes.LOGIN)
        }, 500);
    }

    const onSubmit: SubmitHandler<RegisterForm> = data => {
        let form = new FormData()
        form.append("email", data.email)
        form.append("firstName", data.firstName)
        form.append("lastName", data.lastName)
        form.append("phoneNumber", data.phoneNumber)
        form.append("roleName", "ROLE_USER")
        UserService.register(form, data.password).then(resp => {
            animationForm('verify')
        })
    }

   

    const userForm = <Grid container direction="column" width="75%">
        <Grid item style={{ display: "flex", flexDirection: "column" }} marginTop={5} marginBottom={4}>
            <Typography variant="h3" color="white">
                {strings.register}
            </Typography>
            <Typography variant="body2" color="white">
                {strings.addYourDetailsToRegister}
            </Typography>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction={{ xs: "column", md: "row" }} justifyContent={{ xs: "center", md: "space-around" }} marginBottom={{ xs: 2, md: 3 }}>
                <Grid item sx={{ width: { xs: "100%", md: "45%" } }} justifyContent="center" marginBottom={{ xs: 2, md: 0 }}>
                    <InputLabel sx={{ color:"white", float: "left" }} htmlFor="firstName">{strings.firstName}</InputLabel>
                    <Tooltip title={strings.enterFirstName} placement="bottom-start"  open={errors?.firstName?.type === "required"}>
                        <TextField className="styledTextField"
                            fullWidth
                            error={errors?.firstName?.type === "required"}
                            id="firstName"
                            {...register("firstName", {
                                required: true
                            })}
                            type="text"
                            size="small"
                        />
                    </Tooltip>
                </Grid>
                <Grid item width={{ xs: "100%", md: "45%" }}>
                    <InputLabel sx={{ color:"white", float: "left" }} htmlFor="lastName">{strings.lastName}</InputLabel>
                    <Tooltip title={strings.enterLastName} placement="bottom-start"  open={errors?.lastName?.type === "required"}>
                        <TextField className="styledTextField"
                            fullWidth
                            error={errors?.lastName?.type === "required"}
                            id="lastName"
                            {...register("lastName", { required: true })}
                            type="text"
                            size="small"
                        />
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container direction={{ xs: "column", md: "row" }} justifyContent={{ xs: "center", md: "space-around" }} marginBottom={{ xs: 2, md: 3 }}>
                <Grid item width={{ xs: "100%", md: "45%" }} marginBottom={{ xs: 2, md: 0 }}>
                    <InputLabel sx={{ color:"white", float: "left" }} htmlFor="email">{strings.emailAddress}</InputLabel>
                    <Tooltip title={strings.enterEmail} placement="bottom-start"  open={errors?.email?.type === "required"}>
                        <TextField className="styledTextField"
                            fullWidth
                            error={errors?.email?.type === "required"}
                            id="email"
                            {...register("email", { required: true })}
                            type="text"
                            size="small"
                        />
                    </Tooltip>
                </Grid>
                <Grid item width={{ xs: "100%", md: "45%" }}>
                <Tooltip title={strings.enterNumber} placement="bottom-start" open={errors?.phoneNumber?.type === "required"}>
                            <div>
                                <label className="d-flex" htmlFor="password" style={{ color:"white" }}>{strings.mobileNumber}</label>
                                <Controller
                                    rules={{ required: true }}
                                    control={control}
                                    name="phoneNumber"
                                    render={({ field: { ref, ...field } }) => (
                                        <PhoneInput
                                            {...field}
                                            value={getValues('phoneNumber')}
                                            inputClass={"form-control"}
                                            inputStyle={{ width: "100%", height: "40px" }}
                                            buttonStyle={{ borderRadius: "6px" }}></PhoneInput>
                                    )}></Controller>

                            </div>
                        </Tooltip>
                </Grid>
            </Grid>
            <Grid container direction={{ xs: "column", md: "row" }} justifyContent={{ xs: "center", md: "space-around" }} marginBottom={{ xs: 3, md: 4 }}>
                <Grid item width={{ xs: "100%", md: "45%" }} marginBottom={{ xs: 2, md: 0 }}>
                    <InputLabel sx={{ color:"white", float: "left" }} htmlFor="password">{strings.password}</InputLabel>
                    <Tooltip title={strings.enterPassword} placement="bottom-start"  open={errors?.password?.type === "required"}>
                        <TextField className="styledTextField"
                            fullWidth
                            error={errors?.password?.type === "required"}
                            id="password"
                            {...register("password", { required: true })}
                            type={showPassword ? "text" : "password"}
                            size="small"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={togglePassword}
                                        >
                                            {showPassword ? <Visibility color="success" /> : <VisibilityOff color="success" />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Tooltip>
                </Grid>
                <Grid item width={{ xs: "100%", md: "45%" }}>
                    <InputLabel sx={{ color:"white", float: "left" }} htmlFor="confirmPassword">{strings.confirmPassword}</InputLabel>
                    <Tooltip title={strings.matchPassword} placement="bottom-start"  open={errors?.confirmPassword?.type === "validate"}>
                        <TextField className="styledTextField"
                            fullWidth
                            error={errors?.confirmPassword?.type === "required"}
                            id="confirmPassword"
                            {...register("confirmPassword", { validate: value => value === getValues("password") && value.length!=0 })}
                            type={showConfirmPassword ? "text" : "password"}
                            size="small"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={toggleConfirmPassword}
                                        >
                                            {showConfirmPassword ? <Visibility color="success" /> : <VisibilityOff color="success" />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container direction="column" marginBottom={2}>
                <Grid item marginBottom={2}>
                    <Button type="submit" className="btn-sm greenBtn">
                       {strings.continue}
                    </Button>
                </Grid>
                <Grid item>
                    <Typography variant="body1" color="white">{strings.alreadyHaveAnAccount} <Button className="linkText" onClick={() => animationForm('login')}>
                        {strings.login}
                    </Button></Typography>
                </Grid>
            </Grid>

        </form>
    </Grid>

    return <Page 
        component={
            <Container disableGutters>
                <Logo className="mt-3" />
                <div className="container">
                    <div className="d-flex justify-content-center mt-4">
                        {matches ?
                            <Octagon elements={userForm} classAnimate={setAnimation ? "container-out" : "container-in"} size={600} />
                            :
                            <>{userForm}</>}
                    </div>
                </div>
            </Container>
        }
    />
}
