import {Page} from "../../pagination/Page";
import axiosInstance from "../../network/AxiosInstance";
import {TransactionFilterDTO} from "../../models/filters/payments/TransactionFilterDTO";
import {TransactionDTO} from "../../models/payments/TransactionDTO";
import { LoggedUserTransactionsDTO } from "../../models/filters/payments/LoggedUserTransactionsDTO";
import { TransactionStatusDTO } from "../../models/payments/TransactionStatusDTO";

export class TransactionService{
    static async getAllTransactionsPaged(page: number, size: number, transactionFilter: TransactionFilterDTO): Promise<Page<TransactionDTO>> {
        const response = await axiosInstance.post<Page<TransactionDTO>>(
            `/rest/transaction/all-paged/`, transactionFilter, { params: { page, size } }
        );
        const data = response.data

        return new Page<TransactionDTO>(
            data.content.map(transaction => new TransactionDTO(transaction)),
            data.totalElements
        )
    }
    static async getAllTransactionsForUserPaged(page: number, size: number, transactionFilterDTO : LoggedUserTransactionsDTO): Promise<Page<TransactionDTO>> {
        const response = await axiosInstance.post<Page<TransactionDTO>>(
            `/rest/transaction/logged-user-all-paged/`, transactionFilterDTO, { params: { page, size } }
        );
        const data = response.data

        return new Page<TransactionDTO>(
            data.content.map(transaction => new TransactionDTO(transaction)),
            data.totalElements
        )
    }
    static async getTransactionById(id:number): Promise<TransactionDTO> {
        const response = await axiosInstance.get<TransactionDTO>(
            `/rest/transaction/get/` +  id, {}

        );
        const data = response.data

        return new TransactionDTO(
            data
        )
    }
    static async getTransactionStatuses() : Promise<Array<TransactionStatusDTO>>{
        const response = await axiosInstance.get<Array<TransactionStatusDTO>>(`/rest/transactionStatus/all`)
        const data = response.data
        return data
    }
}