import {Buffer} from "buffer";
import axiosInstance from '../../network/AxiosInstance';

export class AuthService {
    static readonly PREFIX = "/rest";

    static async login(username: string, password: string): Promise<string> {
        const auth = new Buffer(`${username}:${password}`).toString('base64');

        const response = await axiosInstance.post<string>(`${AuthService.PREFIX}/login`, null, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + auth,
            }
        });

        return response.data;
    }

}
