import { Page } from "../../components/Page";
import { FormEvent, useEffect, useState } from "react";
import { AppController } from "../../controllers/AppController";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Octagon } from "../../components/Octagon";
import { Box, Button, useMediaQuery } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './Login.css';
import { Routes } from "../../router/Routes";
import { strings } from "../../localization/Localization";


export function Login() {
    const [setAnimation, setAnimationState] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();

    const animationForm = (toRedirect: string) => {
        setAnimationState(true)

        setTimeout(() => {
            setAnimationState(false)
            if (toRedirect === 'reset')
                navigate(Routes.RESETPASSWORDMOBILE);
            else
                navigate(Routes.REGISTER)
        }, 500);
    }

    function togglePassword(): any {
        setShowPassword(!showPassword);
    }

    async function login(event: FormEvent): Promise<void> {
        event.preventDefault();
        try {
            return await AppController.login(navigate, email, password);
        } catch (e) {
            alert(strings.invalidUserOrPassword);
        }
    }

    const loginForm = <div className="container">
        <h2 className="mt-3 mb-3 labelText">{strings.login}</h2>
        <p className="h5 text-center mb-4 labelText" >
            {strings.welcomeBack} <br /> {strings.pleaseLogin}
        </p>
        <form className="mt-2" onSubmit={login}>
            <div className="d-flex justify-content-center align-items-center form-group mb-4">
                <div style={{ width: '70%' }}>
                    <label className="d-flex">{strings.emailAddress}</label>
                    <input type="email" className="form-control" id="email" onChange={(event) => setEmail(event.target.value)} required />
                </div>
            </div>
            <div className="d-flex justify-content-center form-group">
                <div style={{ width: '70%' }}>
                    <label className="d-flex" htmlFor="password">{strings.password}</label>
                    <input type={showPassword ? "text" : "password"} style={{ height: '2.386rem' }} className="input form-control" id="password" onChange={(event) => setPassword(event.target.value)} required />
                    <span>
                        <span onClick={togglePassword}>
                            {showPassword ? <VisibilityIcon className="icon" /> : <VisibilityOffIcon className="icon" />}
                        </span>
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-end ml-2 form-group">
                <div style={{ width: '59%', marginTop: '-1.15rem' }}>
                    <a style={{ fontSize: '12px', textDecoration: 'none', cursor: 'pointer'}} className="labelText cursor-pointer" onClick={() => animationForm('reset')}>
                        {strings.forgotYourPassword}
                    </a>
                </div>
            </div>

            <div className="mt-4 ml-2 form-group">
                <Button className="btn-sm greenBtn" type="submit" style={{width:"115px"}}>
                    {strings.login}
                </Button>
            </div>
        </form>
        <p className="h5 text-center mt-4 labelText" style={{ fontSize: '12px' }}>
            {strings.noAccount}
            <Button component="label" style={{ color: 'var(--green)', fontSize: '12px', textDecoration: 'none', minWidth: "0px", padding: "3px" }} onClick={() => animationForm('register')}>
                {strings.register}
            </Button>
        </p>
    </div>

    return (
        <Page 
            component={
                <>
                    <Logo className="mt-3" />
                    <div className="container">
                        <div className="d-flex justify-content-center mt-5">
                            {matches ?
                                <Octagon elements={loginForm} classAnimate={setAnimation ? "container-out" : "container-in"} size={500} />
                                :
                                <>{loginForm}</>}</div>
                    </div>
                </>
            }
        />
    );
}
