import { ReactElement } from "react";

export class SidebarItem {
    constructor(
        public name: string,
        public path: string,
        public icon: any,
        public children: SidebarItem[],
        public showIcon?:boolean,
    ) {
    }
}