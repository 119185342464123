import { Page } from "../../components/Page";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Octagon } from "../../components/Octagon";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'react-phone-input-2/lib/style.css'
import { Box, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import { Routes } from "../../router/Routes";
import { UserService } from "../../services/users/UserService";
import { strings } from "../../localization/Localization";


export function NewPassword() {
    const [setAnimation, setAnimationState] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));    


    function changePassword() {
        UserService.resetPassword(password).then(resp => {
            localStorage.removeItem("passwordResetToken")
        setAnimationState(true)
            setTimeout(() => {
                setAnimationState(false)
                navigate(Routes.LOGIN);
            }, 500);
        })
    }

    function togglePassword(): any {
        setShowPassword(!showPassword);
    }

    function toggleConfirmPassword(): any {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const mobilePhoneForm = <div className="container">
        <h2 className="mt-3 mb-3" style={{ color: '#FFF' }}>{strings.new} <br /> {strings.password}</h2>
        <form className="mt-4">
            <div className="d-flex justify-content-center align-items-center form-group">
                <div style={{ width: '70%', maxWidth:'300px' }}>
                    <label className="d-flex">{strings.password}</label>
                    <input type={showPassword ? "text" : "password"} style={{ height: '2.386rem' }} className="input form-control" id="password" onChange={(event) => setPassword(event.target.value)} required />
                    <span>
                        <span onClick={togglePassword}>
                            {showPassword ? <VisibilityOffIcon className="icon" /> : <VisibilityIcon className="icon" />}
                        </span>
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center form-group mb-4">
                <div style={{ width: '70%', maxWidth:'300px' }}>
                    <label className="d-flex">{strings.confirmPassword}</label>
                    <input type={showConfirmPassword ? "text" : "password"} style={{ height: '2.386rem' }} className="input form-control" id="confirm-password" onChange={(event) => setConfirmPassword(event.target.value)} required />
                    <span>
                        <span onClick={toggleConfirmPassword}>
                            {showConfirmPassword ? <VisibilityOffIcon className="icon" /> : <VisibilityIcon className="icon" />}
                        </span>
                    </span>
                </div>
            </div>
            <div className="mt-4 ml-2 form-group">
                <Button className="btn-sm borderedButton" onClick={changePassword}>
                    <Box component="span" className="borderedButtonBox">
                        {strings.continue}
                    </Box>
                </Button>
            </div>
        </form>
    </div>

    return (
        <Page 
            component={
                <>
                    <Logo className="mt-3" />
                    <div className="container">
                        <div className="d-flex justify-content-center mt-5">
                        {matches ? <Octagon elements={mobilePhoneForm} classAnimate={setAnimation ? "container-out" : "container-in"} size={450} /> : <>{mobilePhoneForm}</>}
                        </div>
                    </div>
                </>
            }
        />
    );
}
