import { AthleteInfoDTO } from "../users/AthleteInfoDTO";
import { ClubDTO } from "../users/ClubDTO";
export class CountryDTO {
    id:number;
    name:string;
    description:string;
    clubInfos:ClubDTO[];
    athleteInfos:AthleteInfoDTO[];
    constructor(json:CountryDTO){
        this.id = json.id;
        this.name = json.name;
        this.description = json.description;
        this.clubInfos = json.clubInfos.map((club)=>new ClubDTO(club));
        this.athleteInfos = json.athleteInfos.map((athlete)=>new AthleteInfoDTO(athlete))
    }

}