import React, {ReactElement} from "react";

class PageProps {
    constructor(
        public component: ReactElement,
    ) {}
}

export function Page(props: PageProps) {


    return props.component;
}
