
import {ProfileContext} from "../../components/profile_components/ProfileContext";
import "./Profile.css";
import {CenteredCardLayout} from "../../components/CenteredLayout";
import { Grid } from "@mui/material";
import { strings } from "../../localization/Localization";
export function Profile(){

    return (
        <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
            <Grid item xs={12} lg={8} md={12} sm={12}>
                <CenteredCardLayout footer={true} header={true} navbar={true} sidebar={true} title={strings.myProfile} className="main-content">
                    <ProfileContext />
                </CenteredCardLayout>
            </Grid>
        </Grid>
       
    )
}