import { createRef, ReactElement, useState } from "react";
import { Logo } from "../../components/Logo";
import React from "react";
import { RegisterAthleteForm } from "../../components/RegisterAthleteForm";
import { RegisterClubForm } from "../../components/RegisterClubForm";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { UserService } from "../../services/users/UserService";
import './Register.css';

export function RegisterClubAthlete() {

    const switchForm = (e: React.MouseEvent<HTMLButtonElement>, formName: string) => {
        if (formName === "club") {
            setForm(<RegisterClubForm onSwitch={(e, str) => switchForm(e, str)} onFormSubmit={(form, password) => register(form, password)} captchaRef={captchaRef} />)
        }
        else {
            setForm(<RegisterAthleteForm onSwitch={(e, str) => switchForm(e, str)} onFormSubmit={(form, password) => register(form, password)} captchaRef={captchaRef} />)
        }
    }

    function register(form: FormData, password: string): Promise<string> {
        return UserService.register(form, password)
    }
    const captchaRef = createRef<ReCAPTCHA>();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));   
    const [formToShow, setForm] = useState<ReactElement>(<RegisterAthleteForm onSwitch={(e, str) => switchForm(e, str)} onFormSubmit={(form, password) => register(form, password)} captchaRef={captchaRef} />);


    return <Container disableGutters>
        <Logo className="mt-3" width="10%" height="10%" />
        <div className="container-fluid justify-content-center d-flex mt-4">
            {matches ?
                <Container className="rectangleContainer">
                    {formToShow}
                </Container> : <>{formToShow}</>
                    }

        </div>
        <ReCAPTCHA
            ref={captchaRef}
            sitekey="6LcntQQkAAAAANOvdq_3ZJczO7oKkprTqvuUaKVb"
            size="invisible"
        />
    </Container>
}
