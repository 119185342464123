import Grid from "@mui/material/Grid";
import { abort } from "process";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { strings } from "../../localization/Localization";
import { SportAmbassadorsFilter } from "../../models/filters/sportAmbassadors/SportAmbassadorsFilter";
import { UserDTO } from "../../models/users/UserDTO";
import { Routes } from "../../router/Routes";
import { UserService } from "../../services/users/UserService";
import { PageTable } from "../../shared-components/table/PageTable";
import {UsersMenu} from "../users/UsersMenu";

export function SportAmbassadors() {
    const [ambassadorName, setAmbassadorName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [enabled, setEnabled] = useState<boolean | undefined>(undefined)
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
    const [ambassadors, setAmbassadors] = useState<UserDTO[]>([])
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalElements, setTotalElements] = useState<number>(0);
    const navigate = useNavigate();
    function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        setAmbassadorName(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setAmbassadorName(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handlePhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>){
        setPhoneNumber(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handleEnableChange(event: boolean){
        setEnabled(event)
        setPage(0)
    }
    const headerRows = [
            {
                id: "email",
                label: strings.emailAddress,
                search: true,
                type: "text",
                handleChange: handleChangeEmail,
                variant: "standard"
            },
            {
                id: "clubName",
                label: strings.ambassadorName,
                search: true,
                type: "text",
                handleChange: handleChangeName,
                variant: "standard"
            },
            {
                id: "enabled",
                label: strings.enabled,
                search: true,
                type: "boolean",
                handleChange: handleEnableChange,
                variant: "standard"
            },
            {
                id: "phoneNumber",
                label: strings.mobileNumber,
                search: true,
                type: "text",
                handleChange: handlePhoneNumberChange,
                variant: "standard"
            },
     
    ];

    const rows = ambassadors.map((element) => {
        return {
            id: element.id,
            values: [
                {
                    type: "text",
                    name: "email",
                    value: element.email,
                },
                {
                    type: "text",
                    name: "ambassadorName",
                    value: element?.person?.firstName + " " + element?.person?.lastName,
                },
                {
                    type: "boolean",
                    name: "enabled",
                    value: element.enabled,
                },
                {
                    type: "text",
                    name: "phoneNumber",
                    value: element?.person?.phoneNumber,
                },
            ],
        };
    });
    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }


    useEffect(() => {
        async function getAllAmbassadors() {
            const response = await UserService.getAllAmbassadorsPaged(page, rowsPerPage, new SportAmbassadorsFilter(email,ambassadorName,enabled,phoneNumber))
            setAmbassadors(response.content);
            setTotalElements(response.totalElements);
            setPage(page)
        }
        getAllAmbassadors()
            .then(() => {
            });
    }, [page, rowsPerPage, email, ambassadorName, enabled, phoneNumber]);
    function handleOpen(id: number) {
        navigate(Routes.USER_WITH_ID.replace(":id", id.toString()));
    }
    return (
        <>
        <Grid
            container
            alignItems={"flex-start"}
            alignContent={"flex-start"}
            justifyContent={"center"}
            spacing={0}
            rowGap={0}
        >
            <Grid item xs={12} lg={8} md={12} sm={12}>
                <CenteredCardLayout
                    footer={true}
                    navbar={true}
                    sidebar={true}
                    className="main-content"
                    header={true}
                    title={strings.sportAmbassadors}
                >
                    <UsersMenu/>
                    <div className="tableContainer">
                    <PageTable
                        headerRows={headerRows}
                        rows={rows}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        totalElements={totalElements}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleOpen={handleOpen}
                        clickable={true}
                    />
                    </div>
                </CenteredCardLayout>
            </Grid>
        </Grid>
    </>
);
  
}