import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import Divider from '@material-ui/core/Divider';
import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { ConversationDetailsDTO } from "../../models/conversation/ConversationDetailsDTO";
import { ConversationService } from "../../services/tickets/ConversationService";
import { StorageService } from "../../storage/StorageService";
import { SwalService } from "../../utils/SwalService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { TicketDTO } from "../../models/internal-ticket/TicketDTO";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from "@material-ui/lab/TimelineDot";
import Typography from "@material-ui/core/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useForm } from "react-hook-form";
import { InternalTicketCreationDTO } from "./AddConversation";
import { InternalTicketService } from "../../services/tickets/InternalTicketService";
import { FAQService } from "../../services/tickets/FAQService";
import { Routes } from "../../router/Routes";
import "./conversations.css";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { UserDTO } from "../../models/users/UserDTO";
import { UserService } from "../../services/users/UserService";
import { StorageKeys } from "../../storage/StorageKeys";
import { strings } from "../../localization/Localization";

export function ConversationDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState<UserDTO>();
  const [conversationDetails, setConversationDetailsDTO] = useState<ConversationDetailsDTO>();
  const [conversationStatus, setConversationStatus] = useState<string>();
  const [conversationType, setConversationType] = useState<string>();
  const [conversationTickets, setConversationTickets] = useState<TicketDTO[]>([]);
  const [supports, setSupports] = useState<UserDTO[]>([]);
  const [expanded, setExpanded] = useState<number>(0);
  const [AssignConversation, setAssignConversation] = useState<boolean>(false);
  const [CloseConversation, setCloseConversation] = useState<boolean>(false);
  const [reassignConversation, setReassignConversation] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<InternalTicketCreationDTO>({
    defaultValues: {},
    mode: "onChange",
  });
  watch();

  const handleChangeUser = (event: SelectChangeEvent) => {
    setUserId(event.target.value as string);
  };

  async function getConversation() {
    if (id) {
      await ConversationService.getConversationById(+id).then(res => {
        setConversationDetailsDTO(res);
        setConversationStatus(res.conversationDTO.conversationStatusDTO.name);
        setConversationType(res.conversationDTO.conversationTypeDTO.name);
        setConversationTickets(res.ticketDTOS!);
      });
    }
  }

  async function getAllUsersByRole(role: string) {
    const resp = await UserService.getAllUsersByRole(role)
    setSupports(resp)
  }

  async function getLoggedUser(token: string | null) {
    const response = await UserService.getLoggedUser(token!)
    setUser(response)
  }

  useEffect(() => {
    markAsRead();
    getConversation()
    getAllUsersByRole(StorageService.getUserRole()!)
    getLoggedUser(localStorage.getItem(StorageKeys.AUTH_TOKEN))
  }, [])

  function markAsRead(){
    if (id) {
      ConversationService.markAsRead(+id).then(()=>{});
    }
  }
  function closeConversation() {
    if (id) {
      setCloseConversation(false)
      ConversationService.closeConversation(+id).then(() => {
        SwalService.showSwalMessage(strings.successfullyClosedTicket, "", <CheckCircleIcon sx={{ transform: "scale(4)" }} />).then(() => {
          navigate(Routes.CONVERSATIONS)
        })
      })
        .catch((error) => {
          if (error) {
            SwalService.showSwalMessage(strings.unsuccessfullyClosedTicket, "", <CancelIcon sx={{ transform: "scale(4)" }} />)
          }
        });
    }
  }

  function assigneeConversation() {
    if (id) {
      setAssignConversation(false)
      ConversationService.assigneeConversation(+id).then(() => {
        SwalService.showSwalMessage(strings.successfullyAssignedTicket, "", <CheckCircleIcon sx={{ transform: "scale(4)" }} />)
      }).then(() => { getConversation() })
        .catch((error) => {
          if (error) {
            SwalService.showSwalMessage(strings.alreadyAssignedTicket, "", <CancelIcon sx={{ transform: "scale(4)" }} />)
          }
        });
    }
  }

  function reassignUserConversation() {
    setReassignConversation(false)
    ConversationService.reassignConversation(Number(id), Number(userId)).then(() => {
        setUserId('')
        SwalService.showSwalMessage(strings.successfullyReassignedTicket, "", <CheckCircleIcon sx={{ transform: "scale(4)" }} />).then(() => {
            navigate(Routes.CONVERSATIONS);
        })
    }).catch((error) => {
        if (error) {
            SwalService.showSwalMessage(strings.unsuccessfullyReassignedTicket, "", <CancelIcon sx={{ transform: "scale(4)" }} />)
        }
    });
}

  function addNewInternalConversation() {
    let formData = new FormData()
    formData.append("conversationId", id!)
    formData.append("conversationName", conversationDetails?.conversationDTO.name!)
    formData.append("internalTitle", "")
    formData.append("internalContent", getValues("internalContent"))

    for (var i = 0; i < getValues("files")!.length; i++) {
      formData.append("files", getValues("files")![i] as File)
    }
    InternalTicketService.createInternalConversation(formData).then(() => {
      setValue("internalContent", "")
      setValue("files", [])
      getConversation()
      SwalService.showSwalMessage(strings.successfullyAddedNewMessage, "", <CheckCircleIcon sx={{ transform: "scale(4)" }} />)
    })
      .catch((error) => {
        if (error) {
          SwalService.showSwalMessage(strings.unsuccessfullyAddedNewMessage, "", <CancelIcon sx={{ transform: "scale(4)" }} />)
        }
      });
  }

  async function downloadFile(name: string, id: number) {
    await FAQService.downloadFile(name, id)
      .catch((error) => {
        if (error.request) {
        }
      });
  }


  const handleChange = (id: number) => () => {
    setExpanded(id === expanded ? 0 : id);
  }

  function getFileNames(): Array<ReactElement> {
    let list: Array<File> | undefined = getValues("files")
    let listStrings: Array<ReactElement> = []
    if (list !== undefined) {
      for (let i = 0; i < list.length; i++) {
        listStrings.push(<p key={list[i].name} style={{ color: "white", fontSize: "13px" }}>{
          <>{list[i].name} &nbsp;&nbsp;</>
        } </p>)
      }
    }
    return listStrings
  }


  return (
    <>
      <Grid
        container
        alignItems={"flex-start"}
        alignContent={"flex-start"}
        justifyContent={"center"}
        spacing={0}
        rowGap={0}
      >
        <Grid item xs={12} lg={8} md={12} sm={12}>
          <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content" header={true} title={strings.details}>
            <Grid className="conversation">
              {conversationStatus && conversationStatus === "Unassigned" && StorageService.getUserRole() === "ROLE_SUPPORT" &&
                <Button onClick={() => setAssignConversation(true)} className="buttonConversation greenBtn mt-3">{strings.assignTicket}</Button>}
              {conversationStatus && conversationStatus === "Opened" && StorageService.getUserRole() === "ROLE_SUPPORT" && 
                conversationDetails?.conversationDTO.assignedUserEmail === user?.email && 
                <>
                <Button onClick={() => setCloseConversation(true)} className="buttonConversation purpleBtn mt-3">{strings.closeTicket}</Button>
                <Button onClick={() => setReassignConversation(true)}  className="buttonConversation greenBtn mt-3">{strings.reassignTicket}</Button>
                </>}
              {(conversationStatus === "Unassigned" || (conversationStatus === "Opened" && conversationDetails?.conversationDTO.assignedUserEmail === user?.email)) && 
                StorageService.getUserRole() === "ROLE_SUPPORT" && <hr className="customLine" /> }
            </Grid>
            <Grid>
              <Timeline>
                {conversationTickets.map((ticket, index) => {
                  return (
                    <TimelineItem key={ticket.id}>
                      <Grid item xs={4} sm={3} md={2} lg={2.5} xl={2}>
                        <Box>
                          <TimelineOppositeContent className="oppositeContent">
                            {ticket.creatorUser?.person ? ticket.creatorUser?.person.firstName + " " + ticket.creatorUser?.person.lastName : 
                            ticket.creatorUser?.athleteInfo ? ticket.creatorUser?.athleteInfo.name : ticket.creatorUser?.clubInfo?.name}
                          </TimelineOppositeContent>
                        </Box>
                      </Grid>
                      <TimelineSeparator>
                        <TimelineDot className="timelineDot">
                          {ticket.creatorUser?.roles.find(item => item.role === "ROLE_SUPPORT") ? <SupervisorAccountIcon className="supervisorIcon" onClick={handleChange(ticket.id)}/>
                            : <PersonIcon className="personIcon" onClick={handleChange(ticket.id)} />}
                        </TimelineDot>
                        {index !== conversationTickets.length - 1 && <TimelineConnector />}
                      </TimelineSeparator>
                      <TimelineContent className="timelineContent">
                        <Accordion
                          key={ticket.id}
                          expanded={expanded === ticket.id}
                          className={ticket.creatorUser?.roles.find(item => item.role === "ROLE_SUPPORT") ? "supportTicket" : "userTicket"}
                        >
                          <AccordionSummary className="accordionText">
                            <Typography>{expanded !== ticket.id && ticket.content.length > 315 ? <>{ticket.content.substring(0, 315)}<span className="typographyColor"
                              onClick={handleChange(ticket.id)}>{strings.readMore}</span></> : expanded === ticket.id && ticket.content.length > 315 ?
                              <>{ticket.content} <span className="typographyColor"
                                onClick={handleChange(ticket.id)}>&nbsp; {strings.showLess}</span></> : ticket.content}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid>
                              <Box sx={{ display: 'inline-grid', paddingTop: '6.4px' }}>{ticket.fileDTOs?.map(file => (
                                <a key={file.id} className="files" onClick={() => downloadFile(file.name, file.id)}><AttachFileIcon />{file.name}</a>
                              ))} </Box>
                            </Grid>
                            <Grid>
                              <Box className="messageTime">{DateTimeUtils.formatDateObject(ticket?.dateCreated)}</Box>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </TimelineContent>
                    </TimelineItem>
                  )
                })}
              </Timeline>
            </Grid>
            <Divider />
            {((conversationStatus === "Opened" && conversationDetails?.conversationDTO.assignedUserEmail === user?.email && conversationType === "internal" 
              && StorageService.getUserRole() === "ROLE_SUPPORT") || (conversationStatus !== "Closed" && StorageService.getUserRole() !== "ROLE_SUPPORT" 
              && StorageService.getUserRole() !== "ROLE_ADMIN")) &&
              <Grid container className="messageGrid">
                <Grid item xs={10} sm={11} md={11} lg={11}>
                  <TextField variant="standard" {...register("internalContent", { required: true })}
                    label={strings.enterNewMessage}
                    sx={{
                      "& .MuiInput-root": {
                        borderBottom: '1.5px solid white'
                      },
                      "& .MuiInputBase-root, & .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused": {
                        color: 'white'
                      },
                      '.MuiInput-underline:after': {
                        border: 'none'
                      }
                    }}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <>
                            <label htmlFor="choose-file">
                              <AttachFileIcon className="attachFiles" />
                            </label>
                          </>
                        </InputAdornment>
                    }} multiline fullWidth />
                </Grid>
                <Grid item xs={2} sm={1} md={1} lg={1}>
                  <div onClick={handleSubmit(addNewInternalConversation)} className="fabWrapper"><SendIcon className="fabIcon" /></div>
                </Grid>
              </Grid>}
            <Grid display="flex" justifyContent="start" className="filesMessage">
              <input
                {...register("files")}
                style={
                  { display: "none" }}
                accept="*"
                id="choose-file"
                type="file"
                multiple
              />
              {getFileNames()}
            </Grid>
            {(conversationStatus === "Unassigned" || (conversationStatus === "Opened" && conversationDetails?.conversationDTO.assignedUserEmail === user?.email)) && 
            StorageService.getUserRole() === "ROLE_SUPPORT" && <hr className="customLine" /> }
          </CenteredCardLayout>
        </Grid>
      </Grid>
      <ConfirmationDialog open={CloseConversation} handleClose={() => setCloseConversation(false)} handleConfirm={() => closeConversation()} title={strings.confirmClose} content={strings.confirmCloseTicket} actionButtonName={strings.confirm}></ConfirmationDialog>
      <ConfirmationDialog open={AssignConversation} handleClose={() => setAssignConversation(false)} handleConfirm={() => assigneeConversation()} title={strings.confirmAssign} content={strings.confirmAssignTicket} actionButtonName={strings.confirm}></ConfirmationDialog>
      <ConfirmationDialog width="1000px" open={reassignConversation} handleClose={() => setReassignConversation(false)} handleConfirm={() => reassignUserConversation()} title={strings.reassignTicket}
        content={
          <>
            <InputLabel id="supportUserId">{strings.supportUser}</InputLabel>
              <FormControl className="relative" size="small" fullWidth>
                    <Select
                      labelId="userId"
                      id="userId"
                      value={userId}
                      onChange={handleChangeUser}
                      fullWidth
                      native={false}
                      displayEmpty={true}
                      className="supportUsers"
                    >
                      {supports?.filter(function (support) {
                        return support.id !== user?.id;
                      }).map(function (support) {
                        return <MenuItem key={support.id} value={support.id}>{support.person.firstName} {support.person.lastName}</MenuItem>
                      })}
                    </Select>
              </FormControl>
              </>
        }
        actionButtonName={strings.confirm}></ConfirmationDialog>
    </>
  );


}
