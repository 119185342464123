import { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, RadioGroup, Select, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CountryDTO } from "../models/config/CountryDTO";
import { ClubDTO } from "../models/users/ClubDTO";
import { SportDTO } from "../models/config/SportDTO";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import { CountryService } from "../services/config/CountryService";
import { GenderService } from "../services/config/GenderService";
import { SportService } from "../services/config/SportService";
import { ClubService } from "../services/clubs/ClubService";
import { GenderDTO } from "../models/config/GenderDTO";
import ReCAPTCHA from "react-google-recaptcha";
import Radio from "@mui/material/Radio";
import { useNavigate } from "react-router-dom";
import { Routes } from "../router/Routes";
import "./styles/RegisterForm.css"
import { strings } from "../localization/Localization";

type RegisterForm = {
    firstName: string,
    address: string,
    birthDate: string,
    countryId: number,
    sportId: number,
    email: string,
    phoneNumber: string,
    password: string,
    confirmPassword: string,
    clubId: number,
    shortDescription: string,
    picture: File,
    gender: number,
    terms: boolean,
    privacy: boolean,
    correct: boolean,
    pictureCheck: boolean,
    captchaCheck: boolean
}

class RegisterProps {
    constructor(
        public onSwitch: (e: React.MouseEvent<HTMLButtonElement>, formToShow: string) => void,
        public onFormSubmit: (formToShow: FormData, password: string) => Promise<string>,
        public captchaRef: (React.RefObject<ReCAPTCHA>),
        public countries?: CountryDTO[],
    ) { }
}

export function RegisterAthleteForm(props: RegisterProps) {
    
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [countries, setCountries] = useState<CountryDTO[]>([])
    const [sports, setSports] = useState<SportDTO[]>([])
    const [gender, setGender] = useState<GenderDTO[]>([])
    const [clubs, setClub] = useState<ClubDTO[]>([])
    const { register, handleSubmit, getValues, watch, control, formState: { errors } } = useForm<RegisterForm>({
        mode: "onChange"
    });
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();


    async function getAllCountries(): Promise<void> {
        const resp = await CountryService.getAllCountries()
        setCountries(resp)
    }
    async function getAllGenders(): Promise<void> {
        const resp = await GenderService.getAllGenders()
        setGender(resp)
    }
    async function getAllSports(): Promise<void> {
        const resp = await SportService.getAllSports()
        setSports(resp)
    }
    async function getAllClubs(id: number): Promise<void> {
        const resp = await ClubService.getAllClubsById(id)
        setClub(resp)
    }

    useEffect(() => {
        getAllCountries()
        getAllSports()
        getAllGenders()
    }, [])

    useEffect(() => {
        if (getValues("sportId")) {
            getAllClubs(getValues("sportId"))
        }
    }, [watch('sportId')])

    function onSubmit(data: RegisterForm): void {
        let form = new FormData()
        form.append("firstName", data.firstName)
        form.append("address", data.address)
        form.append("birthDate", data.birthDate)
        form.append("email", data.email)
        form.append("countryId", data.countryId.toString())
        form.append("phoneNumber", data.phoneNumber)
        form.append("sportId", data.sportId.toString())
        form.append("clubId", data.clubId.toString())
        form.append("genderId", data.gender.toString())
        form.append("shortDescription", data.shortDescription)
        form.append("roleName", "ROLE_ATHLETE")
        props.onFormSubmit(form, data.password).then(() => {
            navigate(Routes.VERIFYCODE)
        })
    }

    function togglePassword(): void {
        setShowPassword(prev => !prev);
    }

    function toggleConfirmPassword(): void {
        setShowConfirmPassword(prev => !prev);
    }

    async function checkCaptcha(): Promise<void> {
        if (props.captchaRef.current !== null) {
            let token = await props.captchaRef.current.executeAsync();
        }
    }


    return <Grid container direction="column" sx={{ width: { xs: "75%", md: "100%" } }} justifyContent={{ xs: "center", md: "space-around" }}>
        <Grid container spacing={3} marginBottom={3} justifyContent="space-around" direction={{ xs: "column", md: "row" }}>
            <Grid item style={{ display: "flex", flexDirection: "column" }} justifyContent="center" marginTop={1}>
                <Typography variant="h3" color="white">
                    {strings.register}
                </Typography>
                <Typography variant="body2" color="white">
                    {strings.addYourDetailsToRegister}
                </Typography>
            </Grid>
            <Grid item>
                {matches ?
                    <ButtonGroup variant="text" fullWidth sx={{ justifyContent: "space-around", boxShadow: "none" }}>
                        <Button sx={{ mr: 1 }} className="switchButton mobileSwitch greenBtn" onClick={(e) => props.onSwitch(e, "ath")}>
                            {strings.athletes}
                        </Button>
                        <Button className="switchButton mobileSwitch greenBtn" onClick={(e) => props.onSwitch(e, "club")}>
                            {strings.club}
                        </Button>
                    </ButtonGroup> :
                    <ButtonGroup variant="contained" style={{ height: "60%", boxShadow: "none" }}>
                        <Button sx={{ mr: 1 }} className="switchButton greenBtn" onClick={(e) => props.onSwitch(e, "ath")}>{strings.athletes}</Button>
                        <Button className="switchButton greenBtn" onClick={(e) => props.onSwitch(e, "club")}>{strings.club}</Button>
                    </ButtonGroup>}

            </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column">
                <Grid container direction="row" spacing={1} justifyContent="space-around" marginBottom={1}>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                        <InputLabel className="registerInputLabel"  htmlFor="name">{strings.athleteName}</InputLabel>
                        <Tooltip title={strings.enterName} placement="top-start" open={errors?.firstName?.type === "required"}>
                            <TextField className="styledTextField"
                                error={errors?.firstName?.type === "required"}
                                fullWidth
                                id="athName"
                                {...register("firstName", { required: true })}
                                type="text"
                                size="small"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                        <InputLabel className="registerInputLabel"  htmlFor="address">{strings.address}</InputLabel>
                        <Tooltip title={strings.enterAddress} placement="top-start" open={errors?.address?.type === "required"}>
                            <TextField className="styledTextField"
                                error={errors?.address?.type === "required"}
                                fullWidth
                                id="address"
                                {...register("address", { required: true })}
                                type="text"
                                size="small"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                        <InputLabel className="registerInputLabel"  htmlFor="date">{strings.dateOfBirth}</InputLabel>
                        <Tooltip title={strings.enterDateOfBirth} placement="top-start" open={errors?.birthDate?.type === "required"}>
                            <TextField className="styledTextField"
                                error={errors?.birthDate?.type === "required"}
                                fullWidth
                                id="date"
                                {...register("birthDate", { required: true })}
                                type="date"
                                size="small"
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container direction={{ xs: "column", md: "row" }} spacing={1} justifyContent="space-around" marginBottom={2}>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                        <InputLabel className="registerInputLabel"  htmlFor="email">{strings.emailAddress}</InputLabel>
                        <Tooltip title={strings.enterEmail} placement="top-start" open={errors?.email?.type === "required"}>
                            <TextField className="styledTextField"
                                error={errors?.email?.type === "required"}
                                fullWidth
                                id="email"
                                {...register("email", { required: true })}
                                type="email"
                                size="small"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                        <InputLabel className="registerInputLabel"  htmlFor="country">{strings.country}</InputLabel>
                        <Tooltip title={strings.chooseCountry} placement="top-start" open={errors?.countryId?.type === "required"}>
                        <FormControl className="relative" style={{ width: "100%" }} size="small">
                        <Controller
                                    rules={{ required: true }}
                                    control={control}
                                    name="countryId"
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                        onChange={onChange}
                                        value={value}
                                        error={errors?.countryId?.type === "required"}
                                        fullWidth
                                        native={false}
                                        displayEmpty
                                        sx={{ backgroundColor: "white", textAlign: "start" }}
                                    >
                                        {countries?.map(item => {
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })}
                                    </Select>
                                    )}></Controller>
                        </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                    <Typography className="d-flex" style={{ color: 'white' }}>{strings.mobileNumber}</Typography>
                        <Tooltip title={strings.enterNumber} placement="top-start" open={errors?.phoneNumber?.type === "required"}>
                            <FormControl className="relative" style={{ width: "100%" }} size="small">
                                <Controller
                                    rules={{ required: true }}
                                    control={control}
                                    name="phoneNumber"
                                    render={({ field: { ref, ...field } }) => (
                                        <PhoneInput
                                            {...field}
                                            value={getValues('phoneNumber')}
                                            inputClass={"form-control"}
                                            inputStyle={{ width: "100%", height: "40px" }}
                                            buttonStyle={{ borderRadius: "5px" }}></PhoneInput>
                                    )}></Controller>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Tooltip title={strings.chooseGender} placement="left-start" open={errors?.gender?.type === "required"}>
                            <FormControl sx={{ flexDirection: "row", justifyContent: "center", gap: "15%" }} color="secondary">
                                <Controller
                                    rules={{ required: true }}
                                    control={control}
                                    name="gender"
                                    render={({ field }) => {
                                        return <RadioGroup {...field}
                                            row
                                            sx={{ flexDirection: "row", }}
                                        >
                                            {gender.map(item => (
                                                <FormControlLabel className="styledLabel" control={<Radio className="styledCheckboxRadio" value={item.id} key={item.id} />} label={<Box component="div" fontSize={11}>{item.name}</Box>} />
                                            ))}
                                        </RadioGroup>
                                    }} />
                            </FormControl>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container direction={{ xs: "column", md: "row" }} spacing={2} justifyContent="space-around" marginBottom={2}>
                    <Grid item sx={{ width: { xs: "100%", md: "45%" } }}>
                        <Grid item marginBottom={2}>
                            <InputLabel className="registerInputLabel"  htmlFor="sport">{strings.associatedSport}</InputLabel>
                            <Tooltip title={strings.chooseSport} placement="top-start" open={errors?.sportId?.type === "required"}>
                            <FormControl className="relative" style={{ width: "100%" }} size="small">
                                <Controller
                                    rules={{ required: true }}
                                    control={control}
                                    name="sportId"
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                        onChange={onChange}
                                        value={value}
                                        error={errors?.sportId?.type === "required"}
                                        fullWidth
                                        sx={{ backgroundColor: "white", textAlign: "start" }}
                                        SelectDisplayProps={{
                                            style: {
                                                paddingRight: 40
                                            }
                                        }}
                                    >
                                        {sports?.map(item => {
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })}
                                    </Select>
                                    )}></Controller>
                            </FormControl>
                            </Tooltip>
                        </Grid>
                        <Grid item marginBottom={2}>
                            <InputLabel className="registerInputLabel"  htmlFor="clubName">{strings.associatedClub}</InputLabel>
                            <Tooltip title={strings.chooseClub} placement="top-start" open={errors?.clubId?.type === "required"}>
                            <FormControl className="relative" style={{ width: "100%" }} size="small">
                                <Controller
                                    rules={{ required: true }}
                                    control={control}
                                    name="clubId"
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                        onChange={onChange}
                                        value={value}
                                        error={errors?.clubId?.type === "required"}
                                        fullWidth
                                        sx={{ backgroundColor: "white", textAlign: "start" }}
                                        SelectDisplayProps={{
                                            style: {
                                                paddingRight: 40
                                            }
                                        }}
                                    >
                                        {clubs?.map(item => {
                                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        })}
                                    </Select>
                                    )}></Controller>
                            </FormControl>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <InputLabel className="registerInputLabel" htmlFor="description">{strings.sportDescription}</InputLabel>
                            <Tooltip title={strings.enterDescription} placement="top-start" open={errors?.shortDescription?.type === "required"}>
                                <TextField className="styledTextField"
                                    error={errors?.shortDescription?.type === "required"}
                                    fullWidth
                                    id="description"
                                    {...register("shortDescription", { required: true })}
                                    type="text"
                                    size="small"
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ width: { xs: "100%", md: "45%" } }}>
                        <Grid item height="45%" marginBottom={1}>
                            <input
                                accept="image/*"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                {...register("picture", { required: true })}
                            />

                            <label htmlFor="raised-button-file" className="w-100">
                                <label className="d-flex" style={{ color: 'white' }}>{strings.yourPhoto}</label>
                                <Tooltip title={strings.attachPicture} placement="top-start" open={errors?.picture?.type === "required"}>
                                    <Button component="span" style={{ width: "100%", height: "80%", display: "block", border: "1px solid #ffffff", borderRadius: "2px" }}>
                                        <p style={{ color: '#ffffff', marginBottom: "5px", fontSize: "10px" }}>{strings.dragAndDrop}</p>
                                        <p style={{ color: '#ffffff', marginBottom: "5px", fontSize: "10px" }}>{strings.or}</p>
                                        <p style={{ color: '#22FF90', marginBottom: "5px", fontSize: "10px" }}>{strings.browseFiles}</p>
                                    </Button>
                                </Tooltip>
                            </label>

                        </Grid>
                        <Grid item>
                            <FormControl sx={{ flexDirection: "column", justifyContent: "center", gap: "15%" }} color="secondary">
                                <RadioGroup
                                    row
                                    name="controlled-radio-buttons-group"
                                    sx={{ flexDirection: "column", }}
                                >
                                    <FormControlLabel className="styledLabel" value="terms" control={<Checkbox className="styledCheckboxRadio" {...register("terms", { required: true })} />} label={
                                        <Box component="div" fontSize={11} textAlign="left">
                                            I have read and agree with the <Link style={{ color: '#22FF90', textDecoration: 'none', fontWeight: 'bold' }} href="https://boxxunited.com/legal/" className="cursor-pointer">
                                                terms and conditions
                                            </Link>
                                        </Box>} />
                                    <FormControlLabel className="styledLabel" value="privacy" control={<Checkbox className="styledCheckboxRadio" {...register("privacy", { required: true })} />} label={<Box component="div" fontSize={11} textAlign="left">I have read and agree to the <Link style={{ color: '#22FF90', textDecoration: 'none', fontWeight: 'bold' }} href="https://boxxunited.com/wp-content/uploads/2021/10/BoXX-United-Limited-Privacy-Policy.pdf" className="cursor-pointer">
                                        privacy policy
                                    </Link></Box>} />
                                    <FormControlLabel className="styledLabel" control={<Checkbox className="styledCheckboxRadio" {...register("correct", { required: true })} />} label={<Box component="div" fontSize={11} textAlign="left">I declare that I provide correct and truthful information</Box>} />
                                    <FormControlLabel className="styledLabel" control={<Checkbox className="styledCheckboxRadio" {...register("pictureCheck", { required: true })} />} label={<Box component="div" fontSize={11} textAlign="left">I declare that BoXX United can use my uploaded pictures for all purposes</Box>} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction={{ xs: "column", md: "row" }} spacing={1} justifyContent="space-around" marginBottom={{ xs: 3, sm: 3, md: 2 }}>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                        <InputLabel className="registerInputLabel"  htmlFor="password">{strings.password}</InputLabel>
                        <Tooltip title={strings.enterPassword} placement="top-start" open={errors?.password?.type === "required"}>
                            <TextField className="styledTextField"
                                fullWidth
                                error={errors?.password?.type === "required"}
                                id="password"
                                {...register("password", { required: true })}
                                type={showPassword ? "text" : "password"}
                                size="small"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePassword}
                                            >
                                                {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="success" />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                        <InputLabel className="registerInputLabel"  htmlFor="confirmPassword">{strings.confirmPassword}</InputLabel>
                        <Tooltip title={strings.matchPassword} placement="top-start" open={errors?.confirmPassword?.type === "validate"}>
                            <TextField className="styledTextField"
                                fullWidth
                                error={errors?.confirmPassword?.type === "validate"}
                                id="confirmPassword"
                                {...register("confirmPassword", { validate: value => value === getValues("password") && value.length != 0 })}
                                type={showConfirmPassword ? "text" : "password"}
                                size="small"
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={toggleConfirmPassword}
                                            >
                                                {showConfirmPassword ? <Visibility color="success" /> : <VisibilityOff color="success" />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item sx={{ width: { xs: "100%", md: "28%" } }}>
                        <InputLabel sx={{ color: 'white', float: "left", visibility: "hidden" }} htmlFor="confirmPassword">{strings.captcha}</InputLabel>
                        <Tooltip title={strings.checkCaptcha} placement="top-start" open={errors?.captchaCheck?.type === "required"}>
                            <FormControlLabel
                                label={strings.ImNotRobot}
                                sx={{ background: "white", width: "100%", borderRadius: "6px", marginLeft: { xs: "0px", sm: "0px", color:"black !important" } }}
                                control={
                                    <Checkbox {...register("captchaCheck", { required: true })} onClick={() => checkCaptcha()} />
                                }
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container direction="column" marginBottom={2}>
                    <Grid item sx={{ marginBottom: 2 }}>
                        <Button className="btn-sm greenBtn" type="submit">
                            {strings.continue}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color="white">{strings.alreadyHaveAnAccount} <Link style={{ color: '#22FF90', textDecoration: 'none', fontWeight: 'bold', textTransform: "uppercase"  }} href="/login" className="cursor-pointer">
                            {strings.login}
                        </Link></Typography>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </Grid >
}