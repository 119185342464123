import {FileDTO} from "../faq/FileDTO";
import { UserDTO } from "../users/UserDTO";


export class TicketDTO{
    id: number;
    title : string;
    content : string;
    creatorMail : string;
    creatorUser : UserDTO | undefined;
    fileDTOs? : FileDTO[] | null;
    dateCreated: Date;
    dateModified: Date;

    constructor(json:TicketDTO) {
        this.id = json.id;
        this.title = json.title;
        this.content = json.content;
        this.creatorMail = json.creatorMail;
        this.creatorUser = json.creatorUser ? new UserDTO(json.creatorUser) : undefined;
        this.fileDTOs = json.fileDTOs?.map(file => new FileDTO(file));
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
    }

}