import axios from "../../network/AxiosInstance";
import { Page } from "../../pagination/Page";
import { TicketDTO } from "../../models/internal-ticket/TicketDTO";

export class InternalTicketService {

    static async findAllPagedForUser(id: number, page: number, size: number): Promise<Page<TicketDTO>> {
        const response = await axios.get<Page<TicketDTO>>(
            `/rest/internalticket/all-paged/` + id, { params: { page, size } }
        );
        const data = response.data

        return new Page<TicketDTO>(
            data.content.map(ticket => new TicketDTO(ticket)),
            data.totalElements
        )
    }

    static async createInternalConversation(form: FormData): Promise<TicketDTO> {
        const response = await axios.post<TicketDTO>(`/rest/internal-ticket/create`, form);
        const data = response.data;
        return new TicketDTO(data);
    }

    static async getNumberOfNewMessages(): Promise<number> {
        const response = await axios.get<number>(
            `/rest/internal-ticket/number-of-new-messages`
        );
        return response.data;
    }
}