import { CenteredCardLayout } from "../../components/CenteredLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserService } from "../../services/users/UserService";
import { Form } from "react-bootstrap";
import { Button, IconButton, Grid, ClickAwayListener, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { Item, Label } from "semantic-ui-react";
import { UserDTO } from "../../models/users/UserDTO";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import profile from '../../assets/images/profilePicture.svg'
import { ImageService } from "../../utils/ImageService";
import { styled } from "@mui/material/styles";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { SwalService } from "../../utils/SwalService";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { StorageKeys } from "../../storage/StorageKeys";
import { Routes } from "../../router/Routes";
import { Roles } from "../../router/Roles";
import { StorageService } from "../../storage/StorageService";
import "./User.css";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { strings } from "../../localization/Localization";

export function User() {
    const { id } = useParams()
    const navigate = useNavigate()
    const token = localStorage.getItem(StorageKeys.AUTH_TOKEN)
    const [user, setUser] = useState<UserDTO>()
    const [loggedUserRoles, setLoggedUserRoles] = useState<string[]>([])
    const [supportedUserIds, setSupportedUsersIds] = useState<string[]>([])
    const [userRoles, setUserRoles] = useState<string[]>([])
    const [supportUser, setSupportUser] = useState<boolean>(false)
    const [unsupportUser, setUnsupoortUser] = useState<boolean>(false)




    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: "var(--green)"
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "var(--green)",
            color: "black"
        },
    }));
    const CopyButton = styled(IconButton)({
        backgroundColor: '#22FF90',
        color: "black",
        width: '65px',
        maxWidth: '100%',
        height: '40px',
        borderRadius: '15%',
        borderColor: "primary.main",
        marginTop: "8px",
        '&:hover': {
            backgroundColor: '#471473',
            border: "3px solid #22FF90",
            color: "var(--green)",
            '& .button-copy': {
                color: "#22FF90"
            }
        }
    })
    const [open, setOpen] = useState(false);

    function handleTooltipClose() {
        setOpen(false);
    };

    function handleTooltipOpen(affiliateLink: string) {
        setOpen(true);
        navigator.clipboard.writeText(affiliateLink)
    };

    async function support() {
        if (id) {
            setSupportUser(false)
            try {
                const res = await UserService.support(+id);
                SwalService.showSwalMessage("Successfully added new affiliate!",
                    "Please rearrange the affiliates percentages on your profile.", <CheckCircleIcon
                    sx={{ transform: "scale(4)" }} />).then(() => {
                        navigate(Routes.PROFILE)
                    })
            }
            catch (error) {
                SwalService.showSwalMessage(strings.alreadySupportUser, "", <CancelIcon
                    sx={{ transform: "scale(4)" }} />).then(() => {
                        navigate(-1)
                    })
            }
        }
    }

    async function unsupport() {
        if (id) {
            setUnsupoortUser(false)
            try {
                const res = await UserService.unsupport(+id);
                SwalService.showSwalMessage("Successfully removed affiliate!",
                    "If you still have affiliates, please rearrange their percentages on your profile.", <CheckCircleIcon
                    sx={{ transform: "scale(4)" }} />).then(() => {
                        navigate(Routes.PROFILE)
                    })
            }
            catch (error) {
                SwalService.showSwalMessage(strings.alreadyDontSupportUser, "", <CancelIcon
                    sx={{ transform: "scale(4)" }} />).then(() => {
                        navigate(-1)
                    })
            }
        }
    }

    useEffect(() => {
        async function getUserInfo(id: string | undefined) {
            if (id != null) {
                UserService.getUserById(id).then((res) => {
                    setUser(res)
                    let userRolesList: string[] = [];
                    res.roles.map((role) => {
                        userRolesList.push(role.role)
                        setUserRoles(userRolesList)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }
        }

        async function getSupporting() {
            if (token != null) {
                await UserService.getLoggedUser(token)
                    .then((response) => {
                        let userRolesList: string[] = [];
                        response.roles.map((role) => {
                            userRolesList.push(role.role)
                            setLoggedUserRoles(userRolesList)
                        })
                        UserService.getSupporting(response.id).then((res) => {
                            let affiliatesList: string[] = [];
                            res.map((user) => {
                                affiliatesList.push(user.id.toString())
                                setSupportedUsersIds(affiliatesList)
                            })
                        })
                    })
                    .catch((error) => {
                    });
            }
        }

        getUserInfo(id).then(_ => { });
        getSupporting().then(_ => { });
    }, [])



    return (
        <>
        <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
            <Grid item xs={12} lg={8} md={12} sm={12}>
                <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content" header={true} title={strings.userProfile}>
                    <Grid item className="row roundCorner">
                        <Grid className="col-md-5 pt-5">
                            <Grid item xs={12} lg={12} xl={12} sm={12} md={12}>
                                <Grid item xs={12} lg={11} md={12} sm={12} xl={12}>
                                    <Item>
                                        <div>
                                            {user?.profilePicture?.length !== 0 &&
                                                <span className="poligonUser link-success pb-0">
                                                    <label>
                                                        <img src={`data:image/jpeg;base64,` + ImageService.arrayBufferToBase64(user?.profilePicture)} width="250px" height="250px" />
                                                    </label>
                                                </span>
                                            }
                                            <div>
                                                {user?.profilePicture?.length === 0 && <img src={profile} width="250px" height="250px" />}
                                            </div>
                                        </div>
                                    </Item>
                                </Grid>
                            </Grid>
                            {(!supportedUserIds.includes(id as string) && (userRoles.includes(Roles.club) || userRoles.includes(Roles.athlete))
                                && StorageService.getUserId() !== id) &&
                                <Grid className="pt-4" item xs={12} lg={12} xl={12} sm={12} md={12}>
                                    <Grid item xs={12} lg={11} md={12} sm={12} xl={12}>
                                        <Item>
                                            <div>
                                                <Button className="greenBtn" onClick={() => setSupportUser(true)}>{strings.supportAffiliate}</Button>
                                            </div>
                                        </Item>
                                    </Grid>
                                </Grid>
                            }
                            {supportedUserIds.includes(id as string) &&
                                <Grid className="pt-4" item xs={12} lg={12} xl={12} sm={12} md={12}>
                                    <Grid item xs={12} lg={11} md={12} sm={12} xl={12}>
                                        <Item>
                                            <div>
                                                <Button className="purpleBtn" onClick={() => setUnsupoortUser(true)}>{strings.unsupportAffiliate}</Button>
                                            </div>
                                        </Item>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Form className="text-left col-lg-6 col-sm-12 pt-5 roundCorner">
                            <Grid className="roundCorner" container spacing={0}>
                                {user?.person && (userRoles.includes(Roles.admin) || (userRoles.includes(Roles.support) || (userRoles.includes(Roles.user))
                                    || (userRoles.includes(Roles.sportAmbassador)))) &&
                                    <>
                                        <Grid item xs={12} lg={6}>
                                                <Typography className="typography-label mb-1" >{strings.firstName}</Typography>
                                                <Typography className="typography-value mb-3">{user?.person.firstName ? user?.person.firstName : ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Typography className="typography-label mb-1">{strings.lastName}</Typography>
                                                <Typography className="typography-value mb-3">{user?.person.lastName ? user?.person.lastName : ""}</Typography>
                                            </Grid>
                                            {(loggedUserRoles.includes(Roles.admin) || loggedUserRoles.includes(Roles.support)) &&
                                                <Grid item xs={12} lg={12}>
                                                    <Typography className="typography-label mb-1">{strings.mobileNumber}</Typography>
                                                    <Typography className="typography-value mb-3">{user?.person.phoneNumber ? user?.person.phoneNumber : ""}</Typography>
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {
                                        user?.athleteInfo && <><Grid item xs={12} lg={12}>
                                            <Typography className="typography-label mb-1">{strings.athleteName}</Typography>
                                            <Typography className="typography-value mb-3">{user?.athleteInfo.name ? user?.athleteInfo.name : ""}</Typography>
                                        </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Typography className="typography-label mb-1">{strings.shortDescription}</Typography>
                                                <Tooltip placement="top-start" title={user?.athleteInfo.shortDescription}>  
                                                <Typography className="typography-value mb-3">{user?.athleteInfo.shortDescription ? user.athleteInfo.shortDescription.length > 60 ? user?.athleteInfo.shortDescription.substring(0,60) + "..." : user.athleteInfo.shortDescription : ""}
                                                </Typography>
                                                </Tooltip>
                                            </Grid>
                                            {(loggedUserRoles.includes(Roles.admin) || loggedUserRoles.includes(Roles.support)) &&
                                                <>
                                                    <Grid item xs={12} lg={12}>
                                                        <Typography className="typography-label mb-1">{strings.athleteAddress}</Typography>
                                                        <Typography className="typography-value mb-3">{user?.athleteInfo.address ? user?.athleteInfo.address : ""}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} lg={12}>
                                                        <Typography className="typography-label mb-1">{strings.mobileNumber}</Typography>
                                                        <Typography className="typography-value mb-3">{user?.athleteInfo.phoneNumber ? user?.athleteInfo.phoneNumber : ""}</Typography>
                                                    </Grid>
                                                </>}
                                        </>
                                    }
                                    {
                                        user?.clubInfo && <><Grid item xs={12} lg={12}>
                                            <Typography className="typography-label mb-1">{strings.clubName}</Typography>
                                            <Typography className="typography-value mb-3">{user?.clubInfo.name ? user?.clubInfo.name : ""}</Typography>
                                        </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Typography className="typography-label mb-1">{strings.representativeName}</Typography>
                                                <Typography className="typography-value mb-3">{user?.clubInfo.representativeName ? user?.clubInfo.representativeName : ""}</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Typography className="typography-label mb-1">{strings.shortDescription}</Typography>
                                                <Tooltip placement="top-start" title={user?.clubInfo.shortDescription}>  
                                                <Typography className="typography-value mb-3">{user?.clubInfo.shortDescription ? user?.clubInfo.shortDescription.length > 60 ? user.clubInfo.shortDescription.substring(0,60) + "..."  : user.clubInfo.shortDescription: ""}</Typography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={12} lg={12}>
                                                <Typography className="typography-label mb-1">{strings.clubAddress}</Typography>
                                                <Typography className="typography-value mb-3">{user?.clubInfo.address ? user?.clubInfo.address : ""}</Typography>
                                            </Grid>
                                            {(loggedUserRoles.includes(Roles.admin) || loggedUserRoles.includes(Roles.support)) &&
                                                <Grid item xs={12} lg={12}>
                                                    <Typography className="typography-label mb-1">{strings.mobileNumber}</Typography>
                                                    <Typography className="typography-value mb-3">{user?.clubInfo.phoneNumber ? user?.clubInfo.phoneNumber : ""}</Typography>
                                                </Grid>}
                                        </>
                                    }
                                    <Grid item xs={12} lg={12}>
                                        <Typography className="typography-label mb-1">{strings.emailAddress}</Typography>
                                        <Typography className="typography-value mb-3">{user?.email ? user?.email : ""}</Typography>
                                    </Grid>
                                    {(loggedUserRoles.includes(Roles.admin) || loggedUserRoles.includes(Roles.support)) &&
                                        <>
                                            <Grid item xs={12} lg={6}>
                                                <Typography className="typography-label mb-1">{strings.dateCreated}</Typography>
                                                <Typography className="typography-value">{DateTimeUtils.formatDateObject(user?.dateCreated)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Typography className="typography-label mb-1">{strings.dateModified}</Typography>
                                                <Typography className="typography-value  mb-3">{DateTimeUtils.formatDateObject(user?.dateModified)}</Typography>
                                        </Grid></>}
                            </Grid>
                            <Grid container spacing={0}>
                                {(userRoles.includes(Roles.club) || userRoles.includes(Roles.athlete)) && <>
                                <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5} xl={10.5}>
                                <Typography className="typography-label mb-1">{strings.affiliateLink}</Typography>
                                <Typography className="typography-value mb-3">{user?.affiliateLink ? user.affiliateLink : ""}</Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    <Item className="customPadding">
                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <div >
                                                <CustomTooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={handleTooltipClose}
                                                    open={open}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="Copied!"
                                                    placement="top"
                                                >
                                                    <CopyButton className="copy-button" onClick={() => user?.affiliateLink ? handleTooltipOpen(user?.affiliateLink) :
                                                        handleTooltipOpen("")}>
                                                        {<CopyIcon />}
                                                    </CopyButton>
                                                </CustomTooltip>
                                            </div>
                                        </ClickAwayListener>
                                    </Item>
                                </Grid>
                                    </>}
                                <Grid item xs={12} lg={12}>
                                <Typography className="typography-label mb-1">{strings.role}</Typography>
                                <Typography className="typography-value mb-3">{user?.roles[0].label}</Typography>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </CenteredCardLayout>
            </Grid>
        </Grid>
        <ConfirmationDialog open={supportUser} handleClose={() => setSupportUser(false)} handleConfirm={() => support()} title={strings.supportUser} content={strings.confirmSupport} actionButtonName={strings.confirm}></ConfirmationDialog>
        <ConfirmationDialog open={unsupportUser} handleClose={() => setUnsupoortUser(false)} handleConfirm={() => unsupport()} title={strings.unsupportUser} content={strings.confirmUnsupport} actionButtonName={strings.confirm}></ConfirmationDialog>
        </>
    )
}
