import { Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { strings } from "../../localization/Localization";
import { ClubFilter } from "../../models/filters/clubs/ClubFilter";
import { UserDTO } from "../../models/users/UserDTO";
import { Routes } from "../../router/Routes";
import { UserService } from "../../services/users/UserService";
import { PageTable } from "../../shared-components/table/PageTable";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import {UsersMenu} from "../users/UsersMenu";

export function Clubs() {
    const [clubName, setClubName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [repName, setRepName] = useState<string | undefined>(undefined);
    const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
    const [clubs, setClubs] = useState<UserDTO[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalElements, setTotalElements] = useState<number>(0);
    const navigate = useNavigate();
    function handleChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        setClubName(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handleRepNameChange(event: React.ChangeEvent<HTMLInputElement>){
        setRepName(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handlePhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPhoneNumber(event.target.value === "" ? undefined : event.target.value);
        setPage(0);
    }
    function handleEnableChange(event: boolean) {
        setEnabled(event);
        setPage(0);
    }
    const headerRows = [
        {
            id: "email",
            label: strings.emailAddress,
            search: true,
            type: "text",
            handleChange: handleChangeEmail,
            variant: "standard",
        },
        {
            id: "clubName",
            label: strings.clubName,
            search: true,
            type: "text",
            handleChange: handleChangeName,
            variant: "standard",
        },
        {
            id: "representativeName",
            label: strings.representativeName,
            search: true,
            type: "text",
            handleChange: handleRepNameChange,
            variant: "standard",
        },
        {
            id: "enabled",
            label: strings.enabled,
            search: true,
            handleChange: handleEnableChange,
            type: "boolean",
        },
        {
            id: "phoneNumber",
            label: strings.mobileNumber,
            search: true,
            type: "text",
            handleChange: handlePhoneNumberChange,
            variant: "standard",
        }
    ];
    const rows = clubs.map((element) => {
        return {
            id: element.id,
            values: [
                {
                    type: "text",
                    name: "email",
                    value: element.email,
                },
                {
                    type: "text",
                    name: "clubName",
                    value: element?.clubInfo?.name,
                },
                {
                    type: "text",
                    name: "representative",
                    value: element?.clubInfo?.representativeName,
                },
                {
                    type: "boolean",
                    name: "enabled",
                    value: element.enabled,
                },
                {
                    type: "text",
                    name: "phoneNumber",
                    value: element?.clubInfo?.phoneNumber,
                },
            ],
        };
    });
    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    useEffect(() => {
        async function getAllClubs() {
            const response = await UserService.getAllClubsPaged(
                page,
                rowsPerPage,
                new ClubFilter(email,clubName,repName,enabled,phoneNumber)
            );
            setClubs(response.content);
            setTotalElements(response.totalElements);
            setPage(page);
        }
        getAllClubs().then(() => { });
    }, [page, rowsPerPage, clubName, email, repName, enabled, phoneNumber]);
    function handleOpen(id: number) {
        navigate(Routes.USER_WITH_ID.replace(":id", id.toString()));
    }
    return (
        <>
            <Grid
                container
                alignItems={"flex-start"}
                alignContent={"flex-start"}
                justifyContent={"center"}
                spacing={0}
                rowGap={0}
            >
                <Grid item xs={12} lg={8} md={12} sm={12}>
                    <CenteredCardLayout
                        footer={true}
                        navbar={true}
                        sidebar={true}
                        className="main-content"
                        header={true}
                        title={strings.clubs}
                    >
                        <UsersMenu/>
                        <div className="tableContainer">
                        <PageTable
                            headerRows={headerRows}
                            rows={rows}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            totalElements={totalElements}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleOpen={handleOpen}
                            clickable={true}
                        />
                        </div>
                    </CenteredCardLayout>
                </Grid>
            </Grid>
        </>
    );
}
