import { AffiliateInfoDTO } from "../../models/affiliates/AffiliateInfoDTO";
import { AffiliateUpdateDTO } from "../../models/affiliates/AffiliateUpdateDTO";
import { AdminFilter } from "../../models/filters/admins/AdminFilter";
import { AthleteFilter } from "../../models/filters/athletes/AthleteFilter";
import { ClubFilter } from "../../models/filters/clubs/ClubFilter";
import { SportAmbassadorsFilter } from "../../models/filters/sportAmbassadors/SportAmbassadorsFilter";
import { SupportFilter } from "../../models/filters/supports/SupportFilter";
import { UserFilter } from "../../models/filters/users/UserFilter";
import { UserCreationDTO } from "../../models/users/UserCreationDTO";
import { UserDTO } from "../../models/users/UserDTO";
import { UserUpdateDTO } from "../../models/users/UserUpdateDTO";
import axiosInstance from "../../network/AxiosInstance";
import { Page } from "../../pagination/Page";


export class UserService {
    static async createUser(userData: UserCreationDTO, password: string): Promise<void> {
        await axiosInstance.post<Page<UserDTO>>(
            `/rest/user/create`, userData, {
                headers: {
                    "password": password
                }
        }
        );
    }
    static async updateUser(userData: UserUpdateDTO): Promise<UserDTO> {
       const response =  await axiosInstance.post<Page<UserDTO>>(
            `/rest/user/update`, userData, { headers: {} }
        );
        const data = response.data;
        return new UserDTO(data);
    
    }
    static async support(id:number): Promise<void> {
        const response =  await axiosInstance.post(
            `/rest/user/support/` + id, {}, { headers: {} }
        );
    }

    static async unsupport(id:number): Promise<void> {
        const response =  await axiosInstance.delete(
            `/rest/user/unsupport/` + id
        );
    }

    static async updatePassword(id: number, password: string): Promise<void> {
        await axiosInstance.put<UserDTO>(
            `/rest/user/update-password/` + id, {}, { headers: { "password": password } }
        );
    }
    static async getUserById(id : number | string): Promise<UserDTO> {
        const response = await axiosInstance.get<UserDTO>(
            `/rest/user/get/` + id
        );
        return new UserDTO(response.data)
    }

    static async setEnabled(id: number, enable: boolean): Promise<void> {
        await axiosInstance.put<UserDTO>(
            `/rest/user/set-enabled/` + id, {}, { params: { enable } }
        );
    }
    static async deleteUser(id: number): Promise<void> {
        await axiosInstance.delete<void>(
            `/rest/user/delete/` + id
        );
    }
    static async getLoggedUser(token: string): Promise<UserDTO>{
        const response =  await axiosInstance.get<UserDTO>(
          `/rest/user/get-logged-user`,{}
        );
        return new UserDTO(response.data)
    }

    static async getUserProfilePic(id: number,profilePicture:any):Promise<void> {
    await axiosInstance.post(
            `/rest/user/profile-image/${id}`,profilePicture
        )

    }

    static async getUserAffiliates():Promise<AffiliateInfoDTO[]>{
       const response =  await axiosInstance.get<AffiliateInfoDTO[]>(`/rest/userSupportUser/logged-user-affiliates`);
       const data =response.data;
       return data.map((affiliate)=>new AffiliateInfoDTO(affiliate));
    }

    static async resetPasswordCode(code:number):Promise<string>{
        const response = await axiosInstance.post<number>(`rest/user/reset-password-code?code=${code}`)
        return response.data.toString();
    }
    
   static async updateAffiliatePercentage(affiliateUpdateDTOS:AffiliateUpdateDTO[]):Promise<void>{
    await axiosInstance.post(`/rest/userSupportUser/update-affiliate-percentage`, affiliateUpdateDTOS)
   }

    static async getCountOfClubs(): Promise<number> {
        const response = await axiosInstance.get<number>(`/rest/user/clubs-count`)
        return response.data;
    }

    static async getCountOfAthletes(): Promise<number> {
        const response = await axiosInstance.get<number>('/rest/user/athletes-count')
        return response.data;
    }

    static async getCountOfAmbassadors(): Promise<number> {
        const response = await axiosInstance.get<number>('/rest/user/sport-ambassadors-count')
        return response.data;
    }

    static async getAllClubsPaged(page: number, size: number, clubFilter : ClubFilter): Promise<Page<UserDTO>> {
        const response = await axiosInstance.post<Page<UserDTO>>(
            `/rest/user/clubs-all-paged/`, clubFilter, { params: { page, size } }
        );
        const data = response.data

        return new Page<UserDTO>(
            data.content.map(user => new UserDTO(user)),
            data.totalElements
        )
    }
    static async getAllUsersPaged(page: number, size: number, userFilter : UserFilter): Promise<Page<UserDTO>> {
        const response = await axiosInstance.post<Page<UserDTO>>(
            `/rest/user/users-all-paged/`, userFilter,  { params: { page, size } }
        );
        const data = response.data

        return new Page<UserDTO>(
            data.content.map(user => new UserDTO(user)),
            data.totalElements
        )
    }

    static async getAllUsersByRole(role: string): Promise<Array<UserDTO>> {
        const response = await axiosInstance.post<Array<UserDTO>>(
            `/rest/user/all-by-role`, {}, { params: { role } }
            );
        const data =response.data;
        return data.map((support)=>new UserDTO(support))
    }

    static async getAllSupportsPaged(page: number, size: number, supportFilter : SupportFilter): Promise<Page<UserDTO>> {
        const response = await axiosInstance.post<Page<UserDTO>>(
            `/rest/user/support-all-paged/`, supportFilter,  { params: { page, size } }
        );
        const data = response.data

        return new Page<UserDTO>(
            data.content.map(user => new UserDTO(user)),
            data.totalElements
        )
    }
    static async getAllAdminsPaged(page: number, size: number, adminFilter: AdminFilter): Promise<Page<UserDTO>> {
        const response = await axiosInstance.post<Page<UserDTO>>(
            `/rest/user/admins-all-paged/`, adminFilter,  { params: { page, size } }
        );
        const data = response.data

        return new Page<UserDTO>(
            data.content.map(user => new UserDTO(user)),
            data.totalElements
        )
    }
    static async getAllAthletesPaged(page: number, size: number, athleteFilter : AthleteFilter): Promise<Page<UserDTO>> {
        const response = await axiosInstance.post<Page<UserDTO>>(
            `/rest/user/athletes-all-paged/`, athleteFilter,  { params: { page, size } }
        );
        const data = response.data

        return new Page<UserDTO>(
            data.content.map(user => new UserDTO(user)),
            data.totalElements
        )
    }


    static async getAllAmbassadorsPaged(page: number, size: number, sportAmbassadorFilter : SportAmbassadorsFilter): Promise<Page<UserDTO>> {
        const response = await axiosInstance.post<Page<UserDTO>>(
            `/rest/user/sport-ambassadors-all-paged/`, sportAmbassadorFilter,  { params: { page, size } }
        );
        const data = response.data

        return new Page<UserDTO>(
            data.content.map(user => new UserDTO(user)),
            data.totalElements
        )
    }

    static async register(data: FormData, password: string | undefined): Promise<string> {
        const response = await axiosInstance.post<string>(`/rest/user/register`, data, {
            headers: {
                'password': password,
            }
        });

        return response.data;
    }

    static async sendSms(phoneNumber: string): Promise<void> {
        await axiosInstance.post<void>(`/rest/user/sendSMS`, null, {
            headers: {
                'phoneNumber': phoneNumber
            }
        });
    }

    static async sendEmail(email: string): Promise<void> {
        await axiosInstance.post<void>(`/rest/user/reset-password`,null,{
            params:{
                email:email
            }
        });
    }

    static async resetPassword(password : string): Promise<void> {
       await axiosInstance.post<void>(`/rest/user/confirm-password-reset?token=${localStorage.getItem("passwordResetCode")}`, null, {
            headers: {
                'password': password
            }
        });
    }

    static async confirmPasswordCode(code: number) {
        const response = await axiosInstance.post<number>(`/rest/user/confirm-registration-code?code=${code}`)
        return response.data.toString();
    }

    static async getSupporting(id: number | undefined): Promise<Array<UserDTO>> {
        const response = await axiosInstance.get<Array<UserDTO>>(
            `/rest/user/get-supporting/` + id
        );
        const data = response.data;
        return data.map(user => new UserDTO(user));
    }

    
}