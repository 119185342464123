import { AuthService } from "../services/login/AuthService";
import {StorageService} from "../storage/StorageService";
import {NavigateFunction} from "react-router-dom";
import {Routes} from "../router/Routes";
import jwt from 'jwt-decode'

export class AppController {
    static async login(navigate: NavigateFunction, username: string, password: string): Promise<void> {
        const token = await AuthService.login(username, password);
        const decodedToken:any = jwt(token)
        StorageService.saveAuthToken(token);
        StorageService.saveUserRole(decodedToken.authority)
        navigate(Routes.MAIN);
    }

    static async logout(navigate: NavigateFunction): Promise<void> {
        StorageService.clearAuthToken();
        navigate(Routes.LOGIN);
    }
}
