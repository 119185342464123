export class AthleteFilter {
    email : string | undefined;
    name : string | undefined;
    enabled : boolean | undefined;
    phoneNumber : string | undefined;
    constructor(email : string | undefined, name : string | undefined, enabled : boolean | undefined, phoneNumber : string | undefined){
        this.email = email;
        this.name = name;
        this.enabled = enabled;
        this.phoneNumber = phoneNumber;
    }
}