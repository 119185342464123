import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {UserService} from "../../services/users/UserService";
import {SwalService} from "../../utils/SwalService";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { strings } from "../../localization/Localization";
export function Affiliate(){
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        async function support(){
            if(id) {
                try {
                    const res = await UserService.support(+id);
                    SwalService.showSwalMessage("Successfully added new affilate", "", <CheckCircleIcon
                        sx={{transform: "scale(4)"}}/>).then(() => {
                        navigate("/profile")
                    })
                }
                catch(error) {
                    SwalService.showSwalMessage(strings.alreadySupportUser, "", <CancelIcon
                        sx={{transform: "scale(4)"}}/>).then(() => {
                        navigate("/profile")
                    })
                }
            }
        }
        support().then(_ => {});
    }, [id])


    return(
        <div>
        </div>
    )
}