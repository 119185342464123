import axios from "../../network/AxiosInstance";
import { ConversationStatusDTO } from "../../models/conversation/ConversationStatusDTO";


export class ConversationStatusService {

    static async getAllConversationStatuses(): Promise<Array<ConversationStatusDTO>> {
        const response = await axios.get<Array<ConversationStatusDTO>>(`/rest/conversation-status/all`);
        const data = response.data;
        return data;
    }
}
