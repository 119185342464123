export class FileDTO{
    id: number;
    name : string;
    mimeType : string;
    content: Blob;

    
    constructor(json:FileDTO) {
        this.id = json.id;
        this.name = json.name;
        this.mimeType = json.mimeType;
        this.content = json.content;
    }
}