import { FileDTO } from "./FileDTO";

export class FaqDTO{
    id : number;
    title : string;
    content : string;
    fileDTOs? : FileDTO[] | null;

    constructor(json:FaqDTO) {
        this.id = json.id;
        this.title = json.title;
        this.content = json.content;
        this.fileDTOs = json.fileDTOs?.map(file => new FileDTO(file));
    }

}