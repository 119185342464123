import React, { useEffect, useState } from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { createRouter } from './router/Router';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from './pages/loader/Loader';
import { Localization } from './localization/Localization';
function App() {
 Localization.initialize();
 const [loading, setLoading] = useState(true);
 useEffect(()=>{
  setTimeout(()=> setLoading(false),1000)
 },[])
  return (
    !loading ?  
    <div className="App">
      <RouterProvider router={createRouter()}/>
    </div>
    :
    <Loader/>
  );
}

export default App;
