import {Guard} from "./Guard";
import {Navigate} from "react-router-dom";
import {Routes} from "../Routes";

export class SupportAuthGuard extends Guard {
    constructor() {
        super((
            <Navigate replace={true} to={Routes.MAIN}/>
        ));
    }

    check(): boolean {
        return true;
    }
}
