import {Guard} from "./Guard";
import {Navigate} from "react-router-dom";
import {Routes} from "../Routes";
import { StorageService } from "../../storage/StorageService";

export class NonAuthGuard extends Guard {
    constructor() {
        super((
            <Navigate replace={true} to={Routes.MAIN}/>
        ));
    }

    check(): boolean {
        return !StorageService.hasAuthToken();
    }
}
