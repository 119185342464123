import { TransactionService } from "../../services/payments/TransactionService";
import { useEffect, useState } from "react";
import { TransactionDTO } from "../../models/payments/TransactionDTO";
import { StorageKeys } from "../../storage/StorageKeys";
import { DateTimeUtils } from "../../utils/DateTimeUtils";
import { PageTable } from "../../shared-components/table/PageTable";
import { Grid } from "@mui/material";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { Routes } from "../../router/Routes";
import { useNavigate } from "react-router-dom";
import { Dayjs } from "dayjs";
import { LoggedUserTransactionsDTO } from "../../models/filters/payments/LoggedUserTransactionsDTO";
import { strings } from "../../localization/Localization";
export function TransactionsUser() {
  const token = localStorage.getItem(StorageKeys.AUTH_TOKEN);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [fromWallet, setFromWallet] = useState<string | undefined>(undefined)
  const [toWallet, setToWallet] = useState<string | undefined>(undefined)
  const [amount, setAmount] = useState<number | undefined>(undefined)
  const [date, setDate] = useState<string | null>(null)
  const [transactions, setTransactions] = useState<TransactionDTO[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    async function getAllTransactionsForUserPaged(page: number): Promise<void> {
      const response = await TransactionService.getAllTransactionsForUserPaged(
        page,
        rowsPerPage,
        new LoggedUserTransactionsDTO(toWallet,fromWallet,amount,date)
      );
      setTotalElements(response.totalElements);
      setTransactions(response.content);
      setPage(page);
    }

    if (token !== null) {
      getAllTransactionsForUserPaged(page).then((_) => {});
    }
    
  }, [page, rowsPerPage, toWallet, fromWallet, amount, date]);

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }
  function handleRowClick(id: number){
    navigate(Routes.TRANSACTION_DETAILS.replace(":id", id.toString()));
}

  const rows = transactions.map((element) => {
    return {
      id: element.id,
      values: [
        {
          type: "text",
          name: "dateCreated",
          value: DateTimeUtils.formatDate(element?.dateCreated?.toString()),
        },
        {
          type: "text",
          name: "amount",
          value: element.amount,
        },
        {
          type: "text",
          name: "dateModified",
          value: element.fromWallet,
        },
        {
          type: "text",
          name: "email",
          value: element.toWallet,
        },
      ],
    };
  });
  const headerRows = [
    {
      id: "dateCreated",
      label: strings.dateCreated,
      search: true,
      type: "datePicker",
      handleChange: handleChangeDate,
      variant: "standard"
    },
    {
      id: "amount",
      label: strings.amount,
      search: true,
      type: "number",
      handleChange: handleChangeAmount,
      variant: "standard"
    },
    {
      id: "fromWallet",
      label: strings.fromWallet,
      search: true,
      type: "text",
      handleChange: handleChangeFromWallet,
      variant: "standard"
    },
    {
      id: "toWallet",
      label: strings.toWallet,
      search: true,
      type: "text",
      handleChange: handleChangeToWallet,
      variant: "standard"
    }
  ];

  function handleChangeFromWallet(event: React.ChangeEvent<HTMLInputElement>) {
    setFromWallet(event.target.value !== "" ? event.target.value : undefined)
    setPage(0)
  }
  function handleChangeToWallet(event: React.ChangeEvent<HTMLInputElement>) {
    setToWallet(event.target.value !== "" ? event.target.value : undefined)
    setPage(0)
  }
  function handleChangeDate(date: Dayjs) {
    setDate(DateTimeUtils.formatDateDayJs(date))
    setPage(0)
  }
  function handleChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
    setAmount(+event.target.value)
    setPage(0)
  }

  return (
    <>
    <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
      <Grid item xs={12} lg={8} md={12} sm={12}>
          <CenteredCardLayout navbar={true} sidebar={true} className="main-content" header={true} footer={true} title={strings.transactions}>
          <div className="tableContainer">
            <PageTable
                headerRows={headerRows}
                rows={rows}
                page={page}
                rowsPerPage={rowsPerPage}
                totalElements={totalElements}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleOpen = {handleRowClick}
                clickable = {true}
                className="mt-5"
            />
            </div>
            </CenteredCardLayout>
        </Grid>
    </Grid>
</>
  );
}
