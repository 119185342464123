import {TableCell, TableRow} from "@mui/material";

import { TableFilter } from "./TableFilter";

 export function PageTableHead(props:any) {
     return (
        <>
         <TableRow>
             {props.headerRows?.map((row:any) => {
                 return (
                     <TableCell
                         className="tableHeader searchCell"
                         sx={{visibility:row.search ? 'visible' : 'hidden'}}
                         key={row.id}
                         align={row.align}
                         padding={row.disablePadding ? 'none' : 'normal'}
                     >
                         {!row.search && <div>{row.label}</div>}
                         {row.search &&
                         <TableFilter row={row}/>
                         }
                     </TableCell>
                 )
             })}
         </TableRow>
                 <TableRow>
                 {props.headerRows?.map((row:any) => {
                     return (
                         <TableCell
                             className="tableHeader headerCell"
                             key={row.id}
                             align={row.align}
                             padding={row.disablePadding ? 'none' : 'normal'}
                         >
                             <div>{row.label}</div>
                         </TableCell>
                     )
                 })}
             </TableRow></>
     )
 }
 
 export default PageTableHead;
 