import { ClubDTO } from "../../models/users/ClubDTO";
import axiosInstance from "../../network/AxiosInstance";

export class ClubService{
    static async getAllClubsById(id : number): Promise<Array<ClubDTO>> {

        const response = await axiosInstance.get<Array<ClubDTO>>(`/rest/club/all/${id}`);

        const data = response.data;
        return data.map((club)=>new ClubDTO(club))
    }
}