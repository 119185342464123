export class AthleteInfoDTO {
    id:number;
    name:string;
    address:string;
    phoneNumber:string;
    shortDescription:string;
    dateModified: Date
    constructor(json:any) {
        this.id=json.id
        this.name=json.name
        this.address=json.address
        this.phoneNumber=json.phoneNumber
        this.shortDescription=json.shortDescription
        this.dateModified=json.dateModified
    }
}