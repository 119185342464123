import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../assets/images/logo.svg'
import { Badge, BadgeProps, Collapse, Link, ListItemButton, Menu, MenuItem, Theme, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { StorageService } from '../storage/StorageService';
import profile from '../assets/images/profilePicture.svg'
import {useEffect, useState} from "react";
import {UserDTO} from "../models/users/UserDTO";
import {UserService} from "../services/users/UserService";
import {StorageKeys} from "../storage/StorageKeys";
import { ExpandLess, ExpandMore, Facebook, Instagram, KeyboardArrowDown, LinkedIn } from '@mui/icons-material';
import { Route, useNavigate } from 'react-router-dom';
import { Routes } from '../router/Routes';
import banner from "../assets/images/banner.png";
import bannerdashboard from "../assets/images/bannerdashboard.png";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {InternalTicketService} from "../services/tickets/InternalTicketService";
import {Roles} from "../router/Roles";
import ".//styles/Navbar.css"
import { Localization, strings } from '../localization/Localization';

export const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "#1C082E",
      color:"#22FF91",
      border:"1px solid #22FF91",
      verticalAlign:"middle",
      textAlign:"center",
    },
    "& li.MuiButtonBase-root":{
        borderBottom:"1px solid #22FF91"
    },
  }
}));


interface Props {
  windowProps?: () => Window;
  dashboard?: boolean;
}


const drawerWidth = 240;

export default function DrawerAppBar(props: Props) {
  const navigate = useNavigate();
  const { windowProps } = props;
  const [authToken, setAuthToken] = React.useState<null | string>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [user, setUser] = useState<UserDTO>()
  const [isOpen, setIsOpen] = React.useState(false);
  const [newMessagesCount, setNewMessagesCount] = React.useState<number>(0)
  const handleIsOpen = () => {
    setIsOpen(!isOpen)
    setMobileOpen(false)
}

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function setLanguage(language: string): void {
    Localization.setLanguage(language);
}

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToMyConversations = () =>{
      if (StorageService.hasAuthToken()) {
          if(StorageService.getUserRole()===Roles.admin || StorageService.getUserRole()===Roles.support) {
              navigate(Routes.CONVERSATIONS)
          }else{
              navigate(Routes.CONVERSATIONS_USER)
          }
      }
  }
  const handleLogout = () => {
    StorageService.clearAuthToken()
    StorageService.clearUserRole()
    navigate(Routes.LOGIN)
}
useEffect(()=>{
    function getNumberOfNewMessages(){
        InternalTicketService.getNumberOfNewMessages().then(count => {
            setNewMessagesCount(count)
        })
    }
    getNumberOfNewMessages();
    getUserInfo(localStorage.getItem(StorageKeys.AUTH_TOKEN))
},[])
  const getUserInfo=(token:string|null)=>{
    if (token != null) {
      setAuthToken(token);
      UserService.getLoggedUser(token).then((res) => {
        setUser(res)
        StorageService.saveUserId(res.id.toString())
      }).catch((error) => {
        console.log(error)
      })
    }
  }
const handleProfile=()=>{
    navigate(Routes.PROFILE);
}

const handleLogin=()=>{
  navigate(Routes.LOGIN);
}
 const languages = ['English', 'Deutsch', 'Dutch', 'Espanol', 'Italiano', 'Francias']
 const drawer = (
  <div  style={{display:"flex",flexDirection:"column"}}>
  <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', textTransform:"uppercase", alignSelf:"stretch"}} >
    <Typography variant="h6" sx={{ my: 2 }}>
    </Typography>
    <Divider />
    {StorageService.hasAuthToken() ?
    <List >
      <ListItemButton>
        <Link className="hamburgerLink" underline="none" href="/profile">{user?.email}</Link>
      </ListItemButton>
      <ListItemButton><Link className="hamburgerLink" underline='none' onClick={handleIsOpen}>Languages</Link>
      {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {
                   languages.map((element, index) => (
                      <Collapse in={isOpen} timeout="auto" unmountOnExit key={index}>
                          <div className="ms-4">
                          <ListItemButton style={{textTransform:"uppercase"}}>
                          {/* onClick={() => setLanguage(Localization.LANGUAGE_DE)} */}
        <Link underline="none" className="hamburgerLink">{element}</Link>
      </ListItemButton>
                          </div>
                      </Collapse>
                  ))          
      }
      <ListItemButton><Link className="hamburgerLink" href='/' underline='none'>Dashboard</Link></ListItemButton>
      <ListItemButton><Link className="hamburgerLink" href='/coming-soon' underline='none'>Buy Kixx</Link></ListItemButton>
      <ListItemButton><Link className="hamburgerLink" href='/wallet' underline='none'>Wallet</Link></ListItemButton>
      <ListItemButton><Link className="hamburgerLink" href='/transactions' underline='none'>Transaction</Link></ListItemButton>
      <ListItemButton><Link className="hamburgerLink" href='/coming-soon' underline='none'>Trading</Link></ListItemButton>
      <ListItemButton><Link className="hamburgerLink" href='/coming-soon' underline='none'>Gaming</Link></ListItemButton>
      <ListItemButton><Link className="hamburgerLink" href='/coming-soon' underline='none'>Nft</Link></ListItemButton>
      <ListItem>
      {authToken !== null && 
        <Link onClick={handleLogout} underline="none" className='hamburgerLink'>Logout</Link> }
        {authToken === null && 
        <Link onClick={handleLogin} underline="none" className='hamburgerLink'>Login</Link> }
      </ListItem>

    </List>
    :
    <List>
    <ListItemButton>
      <Link underline="none">{user?.email}</Link>
    </ListItemButton>
    <ListItemButton><Link underline='none' onClick={handleIsOpen}>Languages</Link>
    {isOpen ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    {
                 languages.map((element, index) => (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit key={index}>
                        <div className="ms-4">
                        <ListItemButton style={{textTransform:"uppercase"}}>
      <Link underline="none">{element}</Link>
    </ListItemButton>
                        </div>
                    </Collapse>
                ))          
    }

    <ListItemButton><Link href='/faq' underline='none'>Faq</Link></ListItemButton>
      <ListItemButton><Link href='/conversations' underline='none'>Conversations</Link></ListItemButton>
    <ListItem>
   
   {authToken !== null && 
     <Link onClick={handleLogout} underline="none">Logout</Link> }
     {authToken === null && 
     <Link onClick={handleLogin} underline="none">Login</Link> }
   </ListItem>
  </List>
}
  </Box>
  <Box>
  <List style={{marginTop:"520px"}} className="footerMobile">
    <ListItemButton>
    <div className="boxxUnitedFooter"><span className="greenText">BoXX United Limited&nbsp; &nbsp;</span> <br/>
   <span className="text-white">Registration Number: 121657&nbsp; &nbsp; </span><br/> <span className="text-white">REID: GICO.121657-29&nbsp; &nbsp; </span> <br/> <span className="greenText">VASP Licence</span>
   </div>
    </ListItemButton>

    <ListItemButton>
    <div className="worldTradeFooter" >
   <span className="text-white">Unit 5.29, World Trade Center &nbsp; &nbsp;</span> <br/><span className="text-white"> Bayside Road 6&nbsp; &nbsp; </span><br/> <span className="text-white">GX111AA Gibraltar&nbsp; &nbsp; </span><br/> <span className="greenText">info@boxxunited.com</span>
   </div>
    </ListItemButton>
  <ListItemButton>
  <div  style={{color:"#22FF91"}} className="socialMediaFooter ">
    <Facebook style={{fontSize:"30px"}}/>
    <LinkedIn style={{fontSize:"30px"}}/>
    <Instagram style={{fontSize:"30px"}}/>
   </div>
    </ListItemButton>
    <ListItemButton>
    <div  className="pb-2">
   <span className="greenText">Read our legal page</span><br/>
   <span className="greenText">Whitepaper</span>
   </div>
    </ListItemButton>
  </List>
  </Box>
  </div>
);

  const container = windowProps !== undefined ? () => windowProps().document.body : undefined;
  return (
    <Box sx={{ display: 'flex' }}>
      <img src={props.dashboard ? bannerdashboard : banner} className="navbarImage" style={{width:"100%", zIndex:"4"}}></img>
      <CssBaseline />
      <AppBar component="nav" position='static' className="navbarBackground" style={{ background: "transparent", boxShadow: "none" }}>
        <Toolbar sx={{ justifyContent: "space-between" }} >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block', md:'block', lg:'none'} }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md:'none', lg:'block' } }}
          >
            <div className='d-flex'>
              <img src={logo} className="mt-3 col-lg-10 col-xl-5"/>
            </div>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'none', md:'none', lg:'block'} }}>
            <div className='d-flex'>
              {authToken !== null && 
              <>
              <Button onClick={handleProfile} sx={{color:"white",marginRight:"20px", fontFamily:"Barlow"}}>{user?.email} &nbsp;&nbsp; <img src={profile} style={{width:'40px'}}/></Button>
              <Badge badgeContent={newMessagesCount} color="error" className='mt-3' sx={{marginRight:"10px", cursor:"pointer"}} onClick={navigateToMyConversations}>
              <NotificationsIcon sx={{color:"#22FF91"}} /> 
              </Badge> 
              </>
               }
              &nbsp;&nbsp;
              <div>
                <Button
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  sx={{color:"#22FF91",marginRight:"15px", fontFamily:"Barlow", marginTop:"5px"}}
                  endIcon={<KeyboardArrowDown />}
                >
                  {StorageService.getLanguage()}
                </Button>
                <Menu
                  style={{textTransform:"uppercase"}}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className={classes.menu}
                 
                >
                  <MenuItem className='languageItem' onClick={() => setLanguage(Localization.LANGUAGE_EN)}>English</MenuItem>
                  <MenuItem className='languageItem' onClick={() => setLanguage(Localization.LANGUAGE_DE)}>Deutsch</MenuItem>
                  <MenuItem className='languageItem'  onClick={handleClose}>Dutch</MenuItem>
                  <MenuItem className='languageItem'  onClick={handleClose}>Espanol</MenuItem>
                  <MenuItem className='languageItem' onClick={handleClose}>Italiano</MenuItem>
                  <MenuItem className='languageItem' onClick={handleClose} style={{borderBottom:"none"}}>Francias</MenuItem>
                </Menu>
              </div>
              {authToken !== null && 
              <Button onClick={handleLogout} sx={{marginLeft:"5px", width:"115px"}} className="greenBtn">{strings.logout}</Button>
              }
              {authToken === null && 
            <Button className="btn-sm greenBtn" onClick={handleLogin} style={{width:"100px"}}>
            Login
        </Button>
              }
            </div>
          </Box>
            <Badge badgeContent={newMessagesCount} color="error" sx={{marginRight:"10px", cursor:"pointer", display: {sm: "block", md:"block",lg:"none"}  }} onClick={navigateToMyConversations}>
                <NotificationsIcon className="notificationsIconMobile" sx={{display: {sm: "block", md:"block",lg:"none"} }}/>
            </Badge>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md:'block', lg:'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background:"var(--gradient)", color:"#22FF91"},
           '& .MuiTypography-root.MuiTypography-inherit.MuiLink-root.MuiLink-underlineNone.css-1cmksfb-MuiTypography-root-MuiLink-root' : {color:"#22FF91"}
          }}

        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
