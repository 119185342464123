import { useEffect, useState } from "react";
import { MenuItem } from "@mui/material";
import "./UsersMenu.css"
import { useNavigate } from "react-router-dom";
import { Routes } from "../../router/Routes";
import { RoleDTO } from "../../models/users/RoleDTO";
import { RoleService } from "../../services/roles/RoleService";
import { Roles } from "../../router/Roles";
import { StorageKeys } from "../../storage/StorageKeys";
import { UserService } from "../../services/users/UserService";
import { UserDTO } from "../../models/users/UserDTO";

export function UsersMenu() {
  const token = localStorage.getItem(StorageKeys.AUTH_TOKEN)
  const [user, setUser] = useState<UserDTO>()
  const [roles, setRoles] = useState<RoleDTO[]>([])
  const [userRoles, setUserRoles] = useState<string[]>([])
  const navigate = useNavigate();


  function handleRoleChange(role: string) {
    if (role === Roles.admin) {
      navigate(Routes.ADMINS)
    } else if (role === Roles.user) {
      navigate(Routes.USERS)
    } else if (role === Roles.support) {
      navigate(Routes.SUPPORTS)
    } else if (role === Roles.athlete) {
      navigate(Routes.ATHLETES)
    } else if (role === Roles.club) {
      navigate(Routes.CLUBS)
    } else if (role === Roles.sportAmbassador) {
      navigate(Routes.SPORT_AMBASSADORS)
    }

  }
  useEffect(() => {
    async function getUserInfo(token: string | null) {
      if (token != null) {
        UserService.getLoggedUser(token)
          .then((res) => {
            setUser(res)
            let userRolesList: string[] = [];
            res.roles.map((role) => {
              userRolesList.push(role.role)
              setUserRoles(userRolesList)
            })
          })
          .catch((error) => {
          });
      }
    }
    async function getAllRoles() {
      await RoleService.getAllRoles().then(res => {
        setRoles(res);
      })
    }
    getUserInfo(token).then(_ => { });
    getAllRoles().then(_ => { });
  }, []);
  return (
    <>
      <div className="users-horizontal-menu pb-5">
        {(userRoles.includes(Roles.admin) || userRoles.includes(Roles.support)) &&
          roles.map((role) => {
            return (
              <MenuItem key={role.id} onClick={() => handleRoleChange(role.role)}>{role.label}</MenuItem>
            )
          })
        }
        {(!userRoles.includes(Roles.admin) && !userRoles.includes(Roles.support)) &&
          roles.filter((role => role.role === Roles.club || role.role === Roles.athlete )).map((otheRole) => {
            return (
              <MenuItem key={otheRole.id} onClick={() => handleRoleChange(otheRole.role)}>{otheRole.label}</MenuItem>
            )
          })
        }
      </div>
    </>
  )
}