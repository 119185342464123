import "./styles/Octagon.css"
import {ReactElement, useState } from "react";

class OctagonProps { 
    constructor(
        public elements: ReactElement,
        public classAnimate: string,
        public size: number,
    ){}
}

export function Octagon(props: OctagonProps) {
    
    return <div className={props.classAnimate} >
    <div className="poligon mt-5" style={{width:props.size+"px",height:props.size+"px"}}>
        <div className="inner" style={{width:props.size-40+"px",height:props.size-40+"px"}}>
            {props.elements}
        </div>
    </div>
    </div>
}