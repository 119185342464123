import { Box, Table, TableHead, TablePagination } from "@mui/material";
import PageTableHead from "./PageTableHead";
import PageTableBody from "./PageTableBody";
import { useLayoutEffect } from "react";
import './css/Table.css'

export function PageTable(props: any) {
    useLayoutEffect(() => {
    }, [props.searchRows])

    function handleOpen(id: number) {
        props.handleOpen(id)
    }
    return (
        <Box sx={{ overflow: "auto" }}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                <Table stickyHeader aria-labelledby="tableTitle" className={props.className}>
                    <TableHead className="tableHead">
                        <PageTableHead headerRows={props.headerRows} searchRows={props.searchRows} />
                    </TableHead>
                    <PageTableBody
                        rows={props.rows}
                        link={props.link}
                        handleOpen={handleOpen}
                        clickable={props.clickable}
                        data={props.data}
                        modalComponent={props.modalComponent}
                        modalFunction={props.modalFunction}
                    />
                </Table>
                <TablePagination
                    className="table-color pagination-div"
                    component="div"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={props.totalElements}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    )
}

