import React, {useEffect, useState} from "react";
import {UserDTO} from "../../models/users/UserDTO";
import {UserService} from "../../services/users/UserService";
import {StorageKeys} from "../../storage/StorageKeys";
import {useNavigate} from "react-router-dom";
import {
    Button,
    Grid,
    TextField,
} from "@mui/material";
import {Item, Label} from "semantic-ui-react";
import {Form} from "react-bootstrap";
import profile from "../../assets/images/myProfilePicture.svg";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {Controller, useForm} from "react-hook-form";
import {PersonDTO} from "../../models/users/PersonDTO";
import {RoleDTO} from "../../models/users/RoleDTO";
import {ClubDTO} from "../../models/users/ClubDTO";
import {AthleteInfoDTO} from "../../models/users/AthleteInfoDTO";
import {UserUpdateDTO} from "../../models/users/UserUpdateDTO";
import {AffiliateInfoDTO} from "../../models/affiliates/AffiliateInfoDTO";
import {AffiliateUpdateDTO} from "../../models/affiliates/AffiliateUpdateDTO";
import {SwalService} from "../../utils/SwalService";
import {ImageService} from "../../utils/ImageService";
import {Roles} from "../../router/Roles";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import octagonAffiliate from "../../assets/images/octagonAffiliate.png";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import "../../pages/profile/Profile.css"
import Paper from "@mui/material/Paper/Paper";
import { strings } from "../../localization/Localization";
class UserData {
    id?: number;
    dateCreated?: string;
    dateModified: string;
    email: string;
    enabled: boolean;
    person: PersonDTO;
    roles: RoleDTO[] | undefined;
    affiliateLink: string;
    clubInfo: ClubDTO | undefined;
    athleteInfo: AthleteInfoDTO | undefined;
    profilePicture?: any;
    role: RoleDTO | undefined;

    constructor(json: UserData) {
        this.id = json.id;
        this.dateCreated = json.dateCreated;
        this.dateModified = json.dateModified;
        this.email = json.email;
        this.enabled = json.enabled;
        this.person = json.person;
        this.roles = json.roles;
        this.affiliateLink = json.affiliateLink;
        this.clubInfo = json.clubInfo;
        this.athleteInfo = json.athleteInfo;
        this.profilePicture = json.profilePicture;
    }

    toUserUpdateDTO(): UserUpdateDTO {
        return new UserUpdateDTO({
            userId: this.id,
            representativeName: undefined,
            phoneNumber: this.person.phoneNumber,
            shortDescription: undefined,
            firstName: this.person.firstName,
            lastName: this.person.lastName,
            email: this.email,
            address: undefined,
        });
    }

    toUserUpdateClubDTO(): UserUpdateDTO {
        return new UserUpdateDTO({
            userId: this.id,
            representativeName: this.clubInfo ? this.clubInfo.representativeName : undefined,
            phoneNumber: this.clubInfo ? this.clubInfo.phoneNumber : undefined,
            shortDescription: this.clubInfo ? this.clubInfo.shortDescription : undefined,
            firstName: this.person.firstName,
            lastName: this.person.lastName,
            email: this.email,
            address: this.clubInfo ? this.clubInfo.address : undefined,
        });
    }

    toUserUpdateAthleteDTO(): UserUpdateDTO {
        return new UserUpdateDTO({
            userId: this.id,
            representativeName: this.athleteInfo ? this.athleteInfo.name : undefined,
            phoneNumber: this.athleteInfo ? this.athleteInfo.phoneNumber : undefined,
            shortDescription: this.athleteInfo ? this.athleteInfo.shortDescription : undefined,
            firstName: this.person.firstName,
            lastName: this.person.lastName,
            email: this.email,
            address: this.athleteInfo ? this.athleteInfo.address : undefined,
        });
    }
}

export function ProfileContext() {
    const [user, setUser] = useState<UserDTO>();
    const [imageType, setImageType] = useState<string>("")
    const [affiliates, setAffiliates] = useState<AffiliateInfoDTO[]>([])
    const [affiliateUpdateDTOS, setAffiliateUpdateDTOS] = useState<AffiliateUpdateDTO[]>([])
    const [userRoles, setUserRoles] = useState<string[]>([])
    const viewBoxValue = "-24 -28 80 80";

    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: {errors},
    } = useForm<UserData>({
        defaultValues: {},
        mode: "onChange",
    });
    watch();

    function getUserInfo(token: string | null) {
        if (token != null) {
            UserService.getLoggedUser(token)
                .then((res) => {
                    setUser(res);
                    let userRolesList: string[] = [];
                    res?.roles.map((role) => {
                        userRolesList.push(role.role)
                        setUserRoles(userRolesList)
                    })
                })
                .catch((error) => {
                });
        }
    }

    useEffect(() => {
        getUserInfo(localStorage.getItem(StorageKeys.AUTH_TOKEN));

        async function getUserAffiliates(): Promise<void> {
            const response = await UserService.getUserAffiliates();
            setAffiliates(response)
            let newList = [];
            for (var i = 0; i < response.length; i++) {
                newList.push({affiliateUserId: response[i].affiliateUserId, percentage: response[i].percentage})
            }
            setAffiliateUpdateDTOS(newList)
        }

        getUserAffiliates().then(_ => {
        });
    }, []);
    useEffect(() => {
        if (user) {
            setValue("id", user.id);
            setValue("person.firstName", user.person?.firstName);
            setValue("person.lastName", user.person?.lastName);
            setValue("person.phoneNumber", user.person?.phoneNumber);
            setValue("email", user?.email);
            setValue("dateCreated", DateTimeUtils.formatDateObject(user?.dateCreated));
            setValue("dateModified", DateTimeUtils.formatDateObject(user?.dateModified));
            setValue("affiliateLink", user?.affiliateLink);
            setValue("roles", user.roles);
            if (user.profilePicture) {
                setValue("profilePicture", user.profilePicture)
            }
            if (user.athleteInfo) {
                setValue("athleteInfo.name", user?.athleteInfo?.name);
                setValue("athleteInfo.shortDescription", user.athleteInfo.shortDescription);
                setValue("athleteInfo.phoneNumber", user.athleteInfo.phoneNumber);
                setValue("athleteInfo.address", user.athleteInfo.address);
            }
            if (user.clubInfo) {
                setValue("clubInfo.representativeName", user.clubInfo.representativeName);
                setValue("clubInfo.shortDescription", user.clubInfo.shortDescription);
                setValue("clubInfo.address", user.clubInfo.address);
                setValue("clubInfo.phoneNumber", user.clubInfo?.phoneNumber);
            }
            if (user.roles) {
                setValue("role.id", user.roles[0].id);
                setValue("role.label", user.roles[0].label);
                setValue("role.role", user.roles[0].role);
                setValue("role.privilege", user.roles[0].privilege);
            }
        }
    }, [user, setValue, imageType]);
    const photoUpload = async (e: any) => {
        e.preventDefault();
        const reader = new FileReader();
        const file = e.target.files[0];
        const buffer = await file.arrayBuffer();
        const bytes = new Uint8Array(buffer);
        setImageType(file.type)
        reader.onloadend = () => {
        };
        if (file) {
            reader.readAsArrayBuffer(file);
        }
        let formData = new FormData();
        formData.append("profilePicture", file);
        if (user?.id) {
            await UserService.getUserProfilePic(user?.id, formData).then(res => {
                setUser({
                    ...user,
                    profilePicture: bytes
                })
            }).catch((error) => {
                if (error) {
                    SwalService.showSwalMessage(strings.cantUploadPhoto, "", <CancelIcon sx={{transform: "scale(4)"}}/>)
                }
            })
        }
    };


    function changePercent(event: React.ChangeEvent<HTMLInputElement>, affiliateUserId: number) {
        setAffiliateUpdateDTOS((prevState) => [
            ...prevState.filter((item) => {
                return item.affiliateUserId !== affiliateUserId
            }),
            {
                affiliateUserId: affiliateUserId,
                percentage: event.target.value !== "" ? +event.target.value : null
            },
        ]);
    };

    function updatePercent() {
        UserService.updateAffiliatePercentage(affiliateUpdateDTOS).then(() => {
            SwalService.showSwalMessage(strings.successfullyUpdate, "", <CheckCircleIcon sx={{transform: "scale(4)"}}/>)
        }).catch((error) => {
            if (error) {
                SwalService.showSwalMessage(strings.sumPercentage, "", <CancelIcon
                    sx={{transform: "scale(4)"}}/>)
            }
        })

    }

    function buildUserUpdateDTO(): UserUpdateDTO {
        if (user?.clubInfo) {
            return new UserData(getValues()).toUserUpdateClubDTO();
        } else if (user?.athleteInfo) {
            return new UserData(getValues()).toUserUpdateAthleteDTO();
        } else
        return new UserData(getValues()).toUserUpdateDTO();
    }

    function saveChanges() {
        const user = buildUserUpdateDTO();
        UserService.updateUser(user).then(() => {
            SwalService.showSwalMessage(strings.successfullyUpdate, "", <CheckCircleIcon
                sx={{transform: "scale(4)"}}/>).then(() => {
                navigate("/homepage")
            })
        });
    }

    return (
        <Grid container justifyContent="center" direction="column" className="p-5 pt-0 roundCorner">
            <div className="transparentBackground">
                <Grid container justifyContent="flex-end">
                    <Grid item xs={12} lg={3}>
                        <Item>
                            <div className="topRight">
                                {user?.profilePicture?.length !== 0 && (
                                    <span className="poligonProfile link-success zTop">
                      <label>
                        <img
                            src={
                                `data:${imageType};base64,` +
                                ImageService.arrayBufferToBase64(user?.profilePicture)
                            }
                            alt=""
                            width="120px"
                            height="120px"
                        />
                          <div className="edit">
                            <input
                                accept="image/png,image/jpeg, image/jpg"
                                style={{width: "100%", height: "100%"}}
                                type="file"
                                id="file"
                                {...register("profilePicture")}
                                onChange={photoUpload}
                                name="profilePicture"
                                className="displayNone"
                            />
                            <AddPhotoAlternateIcon viewBox={viewBoxValue} className="poligonProfile editIcon"/>
                          </div>
                      </label>
                    </span>
                                )}
                                {user?.profilePicture?.length === 0 && (
                                    <span className="noPicturePoligonProfile link-success zTop">
                        <label>
                          <img src={profile} alt=""/>
                          <div className="edit">
                            <input
                                accept=".png,.jpg,.jpeg"
                                style={{width: "100%", height: "100%"}}
                                type="file"
                                id="file"
                                {...register("profilePicture")}
                                onChange={photoUpload}
                                className="displayNone"
                            />
                            <AddPhotoAlternateIcon viewBox={viewBoxValue} className="noPicturePoligonProfile editIconNoPicture"/>
                          </div>
                        </label>
                        </span>
                                )}
                            </div>
                        </Item>

                    </Grid>
                </Grid>
            </div>

            {user && (
                <div>
                   
                <Form 
                    onSubmit={handleSubmit(saveChanges)}
                    className="text-left col-lg-12 pt-3 transparentBackground roundCorner"
                >
                    <Paper sx={{background:"var(--gradient)"}} className="form" elevation={24}>
                    {(userRoles.includes(Roles.admin) ||
                        userRoles.includes(Roles.support) ||
                        userRoles.includes(Roles.sportAmbassador) ||
                        userRoles.includes(Roles.user) ) && (
                        <Grid className="roundCorner" spacing={{xs:0,md:2}} container>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Label>{strings.firstName}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="person.firstName"
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                error={errors.person?.firstName !== undefined}
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Label>{strings.lastName}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="person.lastName"
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                error={errors.person?.lastName !== undefined}
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Label>{strings.mobileNumber}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="person.phoneNumber"
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                error={errors.person?.phoneNumber !== undefined}
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    )}
                    { userRoles.includes(Roles.athlete) && (
                        <Grid container>
                        <Grid container spacing={{xs:0,md:2}}>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Label>{strings.athleteName}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="athleteInfo.name"
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                error={errors.athleteInfo?.name !== undefined}
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Label>{strings.athleteAddress}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="athleteInfo.address"
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                error={errors.athleteInfo?.address !== undefined}
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Label>{strings.mobileNumber}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="athleteInfo.phoneNumber"
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                error={errors.athleteInfo?.phoneNumber !== undefined}
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Item>
                                    <Label>{strings.shortDescription}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="athleteInfo.shortDescription"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                    )}
                    {userRoles.includes(Roles.club) && (
                        <Grid container>
                            <Grid container direction={"row"} spacing={{xs:0,md:2}}>
                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <Label>{strings.clubRepresentativeName}</Label>
                                        <Controller
                                            defaultValue=""
                                            name="clubInfo.representativeName"
                                            rules={{
                                                required: true,
                                            }}
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    {...field}
                                                    className="mb-3"
                                                    type="text"
                                                    error={
                                                        errors.clubInfo?.representativeName !== undefined
                                                    }
                                                    fullWidth
                                                    sx={{
                                                        input: {
                                                            color: "gray",
                                                            background: "white",
                                                            borderRadius: "6px",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <Label>{strings.clubAddress}</Label>
                                        <Controller
                                            defaultValue=""
                                            name="clubInfo.address"
                                            rules={{
                                                required: true,
                                            }}
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    {...field}
                                                    className="mb-3"
                                                    type="text"
                                                    error={errors.clubInfo?.address !== undefined}
                                                    fullWidth
                                                    sx={{
                                                        input: {
                                                            color: "gray",
                                                            background: "white",
                                                            borderRadius: "6px",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Item>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Item>
                                        <Label>{strings.clubMobile}</Label>
                                        <Controller
                                            defaultValue=""
                                            name="clubInfo.phoneNumber"
                                            rules={{
                                                required: true,
                                            }}
                                            control={control}
                                            render={({field}) => (
                                                <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    {...field}
                                                    className="mb-3"
                                                    type="text"
                                                    error={errors.clubInfo?.phoneNumber !== undefined}
                                                    fullWidth
                                                    sx={{
                                                        input: {
                                                            color: "gray",
                                                            background: "white",
                                                            borderRadius: "6px",
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <Item>
                                    <Label>{strings.shortDescription}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="clubInfo.shortDescription"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                    )}
                    <Grid container spacing={{xs:0,md:2}}>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <Label>{strings.emailAddress}</Label>
                                <Controller
                                    defaultValue=""
                                    name="email"
                                    rules={{
                                        required: true,
                                    }}
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            {...field}
                                            className="mb-3"
                                            type="text"
                                            error={errors.email !== undefined}
                                            fullWidth
                                            sx={{
                                                input: {
                                                    color: "gray",
                                                    background: "white",
                                                    borderRadius: "6px",
                                                },
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    )}
                                />
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <Label>{strings.dateCreated}</Label>
                                {user?.dateCreated && (
                                    <Controller
                                        defaultValue=""
                                        name="dateCreated"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <Label>{strings.dateModified}</Label>
                                {user?.dateModified && (
                                    <Controller
                                        defaultValue=""
                                        name="dateModified"
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </Item>
                        </Grid>
                    </Grid>
                    <Grid container className="roundCorner" spacing={{xs:0,md:2}}>
                        {(userRoles.includes(Roles.athlete) || userRoles.includes(Roles.club)) && (
                        <Grid item xs={12} md={6}>
                            <Item>
                                <Label>{strings.affiliateLink}</Label>
                                <Controller
                                    defaultValue=""
                                    name="affiliateLink"
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            {...field}
                                            className=""
                                            type="text"
                                            fullWidth
                                            sx={{
                                                input: {
                                                    color: "gray",
                                                    background: "white",
                                                    borderRadius: "6px",
                                                },
                                            }}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    )}
                                />
                            </Item>
                        </Grid>
                        )}
                        {user?.roles.length > 0 && (
                            <Grid item xs={12} md={(userRoles.includes(Roles.athlete) || userRoles.includes(Roles.club)) ? 6 : 12}>
                                <Item>
                                    <Label>{strings.role}</Label>
                                    <Controller
                                        defaultValue=""
                                        name="role.label"
                                        rules={{
                                            required: true,
                                        }}
                                        control={control}
                                        render={({field}) => (
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                {...field}
                                                className="mb-3"
                                                type="text"
                                                error={errors.role?.label !== undefined}
                                                fullWidth
                                                sx={{
                                                    input: {
                                                        color: "gray",
                                                        background: "white",
                                                        borderRadius: "6px",
                                                    },
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />

                                </Item>
                            </Grid>
                        )}
                    </Grid>
                    </Paper>
                     <div className="text-end pt-3 pb-5">
                        <Button className="buttonForm greenBtn " type="submit">
                            {strings.saveChanges}
                        </Button>
                    </div>
                      </Form>
                </div>
            )}
            {affiliates.length > 0 &&
                <Grid item xs={12}>
                    <div className="greenDiv">
                        <h1>{strings.myAffiliates}</h1>
                    </div>
                    {affiliates.map((affiliate, index) => {
                        return (
                            <div key={index} style={index % 2 === 0 ? {
                                background: "transparent",
                                color: "#22FF91"
                            } : {background: "#875F9A", color: "#461474"}}
                                 className="greenTextUpperCase p-3 d-flex justify-content-between divFlex">
                                <Grid container justifyContent={"center"}>
                                    {affiliate?.profilePicture !== null && (
                                        <Grid item className="link-success" xs={12} sm={2} lg={1} xl={2}>
                                            <Label>
                                                <img className="poligonAffiliateProfile"
                                                     src={
                                                         `data:${'.jpg'};base64,` +
                                                         ImageService.arrayBufferToBase64(affiliate?.profilePicture)
                                                     }
                                                     alt=""
                                                     width="60px"
                                                     height="60px"
                                                />
                                            </Label>
                                        </Grid>
                                    )}
                                    {affiliate?.profilePicture === null && (
                                        <Grid item className="link-success" xs={12} sm={2} lg={1} xl={2}>
                                            <Label>
                                                <img className="poligonAffiliateProfile"
                                                     src={
                                                         octagonAffiliate
                                                     }
                                                     alt=""
                                                     width="60px"
                                                     height="60px"
                                                />
                                            </Label>
                                        </Grid>
                                    )}

                                    <Grid item className="affiliateNameGrid"
                                          xs={12} sm={8} lg={9} xl={8}>
                                        <Label className="affiliateName">{strings.affiliateName}</Label>
                                        <TextField className="affiliateNameText" variant="standard"
                                                   value={affiliate.name}
                                                   sx={{
                                                    "& .MuiInput-root": {
                                                      borderBottom: '1.5px solid white'
                                                    },
                                                    "& .MuiInputBase-root, & .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused": {
                                                      color: 'white'
                                                    },
                                                    '.MuiInput-underline:after': {
                                                      border: 'none'
                                                    }
                                                  }}/>
                                    </Grid>

                                    <Grid item className="supportPercentGrid"
                                          xs={12} sm={2} lg={2} xl={2}>
                                        <Label className="percentage">{strings.supported}</Label>
                                        <TextField required className="percentNumber" type="number" variant="standard"
                                                   defaultValue={affiliate.percentage} fullWidth
                                                   sx={{
                                                    "& .MuiInput-root": {
                                                      borderBottom: '1.5px solid white',
                                                    },
                                                    "& .MuiInputBase-root, & .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused": {
                                                      color: 'white'
                                                    },
                                                    '.MuiInput-underline:after': {
                                                      border: 'none'
                                                    },
                                                  }}
                                                   onChange={(e: any) => changePercent(e, affiliate.affiliateUserId)}/>
                                        <Label className="percentage percent">(%)</Label>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    })}
                    <div className="greenText note">
                        <p>
                            {strings.noteAffiliates}
                        </p>
                    </div>
                    <div className="text-end">
                        <Button className="buttonForm greenBtn" onClick={updatePercent}>
                            {strings.update}
                        </Button>
                    </div>
                </Grid>
            }

        </Grid>
    );
}