export class SupportFilter {
    email : string | undefined;
    firstName : string | undefined;
    enabled : boolean | undefined;
    lastName : string | undefined;
    phoneNumber : string | undefined;
    constructor(email : string | undefined, enabled : boolean | undefined, firstName : string | undefined, lastName : string | undefined, phoneNumber : string | undefined) {
        this.email = email;
        this.firstName = firstName;
        this.enabled = enabled;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
    }
}