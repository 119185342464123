import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { UserDTO } from "../../models/users/UserDTO";
import { UserService } from "../../services/users/UserService";
import { StorageKeys } from "../../storage/StorageKeys";
import visa from '../../assets/images/visa.svg'
import kixxtoken from '../../assets/images/kixxToken.svg'
import kixxCard from '../../assets/images/kixxCard.png'
import euro from '../../assets/images/euroSlanted.png';
import './Wallets.css'
import { strings } from "../../localization/Localization";
export function Wallets() {
  const [user, setUser] = useState<UserDTO>();
  function getUserInfo(token: string | null) {
    if (token != null) {
      UserService.getLoggedUser(token)
        .then((res) => {
          setUser(res);
        })
        .catch((error) => {
        });
    }
  }
  useEffect(() => {
    getUserInfo(localStorage.getItem(StorageKeys.AUTH_TOKEN));
  }, [])
  return (
    <>
      <Grid
        container
        alignItems={"flex-start"}
        alignContent={"flex-start"}
        justifyContent={"center"}
        spacing={0}
        rowGap={0}
      >
        <Grid item xs={12} lg={8} md={12} sm={12}>
          <CenteredCardLayout
            footer={true}
            navbar={true}
            sidebar={true}
            className="main-content"
            header={true}
            title={strings.wallets}
          >
            
            <Grid container justifyContent={"center"}>
              <Grid item xs={11} sm={9} lg={9} md={9} xl={9} >
                <h1 className="greenTextUpperCase tokenDiv">{strings.euroWallet}</h1>
                <div className="d-flex whiteTextUpperCase pb-2 walletDiv">
                  <div><img src={visa} alt="" /></div>
                  <div className="mt-4">{strings.accountingBalance}</div>
                  <h2 className="mt-3 token">500 <img src={euro} alt="" className="mb-2 tokenImg" /> </h2>
                </div>
                <h1 className="greenTextUpperCase pt-5 tokenDiv">{strings.tradingWallet}</h1>
                <div className="d-flex whiteTextUpperCase pb-2 walletDiv">
                  <div><img style={{width:"87px"}} src={kixxCard} alt="" /></div>
                  <div className="mt-4">{strings.accountingBalance}</div>
                  <h2 className="mt-3 token">50 <img src={kixxtoken} alt="" className="mb-2 tokenImg" /></h2>
                </div>
                <h1 className="greenTextUpperCase pt-5 tokenDiv">{strings.shoppingWallet}</h1>
                <div className="d-flex whiteTextUpperCase pb-2 walletDiv">
                  <div><img style={{width:"87px"}} src={kixxCard} alt="" /></div>
                  <div className="mt-4">{strings.accountingBalance}</div>
                  <div>
                    <h2 className="mt-3 token">50 <img src={kixxtoken} alt="" className="mb-2 tokenImg" /></h2>
                  </div>
                </div>
              </Grid>
            </Grid>

          </CenteredCardLayout>
        </Grid>
      </Grid>
    </>

  )
}
