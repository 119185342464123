import { TicketDTO } from "../internal-ticket/TicketDTO";
import { ConversationDTO } from "./ConversationDTO";

export class ConversationDetailsDTO{
    conversationDTO: ConversationDTO;
    ticketDTOS? : TicketDTO[] | null;
    

    constructor(json:ConversationDetailsDTO) {
        this.conversationDTO = json.conversationDTO;
        this.ticketDTOS = json.ticketDTOS?.map(ticket => new TicketDTO(ticket))
    }

}