import { RoleDTO } from "./RoleDTO";

export class UserUpdateDTO{
    userId?:number;
    address?:string;
    representativeName?:string;
    phoneNumber?:string;
    shortDescription?:string;
    firstName?:string;
    lastName?:string;
    email?:string;
    roleIds?:number[];
    constructor(json:UserUpdateDTO){
        this.userId = json.userId;
        this.address = json.address;
        this.representativeName = json.representativeName;
        this.phoneNumber = json.phoneNumber;
        this.shortDescription = json.shortDescription;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.email = json.email;
        this.roleIds = json.roleIds;
    }
}