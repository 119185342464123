/**
 * Table body for PageTable
 *
 * @param {Object} props Function properties.
 * @param {String} props.link Link for the on click action for the row.
 * @param {Array} props.rows Table rows.
 *

 */

 import { Icon, Switch, TableBody, TableCell, TableRow } from "@mui/material";
 import React, { useLayoutEffect } from "react";
 import { useNavigate } from "react-router-dom";
 
 import Button from "@mui/material/Button";
 import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

 export function PageTableBody(props:any) {
   const navigate = useNavigate();
   const [showAllItems, setShowAllItems] = React.useState(true);
 
  
 
   function handleOpen(id:number) {
     props.handleOpen(id);
   };
 
  function handleShowAllItems(id:number)  {
    //  showAllItems === id ? setShowAllItems("") : setShowAllItems(id);
   };
   return (
     <TableBody>
       {props.rows.map((row:any, index:number) => {
         return (
           <TableRow
             hover
             tabIndex={-1}
             key={index}
             onClick={() => (props.clickable ? handleOpen(row.id) : null)}
                     style ={ index % 2? { background : "" }:{ background: "#875F9A"}}
           >
             {row.values.map((value:any, i:number) => {
               return (
                 <React.Fragment key={i}>
                   {value.type === "text" && (
                     <TableCell
                       className="table-color tableCell"
                       component="th"
                       scope="row"
                       align="center"
                     >
                       {value.value}
                     </TableCell>
                   )}
                   {value.type === "boolean" && (
                     <TableCell
                       className="tableCell"
                       component="th"
                       scope="row"
                       align="center"
                     >
                       {value.value ? (
                        <CheckCircleIcon className="success-color"/>
                       ) : (
                         <CancelIcon />
                       )}
                     </TableCell>
                   )}
                   {value.type === "list" && (
                     <TableCell
                       className="tableCell"
                       component="th"
                       scope="row"
                       align="center"
                       onClick={() => handleShowAllItems(row.id)}
                     >
                         <ol>
                           {value.value.map((item:any, index:number) => {
                             return (
                               <li key={index}>
                                 {item}
                               </li>
                             );
                           })}
                         </ol>
                     </TableCell>
                   )}
 
                   {value.type === "button" && (
                     <TableCell
                         className="tableCell"
                         align={value.align}
                     >
                       {value.buttons.map((but:any)=> {
                         return (
                             <Button
                                 key={but.id}
                                 variant="contained"
                                 color={but.color || 'primary'}
                                 onClick={() => but.fun(row.id)}
                                 className="mr-20"
                                 aria-label="button"
                             >
                               {but.value}
                             </Button>
                         )
                       })}
 
                     </TableCell>
                   )}
                   {value.type === "switch" && (
                         <TableCell className="tableCell">
                           {value.buttons.map((but:any) => {
                             return (
                               <>
                               {value.value + " "}

                                 <Switch
                                 color="success"
                                 checked={but.checked}
                                 onChange={() => but.fun(row.id)}
                                 />
                               </>
                             )
                           })}
                         </TableCell>
                   )}
                 </React.Fragment>
               );
             })}
           </TableRow>
         );
       })}
     </TableBody>
   );
 }
 
 export default PageTableBody;
 