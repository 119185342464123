import { Box, Button, Grid } from "@mui/material";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import Faq from "react-faq-component";
import { FaqDTO } from "../../models/faq/FaqDTO";
import { useEffect, useState } from "react";
import { FAQService } from "../../services/tickets/FAQService";
import { StorageService } from "../../storage/StorageService";
import './FAQ.css';
import { useNavigate } from "react-router-dom";
import { Routes } from "../../router/Routes";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { strings } from "../../localization/Localization";



export function FAQ() {
  const navigate = useNavigate();
  const [faq, setFaq] = useState<FaqDTO[]>([]);
  const [rows, setRows] = useState<[]>([]);

  async function getAllFaq() {
    return await FAQService.getAllFAQ()
  }

  async function donwloadFile(name: string, id: number) {
    await FAQService.downloadFileWithNoPrivileges(name, id)
      .catch((error) => {
        if (error.request) {
        }
      });
  }

  function createRows() {
    let dataRows: any = [];
    faq.forEach(item => {
      let obj = {
        title: item.title,
        content: <> <Box component="div" className="faqContent"> {item.content} </Box> <br /> <Box sx={{ display: 'inline-grid', paddingTop: '0.4rem' }}>{item.fileDTOs?.map(file => (
          <a key={file.id} style={{ color: '#22FF91', paddingTop: '0.2rem', cursor: 'pointer' }} onClick={() => donwloadFile(file.name, file.id)}><AttachFileIcon /> {file.name}</a>
        ))} </Box> </>
      }
      dataRows.push(obj);
    })
    setRows(dataRows);
  }

  function handleAddFAQ() {
    navigate(Routes.ADD_NEW_FAQ);
  }


  useEffect(() => {
    getAllFaq()
      .then((resp) => {
        setFaq(resp)
      });
  }, []);

  useEffect(() => {
    createRows()
  }, [faq])

  const data = {
    rows: rows
  };


  const styles = {
    bgColor: 'linear-gradient(#1C082E, #3B115D)',
    rowTitleColor: "#22FF91",
    arrowColor: "#22FF91",
    rowContentColor: "white",
    rowContentPaddingTop: '0.2em'
  };



  return (
    <>
      <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
        <Grid item xs={12} lg={8} md={12} sm={12}>
          <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content" header={true} title="FAQ">
            <Grid container className="mt-5 mb-5" justifyContent={"center"}>
              <Grid item width={'-moz-available'} className="gridWidth">
                {StorageService.hasAuthToken() && StorageService.getUserRole() === "ROLE_SUPPORT" &&
                  <Grid className="faq mb-2">
                    <Button className="greenBtn buttonCreateFaq mt-3" onClick={handleAddFAQ}>{strings.createNewFaq}</Button>
                  </Grid>}
                <Faq
                  data={data} styles={styles}
                />
              </Grid>
            </Grid>
          </CenteredCardLayout>
        </Grid>
      </Grid>
    </>
  )
}