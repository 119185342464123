import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StorageService } from "../../storage/StorageService";
import { Roles } from "../Roles";
import { Routes } from "../Routes";
import { AuthGuard } from "./AuthGuard";
import { Guard } from "./Guard";

class GuardedRouteProps {
  constructor(
    public component: ReactElement,
    public guards: Guard[],
    public requiredRoles?: Roles[] | null
  ) { }
}

export function GuardedRoute(props: GuardedRouteProps) {

  const navigate = useNavigate();

  async function checkUser() {
    let userRole = StorageService.getUserRole();
    for(const guard of props.guards) {
      if(guard.check()){
        if(typeof AuthGuard) {
          if (props.requiredRoles && (userRole === null || !props.requiredRoles?.includes(userRole)))
          navigate(Routes.UNAUTHORIZED)
        }
      }
    }
}

  useEffect(() => {
    checkUser();
  }, []);

  function checkAndBuildFallback(): ReactElement | null {
    for (const guard of props.guards) {
      if (!guard.check()) {
        if (typeof AuthGuard) {
          return guard.fallback;
        } 
      } else {
        checkUser();
      }
    }
    return null;
  }

  const fallback = checkAndBuildFallback();

  return fallback !== null ? fallback : props.component;
}
