import {PageTable} from "../../shared-components/table/PageTable";
import {useEffect, useState} from "react";
import {TransactionDTO} from "../../models/payments/TransactionDTO";
import {TransactionFilterDTO} from "../../models/filters/payments/TransactionFilterDTO";
import {TransactionService} from "../../services/payments/TransactionService";
import {DateTimeUtils} from "../../utils/DateTimeUtils";
import {Grid} from "@mui/material";
import "./Transactions.css"
import {Routes} from "../../router/Routes";
import {useNavigate} from "react-router-dom";
import {CenteredCardLayout} from "../../components/CenteredLayout";
import {Dayjs} from "dayjs";
import { TransactionStatusDTO } from "../../models/payments/TransactionStatusDTO";
import { strings } from "../../localization/Localization";

const Transactions = () => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [fromWallet, setFromWallet] = useState<string | undefined>(undefined)
    const [toWallet, setToWallet] = useState<string | undefined>(undefined)
    const [transactionId, setTransactionId] = useState<string | undefined>(undefined)
    const [amount, setAmount] = useState<number | undefined>(undefined)
    const [transactionStatusId, setTransactionStatusId] = useState<number | undefined>(undefined);
    const [transactionStatuses, setTransactionStatuses] = useState<TransactionStatusDTO[]>([]);
    const [date, setDate] = useState<string | null>(null)
    const [transactions, setTransactions] = useState<TransactionDTO[]>([])
    const [totalElements, setTotalElements] =useState<number>(0);
    const navigate = useNavigate();
    const headerRows = [
        {
            id: "id",
            label: "Id",
            search: false,
            type: "text",
            variant: "standard"
        },
        {
            id: "fromWallet",
            label: strings.fromWallet,
            search: true,
            type: "text",
            handleChange: handleChangeFromWallet,
            variant: "standard"
        },
        {
            id: "toWallet",
            label: strings.toWallet,
            search: true,
            type: "text",
            handleChange: handleChangeToWallet,
            variant: "standard"
        },
        {
            id: "amount",
            label: strings.amount,
            search: true,
            type: "number",
            handleChange: handleChangeAmount,
            variant: "standard"
        },
        {
            id: "transactionId",
            label: strings.transactionId,
            search: true,
            type: "text",
            handleChange: handleChangeTransactionId,
            variant: "standard"
        },
        {
            id: "transactionStatusId",
            value: transactionStatusId,
            search: true,
            label: strings.transactionStatus,
            elements: transactionStatuses,
            handleChange: handleChangeTransactionStatus,
            handleRemoveFilter: handleRemoveTransactionStatusFilter,
            type: "dropdown",
            variant: "standard",
            styling: {
              "& .MuiSelect-select": {
                borderBottom: '1px solid white',
                textAlign:"start"
            },
            "&::before": {
              borderBottom: '0px !important'
          },
          "&::after":{
            border: "0px"
          },
            svg:{color:"white"}},
          },
        {
            id: "dateCreated",
            label: strings.dateCreated,
            search: true,
            type: "datePicker",
            handleChange: handleChangeDate,
            variant: "standard"
        }
    ]
    function handleRowClick(id: number){
        navigate(Routes.TRANSACTION_DETAILS.replace(":id", id.toString()));
    }
    function handleChangeFromWallet(event: React.ChangeEvent<HTMLInputElement>) {
        setFromWallet(event.target.value !== "" ? event.target.value : undefined)
        setPage(0)
    }
    function handleChangeToWallet(event: React.ChangeEvent<HTMLInputElement>) {
        setToWallet(event.target.value !== "" ? event.target.value : undefined)
        setPage(0)
    }
    function handleChangeTransactionId(event: React.ChangeEvent<HTMLInputElement>) {
        setTransactionId(event.target.value !== "" ? event.target.value : undefined)
        setPage(0)
    }
    function handleChangePage(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) {
        setPage(newPage)
    }
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    function handleChangeDate(date: Dayjs){
        setDate(DateTimeUtils.formatDateDayJs(date))
        setPage(0)
    }
    function handleChangeAmount(event : React.ChangeEvent<HTMLInputElement>){
        setAmount(+event.target.value)
        setPage(0)
    }
    function handleChangeTransactionStatus(
        event: React.ChangeEvent<HTMLInputElement>
      ) {
        setTransactionStatusId(+event.target.value);
        setPage(0);
      }
    function handleRemoveTransactionStatusFilter() {
        setTransactionStatusId(undefined);
        setPage(0);
      }

    useEffect(() => {
        async function getAllTransactions() {
            const response = await TransactionService.getAllTransactionsPaged(page, rowsPerPage, new TransactionFilterDTO(toWallet, fromWallet, amount, transactionId, transactionStatusId ,date))
            setTransactions(response.content)
            setTotalElements(response.totalElements);
            setPage(page)
        }
        getAllTransactions()
            .then(() => {
            });
    }, [fromWallet, toWallet, amount, transactionId, transactionStatusId, date, page, rowsPerPage]);

    useEffect(() => {
        async function getAllConversationStatuses() {
            const response =
              await TransactionService. getTransactionStatuses();
              response.unshift({id:0,description:"placeholder",name:"Status"})
              setTransactionStatuses(response);
          }
          getAllConversationStatuses().then(() => { });
    }, [])

    const rows = transactions.map((element) => {
        return {
            id: element.id,
            values: [
                {
                    type: "text",
                    name: "id",
                    value: element.id,
                },
                {
                    type: "text",
                    name: "fromWallet",
                    value: element.fromWallet
                },
                {
                    type: "text",
                    name: "toWallet",
                    value: element.toWallet
                },
                {
                    type: "text",
                    name: "amount",
                    value: element.amount,
                },
                {
                    type: "text",
                    name: "transactionId",
                    value: element.transactionId,
                },
                {
                    type: "text",
                    name: "transactionStatus",
                    value: element.transactionStatus?.name,
                },
                {
                    type: "text",
                    name: "dateCreated",
                    value: DateTimeUtils.formatDate(element?.dateCreated?.toString()),
                }
            ],
        };
    })

    return (
        <>
            <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
                <Grid item xs={12} lg={8} md={12} sm={12}>
                    <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content" header={true} title={strings.transactions}>
                        <div className="tableContainer">
                        <PageTable
                            headerRows={headerRows}
                            rows={rows}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            totalElements={totalElements}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleOpen = {handleRowClick}
                            clickable = {true}
                        />
                        </div>
                    </CenteredCardLayout>
                </Grid>
            </Grid>
        </>
    )
}

export default Transactions;