import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2)
      },
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);

export default function Loader() {
  const classes = useStyles();

  return (
    <Box sx={{ display: 'flex' }} className={classes.root}>
      <CircularProgress sx={{color:"var(--green)"}} size={100}/>
    </Box>
  );
}
