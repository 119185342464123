export const enStrings = {
    login: "Login",
    emailAddress: "Email address",
    participantEmail: "Participant email address",
    password: "Password",
    forgotYourPassword: "Forgot your password?",
    noAccount: "Don't have an account?",
    register: "Register",
    welcomeBack: "Welcome back!",
    pleaseLogin: "Please login to your account.",
    invalidUserOrPassword: "Invalid username or password.",
    clubsAthletes: "Clubs / Athletes",
    club: "Club",
    athletes: "Athletes",
    athleteName: "Athlete's name",
    fans: "Fans",
    alreadyHaveAnAccount: "Already have an account?",
    addYourDetailsToRegister: "Add your details to register",
    firstName: "First Name",
    lastName: "Last Name",
    enterFirstName: "Please enter a first name",
    enterLastName: "Please enter a last name",
    enterName: "Please enter a name",
    enterEmail: "Please enter a valid email address",
    enterAddress: "Please enter an address",
    dateOfBirth: "Date of birth",
    enterDateOfBirth: "Please enter a date of birth",
    mobileNumber: "Mobile number",
    enterNumber: "Please enter a valid number",
    confirmPassword: "Confirm password",
    enterPassword: "Please enter a valid password",
    matchPassword: "Please enter a matching password",
    continue: "continue",
    address: "Address",
    country: "Country",
    chooseCountry: "Please choose a country",
    male: "male",
    female: "female",
    resetPassword: "Reset password",
    chooseResetPassword: "Please choose a way to reset your password",
    sms: "SMS",
    resetLabel: "reset",
    passwordLabel: "password",
    note: "Note: Please enter mobile number without country code or without leading zero.",
    new: "new",
    didntReceive: "Didn't receive?",
    clickHere: "Click here",
    checkYourNumber: "Check your mobile number",
    enterTheCode: "and enter the code",
    associatedSport: "Associated sport",
    chooseGender: "Please choose a gender",
    yourPhoto: "Your photo (max 2b)",
    chooseSport: "Please choose a sport",
    attachPicture: "Please attach a valid photo",
    associatedClub: "Club name you are associated with",
    chooseClub: "Please choose a club",
    sportDescription: "Sport's description",
    shortDescription: "Short description",
    enterDescription: "Please enter a short description",
    ImNotRobot: "I'm not a robot",
    checkCaptcha: "Please check the captcha",
    dragAndDrop: "Drag and drop files here",
    or: "or",
    browseFiles: "browse files",
    clubName: "Club name",
    clubAddress: "Club address",
    clubMobile: "Club phone number",
    clubRepresentativeName: "Club representative name",
    enterClubName: "Please enter a club name",
    representativeName: "Representative name",
    enterRepresentativeName: "Please enter a representative name",
    captcha: "Captcha",
    logout: "logout",
    clubs: "Clubs",
    ambassadorName: "Ambassador name",
    sportAmbassadors: "Sport ambassadors",
    sportAmbassador: "Sport ambassador",
    welcome: "Welcome",
    myProfile: "My profile",
    accountingBalance: "Accounting balance",
    transactionHistory: "Transaction history",
    myAffiliates: "My affiliates",
    more: "...more",
    athleteAddress: "Athlete address",
    dateCreated: "Date created",
    dateModified: "Date modified",
    role: "Role",
    affiliateLink: "Affiliate link",
    saveChanges: "Save changes",
    update: "update",
    affiliateName: "Affiliate name",
    supported: "Supported",
    noteAffiliates: "*Note: The support of affiliates is entered in percentage (%). The sum of all affiliates must equal 100%",
    administrator: "administrator",
    administrators: "administrators",
    user: "user",
    users: "users",
    athlete: "athlete",
    support: "support",
    supports: "Supports",
    both: "Both",
    active: "Active",
    inactive: "Inactive",
    enabled: "Enabled",
    userProfile: "User profile",
    supportAffiliate: "support",
    unsupportAffiliate: "unsupport",
    supportUser: "Support user",
    unsupportUser: "Unsupport user",
    confirmSupport: "Are you sure you want to support this user?",
    confirmUnsupport: "Are you sure you want to unsupport this user?",
    cancel: "cancel",
    confirm: "confirm",
    euroWallet: "Euro wallet",
    tradingWallet: "Trading wallet",
    shoppingWallet: "Shopping wallet",
    wallets: "Wallets",
    transactions: "Transactions",
    transactionId: "Transaction ID",
    fromWallet: "From wallet",
    toWallet: "To wallet",
    amount: "Amount",
    status: "Status",
    type: "Type",
    dateOfCreation: "Date of creation",
    dateOfApproval: "Date of approval",
    transactionStatus: "Transaction status",
    fromUser: "From user",
    toUser: "To user",
    newFaq: "New FAQ",
    createNewFaq: "Create new FAQ",
    question: "Question",
    enterQuestion: "Please enter a question",
    answer: "Answer",
    enterAnswer: "Please enter an answer",
    files: "Files",
    to: "to",
    browseFilesFAQ: "browse files",
    create: "create",
    name: "Name",
    mine: "Mine",
    details: "Details",
    assigned: "Assigned",
    createNewTicket: "Create new ticket",
    content: "Content",
    enterContent: "Please enter content",
    enterTicketName: "Please enter name",
    title: "Title",
    reason: "Reason",
    chooseReason: "Please choose a reason",
    enterTitle: "Please enter title",
    enterParticipantEmail: "Please enter valid participant email",
    assignTicket: "Assign ticket",
    closeTicket: "Close ticket",
    reassignTicket: "Reassign ticket",
    readMore: "... read more",
    showLess: "show less",
    confirmAssign: "Confirm assign",
    confirmAssignTicket: "Are you sure you want to assign this ticket?",
    confirmClose: "Confirm close",
    confirmCloseTicket: "Are you sure you want to close this ticket?",
    supportTickets: "Support tickets",
    enterNewMessage: "Enter new message*",
    cantUploadPhoto: "Can't upload image!",
    successfullyUpdate: "Successfully updated!",
    sumPercentage: "The sum of the percentages must be 100%!",
    successfullyAddedTicket: "Successfully added new support ticket!",
    unsuccessfullyAddedTicket: "Unuccessfully added new support ticket!",
    successfullyAddedFAQ: "Successfully added new FAQ!",
    unsuccessfullyAddedFAQ: "Unsuccessfully added new FAQ!",
    successfullyClosedTicket: "Successfully closed ticket!",
    unsuccessfullyClosedTicket: "Unsuccessfully closed ticket!",
    successfullyAssignedTicket: "Successfully assigned ticket!",
    alreadyAssignedTicket: "Ticket is already assigned!",
    successfullyReassignedTicket: "Successfully reassigned ticket!",
    unsuccessfullyReassignedTicket: "Unsuccessfully reassigned ticket!",
    successfullyAddedNewMessage: "Successfully added new message!",
    unsuccessfullyAddedNewMessage: "Unsuccessfully added new message!",
    alreadySupportUser: "You already support this user!",
    alreadyDontSupportUser: "You don't support this user!"
}