import IconButton from '@mui/material/IconButton';
import { Close } from "@mui/icons-material";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";


export function CustomSelect(props:any) {
    return (
        <>
            <FormControl className="relative" style={{width: props.customWidth}}>
            <InputLabel id="demo-simple-select-label">{props.inputLabel}</InputLabel>
            <Select
                fullWidth
                endAdornment = {
                    <> 
                        {
                            props.value && <IconButton onClick={props.handleRemoveFilter} style={{marginRight: "15px", height:"15px"}}> 
                                <Close fontSize="small"/>
                            </IconButton>                      
                        }
                    </>
                }
                name={String(props.id)}
                label={props.label}
                sx={props.styling}
                onChange={props.handleChange}
                value={props.value ? props.value.toString() : 0}
                variant={props.variant}
                SelectDisplayProps={{
                    style: {
                        paddingRight: 40,
                        color: "white !important"
                    }
                }}
            >
                {props.elements.map((element:any) => (
                    <MenuItem key={element.id} value={element.id} disabled={element.id===0}>{element.name}</MenuItem>
                ))}
                
            </Select>
                </FormControl>
        </>
    );
}

