import {WalletDTO} from "./WalletDTO";
import {TransactionStatusDTO} from "./TransactionStatusDTO";
import {TransactionTypeDTO} from "./TransactionTypeDTO";

export class TransactionDTO{
    id: number;
    fromFirstName: string;
    fromLastName: string;

    fromWallet: string;
    toFirstName: string;
    toLastName: string;
    toWallet: string;
    amount: number;
    transactionId: string;
    transactionStatus: TransactionStatusDTO;
    dateCreated: Date | undefined;
    dateApproved: Date | undefined;
    transactionType: TransactionTypeDTO;
    transaction: TransactionDTO | undefined;

    constructor(json:any) {
        this.id = json.id;
        this.fromFirstName = json.fromFirstName;
        this.fromLastName = json.fromLastName;
        this.fromWallet = json.fromWallet;
        this.toFirstName = json.toFirstName;
        this.toLastName = json.toLastName;
        this.toWallet = json.toWallet;
        this.amount = json.amount;
        this.transactionId = json.transactionId;
        this.transactionStatus = json.transactionStatus;
        this.dateCreated = json.dateCreated ? new Date(json.dateCreated) : undefined;
        this.dateApproved = json.dateApproved ? new Date(json.dateApproved) : undefined;
        this.transactionType = json.transactionType;
        this.transaction = json.transaction;
    }
}