import Grid from "@mui/material/Grid";
import { CenteredCardLayout } from "../../components/CenteredLayout";

import './ComingSoon.css'
export function ComingSoon() {
        return (
        <>
            <Grid
                container
                alignItems={"flex-start"}
                alignContent={"flex-start"}
                justifyContent={"center"}
                spacing={0}
                rowGap={0}
            >
                <Grid item xs={12} lg={8} md={12} sm={12}>
                    <CenteredCardLayout
                        footer={true}
                        navbar={true}
                        sidebar={true}
                        className="main-content"
                        header={true}
                        title={"Coming soon"}
                    >
                    <Grid container>
                        <Grid item xs={12}>
                        <h1 className="greenTextSoon">Coming Soon!</h1>
                        </Grid>
                    </Grid>
                    </CenteredCardLayout>
                </Grid>
            </Grid>
        </>

    )
}
