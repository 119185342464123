import { Container, Grid, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { ConversationDTO } from "../../models/conversation/ConversationDTO";
import { ConversationStatusDTO } from "../../models/conversation/ConversationStatusDTO";
import { ConversationTypeDTO } from "../../models/conversation/ConversationTypeDTO";
import { ConversationFilter } from "../../models/filters/conversation/ConversationFilter";
import { Routes } from "../../router/Routes";
import { ConversationService } from "../../services/tickets/ConversationService";
import { ConversationStatusService } from "../../services/tickets/ConversationStatusService";
import { ConversationTypeService } from "../../services/tickets/ConversationTypeService";
import { PageTable } from "../../shared-components/table/PageTable";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./conversations.css";
import { UserService } from "../../services/users/UserService";
import { UserDTO } from "../../models/users/UserDTO";
import { StorageKeys } from "../../storage/StorageKeys";
import { strings } from "../../localization/Localization";

export function Conversations() {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserDTO>();

  const [conversations, setConversations] = useState<ConversationDTO[]>([]);
  const [conversationTypes, setConversationTypes] = useState<ConversationTypeDTO[]>([]);
  const [conversationStatuses, setConversationStatuses] = useState<ConversationStatusDTO[]>([]);
  const [name, setConversationName] = useState<string | undefined>(undefined);
  const [participantEmail, setParticipantEmail] = useState<string | undefined>(undefined);
  const [conversationTypeId, setConversationTypeId] = useState<number | string | undefined>(undefined);
  const [conversationStatusId, setConversationStatusId] = useState<number | string | undefined>(undefined);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalElements, setTotalElements] = useState<number>(0);

  const headerRows = [
    {
      id: "name",
      label: strings.name,
      search: true,
      type: "text",
      handleChange: handleChangeConversationName,
      variant: "standard"
    },
    {
      id: "participantEmail",
      label: strings.emailAddress,
      search: true,
      type: "text",
      handleChange: handleChangeParticipantEmail,
      variant: "standard"
    },
    {
      id: "conversationStatusId",
      value: conversationStatusId,
      search: true,
      label: strings.status,
      elements: conversationStatuses,
      handleChange: handleChangeConversationStatus,
      handleRemoveFilter: handleRemoveConversationStatusFilter,
      type: "dropdown",
      variant: "standard",
      styling: {
        "& .MuiSelect-select": {
          borderBottom: '1px solid white',
          textAlign:"start"
      },
      "&::before": {
        borderBottom: '0px !important'
    },
    "&::after":{
      border: "0px"
    },
      svg:{color:"white"}},
    },
    {
      id: "conversationTypeId",
      value: conversationTypeId,
      label: strings.type,
      search: true,
      elements: conversationTypes,
      handleChange: handleChangeConversationType,
      handleRemoveFilter: handleRemoveConversationTypeFilter,
      type: "dropdown",
      variant: "standard",
      styling: {
        "& .MuiSelect-select": {
          borderBottom: '1px solid white',
          textAlign:"start"
      },
      "&::before": {
        borderBottom: '0px !important'
      },
      "&::after":{
        border: "0px"
      },
      svg:{color:"white"}},
      input:{color:"white"}
    },
    user?.roles.find(item => item.role === "ROLE_SUPPORT") ?
      {
        id: "assignedUserEmail",
        label: strings.mine,
        search: false,
        type: "text",
        variant: "standard",
      } : {
        id: "assignedUserEmail",
        label: strings.assigned,
        search: false,
        type: "text",
        variant: "standard",
      },
  ];

  function handleChangeConversationName(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setConversationName(
      event.target.value !== "" ? event.target.value : undefined
    );
    setPage(0);
  }

  function handleChangeParticipantEmail(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setParticipantEmail(
      event.target.value !== "" ? event.target.value : undefined
    );
    setPage(0);
  }

  function handleChangeConversationType(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setConversationTypeId(+event.target.value);
    setPage(0);
  }

  function handleChangeConversationStatus(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setConversationStatusId(+event.target.value);
    setPage(0);
  }

  function handleRemoveConversationTypeFilter() {
    setConversationTypeId(undefined);
    setPage(0);
  }

  function handleRemoveConversationStatusFilter() {
    setConversationStatusId(undefined);
    setPage(0);
  }

  useEffect(() => {
    async function getAllConversations() {
      const response = await ConversationService.getAllConversationsPaged(
        page,
        rowsPerPage,
        new ConversationFilter(
          name,
          participantEmail,
          conversationTypeId,
          conversationStatusId
        )
      );
      setConversations(response.content);
      setTotalElements(response.totalElements);
      setPage(page);
    }
    getAllConversations().then(() => { });
  }, [
    page,
    rowsPerPage,
    name,
    participantEmail,
    conversationTypeId,
    conversationStatusId
  ]);

  useEffect(() => {
    async function getLoggedUser(token: string | null) {
      const response = await UserService.getLoggedUser(token!)
      setUser(response)
    }

    getLoggedUser(localStorage.getItem(StorageKeys.AUTH_TOKEN));
  }, []);

  useEffect(() => {
    async function getAllConversationTypes() {
      const response = await ConversationTypeService.getAllConversationTypes();
      response.unshift({id:0,description:"placeholder",name:strings.type})
      setConversationTypes(response);
    }

    async function getAllConversationStatuses() {
      const response =
        await ConversationStatusService.getAllConversationStatuses();
        response.unshift({id:0,description:"placeholder",name:strings.status})
      setConversationStatuses(response);
    }
    getAllConversationStatuses().then(() => { });
    getAllConversationTypes().then(() => { });
  }, []);

  const rows = conversations.map((element) => {
    return {
      id: element.id,
      values: [
        {
          type: "text",
          name: "name",
          value: element.name
        },
        {
          type: "text",
          name: "participantEmail",
          value: element.participantEmail
        },
        {
          type: "text",
          name: "statusName",
          value: element.conversationStatusDTO.name
        },
        {
          type: "text",
          name: "typeName",
          value: element.conversationTypeDTO.name
        },
        user?.roles.find(item => item.role === "ROLE_SUPPORT") ?
          {
            type: "text",
            value: element.assignedUserEmail === user?.email ? <CheckCircleIcon sx={{ color: "var(--green)" }} /> : <CancelIcon sx={{ color: "white" }} />
          } : {
            type: "text",
            value: element.assignedUserEmail ? <Tooltip title={element.assignedUserEmail}><CheckCircleIcon sx={{ color: "var(--green)" }} /></Tooltip> : <CancelIcon  sx={{ color: "white" }} />
          }
      ],
    };
  });

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleOpen(id: number) {
    navigate(Routes.CONVERSATION_DETAILS.replace(":id", id.toString()));
  }

  return (
    <>
      <Grid
        container
        alignItems={"flex-start"}
        alignContent={"flex-start"}
        justifyContent={"center"}
        spacing={0}
        rowGap={0}
      >
        <Grid item xs={12} lg={8} md={12} sm={12}>
          <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content" header={true} title={strings.supportTickets}>
          <Container className="tableContainer">
            <PageTable
              headerRows={headerRows}
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              totalElements={totalElements}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              clickable={true}
              handleOpen={handleOpen}
            />
            </Container>
          </CenteredCardLayout>
        </Grid>
      </Grid>
    </>
  );
}
