
export class TransactionFilterDTO{
    toWalletAddress : string | undefined;
    fromWalletAddress : string | undefined;
    amount : number | undefined;
    transactionId : string | undefined;
    transactionStatus : number | undefined;
    dateCreated : string | null;

    constructor(toWalletAddress: string | undefined, fromWalletAddress: string | undefined, amount: number | undefined, transactionId: string | undefined, transactionStatus: number | undefined, dateCreated: string | null) {
        this.toWalletAddress = toWalletAddress;
        this.fromWalletAddress = fromWalletAddress;
        this.amount = amount;
        this.transactionId = transactionId;
        this.transactionStatus = transactionStatus;
        this.dateCreated = dateCreated;
    }

}