import { ConversationStatusDTO } from "./ConversationStatusDTO";
import { ConversationTypeDTO } from "./ConversationTypeDTO";


export class ConversationDTO{
    id : number;
    name : string;
    conversationTypeDTO : ConversationTypeDTO;
    participantEmail : string;
    assignedUserEmail: string;
    conversationStatusDTO: ConversationStatusDTO;
    dateCreated: Date;
    dateModified: Date;
    

    constructor(json:ConversationDTO) {
        this.id = json.id;
        this.name = json.name;
        this.conversationTypeDTO = json.conversationTypeDTO;
        this.participantEmail = json.participantEmail;
        this.assignedUserEmail = json.assignedUserEmail;
        this.conversationStatusDTO = json.conversationStatusDTO;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
    }

}