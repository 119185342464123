import {Grid} from "@mui/material";
import React from "react";
import logo from "../assets/images/logo.svg";

type LayoutProps = {
    children?: React.ReactNode;
    minWidth?: string;
    maxWidth?: string;
    minHeight?: string;
    maxHeight?: string;
    className? : string;
    width? : string;
    height? : string;
    marginTop? : string;
}

export function Logo(props: LayoutProps) {
    return (
        <Grid container justifyContent={"center"} display={"flex"} alignItems={"center"} className={props.className}>
            <img src={logo} width={props.width} height={props.height} style={{marginTop: props.marginTop }}/>
        </Grid>
    )
}