import { Button, Grid, Paper, TextField, Tooltip } from "@mui/material";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { Form, useNavigate } from "react-router-dom";
import { Item, Label } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { FAQService } from "../../services/tickets/FAQService";
import { Routes } from "../../router/Routes";
import "./FAQ.css";
import { FaqCreationDTO } from "../../models/faq/FaqCreationDTO";
import { SwalService } from "../../utils/SwalService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactElement } from "react";
import { strings } from "../../localization/Localization";

export function AddFAQ() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<FaqCreationDTO>({
    defaultValues: {},
    mode: "onChange",
  });
  watch();

  function addFAQ() {
    let form = new FormData()
    form.append("question", getValues("title"))
    form.append("answer", getValues("content"))

    for (var i = 0; i < getValues("files")!.length; i++) {
      form.append("files", getValues("files")![i])
    }
    FAQService.createFAQ(form).then(() => {
      SwalService.showSwalMessage(strings.successfullyAddedFAQ, "", <CheckCircleIcon sx={{ transform: "scale(4)" }} />).then(() => {
        navigate(Routes.FAQ);
      })
    }).catch((error) => {
      if (error) {
        SwalService.showSwalMessage(strings.unsuccessfullyAddedFAQ, "", <CancelIcon sx={{ transform: "scale(4)" }} />)
      }
    });
  }

  function getFileNames(): Array<ReactElement> {
    let list: Array<File> | undefined = getValues("files")
    let listStrings: Array<ReactElement> = []
    if (list !== undefined) {
      for (let i = 0; i < list.length; i++) {
        listStrings.push(<p key={list[i].name} style={{ color: "white", fontSize: "13px" }}>{
          <>{list[i].name} &nbsp;&nbsp;</>
        } </p>)
      }
    }
    return listStrings
  }


  function handleCancel() {
    navigate(-1);
  }

  return (
    <>
      <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
        <Grid item xs={12} lg={8} md={12} sm={12}>
          <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content" header={true} title={strings.newFaq}>
            <Grid container className="mt-5 mb-5" justifyContent={"center"}>
              <div style={{width:"85%"}}>
              <Form onSubmit={handleSubmit(addFAQ)} className="text-left mt-3 roundCorner">
              <Paper sx={{background:"var(--gradient)", width:"100%"}} className="form" elevation={24}>
                <Grid className="roundCorner" container spacing={4}>
                  <Grid item xs={12} lg={12} md={12} sm={12}>   
                    <Item>
                      <Label>
                        {strings.question}
                      </Label>
                      <Tooltip title={strings.enterQuestion} placement="top-start" open={errors.title !== undefined}>
                      <TextField
                        {...register("title", { required: true })}
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={errors.title !== undefined}
                        style={{ background: 'white', borderRadius: '6px' }}
                      />
                      </Tooltip>
                    </Item>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Item>
                      <Label>
                        {strings.answer}
                      </Label>
                      <Tooltip title={strings.enterAnswer} placement="top-start" open={errors.content !== undefined}>
                      <TextField
                        {...register("content", { required: true })}
                        variant="outlined"
                        rows={3}
                        fullWidth={true}
                        multiline={true}
                        error={errors.content !== undefined}
                        style={{ background: 'white', borderRadius: '6px' }}
                      />
                      </Tooltip>
                    </Item>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Item>
                      <Label>
                        {strings.files}
                      </Label>
                      <Grid display="flex" justifyContent="start">
                        <input
                          {...register("files")}
                          style={
                            { display: "none" }}
                          accept="*"
                          id="raised-button-file"
                          type="file"
                          multiple
                        />
                        {getFileNames()}
                      </Grid>
                      <label htmlFor="raised-button-file" className="w-100">
                        <Button component="span" style={{ width: "100%", height: "80%", display: "flex", flexDirection: "column", fontFamily: 'Varsity, sans-serif', border: "1px solid #ffffff", borderRadius: "2px" }}>
                          <p style={{ color: '#ffffff', marginBottom: "5px", fontSize: "10px" }}>{strings.clickHere}</p>
                          <p style={{ color: '#ffffff', marginBottom: "5px", fontSize: "10px" }}>{strings.to}</p>
                          <p style={{ color: '#22FF90', marginBottom: "5px", fontSize: "10px" }}>{strings.browseFiles}</p>
                        </Button>
                      </label>
                    </Item>
                  </Grid>
                </Grid>
                </Paper>
                <Grid className="createFaq d-flex justify-content-end">
                  <Button onClick={handleCancel}  className="purpleBtn yellowBtn cancel mt-3">{strings.cancel}</Button>
                  <Button type="submit" className="greenBtn mt-3">{strings.create}</Button>
                </Grid>
                </Form>
              </div>
            </Grid>
          </CenteredCardLayout>
        </Grid>
      </Grid>
    </>
  )
}