import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton, TextField } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import { Dayjs } from "dayjs";
import { DateTimeUtils } from "../utils/DateTimeUtils";

interface props {
    onChange?: (value: Dayjs | null) => void | Promise<void>,
    label?: string,
}

export function NullableDatePicker({ onChange, label }: props) {

    const [value, setValue] = useState<string | null>(null);

    const changeHandler = (date: Dayjs | null) => {
        setValue(date ? DateTimeUtils.formatDateDayJs(date) : null);
        if (onChange) {
            onChange(date);
        }
    };


    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    onChange={changeHandler}
                    value={value}
                    label={label}
                    inputFormat="DD.MM.YYYY"
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            variant="standard"
                            sx={{
                                "& .MuiInput-root": {
                                    borderBottom: '1.5px solid white'
                                },
                                svg:{color:"white"},
                                maxWidth:"95%"
                            }}
                            onKeyDown={e => e.preventDefault()}
                        />
                    )}
                    componentsProps={{
                        actionBar: {
                            actions: ["today"],
                        },
                    }}
                    InputProps={{
                        endAdornment: value && (
                            <IconButton sx={{ height: "10px", width: "10px" }}
                                onClick={() => changeHandler(null)}>
                                <Clear />
                            </IconButton>
                        ),
                    }}
                    InputAdornmentProps={{
                        position: "start",
                    }}></DatePicker>
            </LocalizationProvider>
        </>
    )
}