import { Page } from "../../components/Page"
import { Logo } from "../../components/Logo"
import { Octagon } from "../../components/Octagon"
import { useEffect, useState } from "react";
import { Box, Button, Grid, Link, styled, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import ReactCodeInput from "react-code-input";
import { UserService } from "../../services/users/UserService";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../router/Routes";


export function VerifyCode() {

    const [setAnimation, setAnimationState] = useState(false);
    const [verifyCode, setVerifyCode] = useState("");
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();

    const sendCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        UserService.confirmPasswordCode(parseInt(verifyCode)).then(resp =>
            animationForm('login')
        )
    }

    const animationForm = (toRedirect: string) => {
        setAnimationState(true)

        setTimeout(() => {
            setAnimationState(false)
            if (toRedirect === 'reset')
                navigate(Routes.RESETPASSWORDMOBILE)
            else
                navigate(Routes.LOGIN)
        }, 500);
    }

    const codeForm = <>
        <Grid container spacing={5} direction="column" justifyContent="center">
            <Grid item style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} marginTop={10} >
                <Typography variant="h3" color="white" fontSize={30}>
                    VERIFICATION CODE
                </Typography>
                <Typography variant="body2" color="white">
                    Plese enter the verification code that you received
                </Typography>
            </Grid>
            <Grid item marginTop={1}>
                <ReactCodeInput inputMode="numeric" type="number" name="mobileCode" value={verifyCode} fields={6} autoFocus={true} onChange={e => setVerifyCode(e)} />
            </Grid>
            <Grid item marginTop={2}>
                <Button className="btn-sm borderedButton" onClick={(e) => sendCode(e)}>
                    <Box component="span" className="borderedButtonBox">
                        CONFIRM
                    </Box>
                </Button>
            </Grid>
            <Grid item marginTop={2}>
                <Typography variant="body1" color="white" fontSize={14}>Didn't receive a code? <Button style={{ color: '#22FF90', textDecoration: 'none', fontWeight: 'bold' }} onClick={() => animationForm("reset")}>
                    RESEND
                </Button></Typography>
            </Grid>
        </Grid>
    </>


    return <Page 
        component={
            <>
                <Logo className="mt-3" />
                <div className="container">
                    <div className="d-flex justify-content-center mt-3">
                        {matches ? <Octagon elements={codeForm} classAnimate={setAnimation ? "container-out" : "container-in"} size={500} />
                            : <>{codeForm}</>}

                    </div>
                </div>
            </>
        }
    />

}