
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import { ReactElement } from "react";
import { strings } from "../localization/Localization";


interface IProps {
    open: boolean,
    handleClose: () => void,
    handleConfirm: () => void,
    title: string,
    content:string | ReactElement,
    actionButtonName:string,
    width?: string
}

export default function ConfirmationDialog ({ open, handleClose, handleConfirm, title, content, actionButtonName, width }: IProps) {

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{color:"white !important"}}
                PaperProps={{
                    style: {
                      background: "var(--gradient)",
                      width: width
                    }
                  }}
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent
                >
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="purpleBtn" onClick={handleClose}>{strings.cancel}</Button>
                    <Button className="greenBtn" onClick={handleConfirm} color={"error"}>
                        {actionButtonName}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
