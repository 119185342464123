import axios from "../../network/AxiosInstance";
import { ConversationReasonDTO } from "../../models/conversation/ConversationReasonDTO";


export class ConversationReasonService {

    static async getAllConversationReasons(): Promise<Array<ConversationReasonDTO>> {
        const response = await axios.get<Array<ConversationReasonDTO>>(`/rest/conversation-reason/all`);
        const data = response.data;
        return data;
    }
}
