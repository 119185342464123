export class ConversationFilter {
    name : string | undefined;
    participantEmail : string | undefined;
    conversationTypeId : number | string | undefined;
    conversationStatusId : number | string | undefined;

    constructor(name : string | undefined, participantEmail : string | undefined, conversationTypeId : number | string | undefined, conversationStatusId : number | string | undefined) {
        this.name = name;
        this.participantEmail = participantEmail;
        this.conversationTypeId = conversationTypeId;
        this.conversationStatusId = conversationStatusId;
    }
}