import {UserFilter} from "../../models/filters/users/UserFilter";
import {Page} from "../../pagination/Page";
import {UserDTO} from "../../models/users/UserDTO";
import axiosInstance from "../../network/AxiosInstance";
import {RoleDTO} from "../../models/users/RoleDTO";

export class RoleService {
    static async getAllRoles(): Promise<RoleDTO[]>{
        const response = await axiosInstance.get<RoleDTO[]>(
            `/rest/role/all/`
        );
        return  response.data
    }
}