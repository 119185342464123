import { PersonDTO } from "./PersonDTO";
import { RoleDTO } from "./RoleDTO";
import * as buffer from "buffer";
import {ClubDTO} from "./ClubDTO";
import {AthleteInfoDTO} from "./AthleteInfoDTO"

export class UserDTO{
    id: number;
    dateCreated: Date;
    dateModified: Date;
    email: string;
    enabled?: boolean;
    person: PersonDTO;
    roles: RoleDTO[];
    affiliateLink:string
    clubInfo?: ClubDTO|null;
    athleteInfo?:AthleteInfoDTO|null;
    profilePicture?: Uint8Array;
    companyWalletBalance: number;

    constructor(json:any) {
        this.id = json.id;
        this.dateCreated = new Date(json.dateCreated);
        this.dateModified = new Date(json.dateModified);
        this.email = json.email;
        this.enabled = json.enabled;
        this.person = json.person;
        this.companyWalletBalance = json.companyWalletBalance;
        this.roles = json.roles
        this.affiliateLink=json.affiliateLink
        this.clubInfo=json.clubInfo
        this.athleteInfo=json.athleteInfo;
        this.profilePicture=json.profilePicture
    }
}