export class Routes {
    public static LOGIN = "/login";
    public static REGISTER = "/register";
    public static MAIN = "/homepage";
    public static USER_WITH_ID="/user/:id";
    public static PROFILE="/profile";
    public static FAQ = "/faq";
    public static ADD_NEW_FAQ = "/add-faq";
    public static CONVERSATIONS = "/support-tickets";
    public static CONVERSATIONS_USER = "/support-tickets-user";
    public static CONVERSATION_DETAILS = "/support-ticket/:id";
    public static ADD_NEW_CONVERSATION = "/add-support-ticket";
    public static UNAUTHORIZED = "/unauthorized";
    public static BUY_KIXX = "/buy-kixx";
    public static WALLET = "/wallet";
    public static TRADING = "/trading";
    public static GAMING = "/gaaming";
    public static NFT = "/nft"
    public static HOMEPAGE = "/";
    public static TRANSACTIONS = "/transactions";
    public static TRANSACTIONS_USER = "/transactions-user";
    public static REGISTERFAN = "/register-fan";
    public static REGISTERCLUBATHLETE = "/register-club-athlete";
    public static RESETPASSWORDMOBILE = "/reset-password-mobile";
    public static NEWPASSWORD = "/new-password";
    public static RESETPASSWORDCODE = "/reset-password-code";
    public static LOADINGPAGE = "/loading-page";
    public static TRANSACTION_DETAILS = "/transaction/:id";
    public static VERIFYCODE = "/verify-code"
    public static CLUBS = "/clubs";
    public static ATHLETES = "/athletes";
    public static SPORT_AMBASSADORS = "/sport-ambassadors";
    public static USERS = "/users";
    public static SUPPORTS = "/supports";
    public static ADMINS = "/admins";
    public static AFFILIATE = "/affiliate/:id";
    public static COMINGSOON = "/coming-soon";
}
