import axios from "../../network/AxiosInstance";
import { ConversationTypeDTO } from "../../models/conversation/ConversationTypeDTO";


export class ConversationTypeService {

    static async getAllConversationTypes(): Promise<Array<ConversationTypeDTO>> {
        const response = await axios.get<Array<ConversationTypeDTO>>(`/rest/conversation-type/all`);
        const data = response.data;
        return data;
    }
}
