import { Button, Grid, TextField, Select, FormControl, Tooltip, MenuItem, InputLabel, Paper } from "@mui/material";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { Form, useNavigate } from "react-router-dom";
import { Item } from "semantic-ui-react";
import { Controller, useForm } from "react-hook-form";
import { Routes } from "../../router/Routes";
import { SwalService } from "../../utils/SwalService";
import { InternalTicketService } from "../../services/tickets/InternalTicketService";
import { StorageService } from "../../storage/StorageService";
import { ExternalTicketService } from "../../services/tickets/ExternalTicketService";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactElement, useEffect, useState } from "react";
import { ConversationReasonService } from "../../services/tickets/ConversationReasonService";
import { ConversationReasonDTO } from "../../models/conversation/ConversationReasonDTO";
import { strings } from "../../localization/Localization";
export class InternalTicketCreationDTO {
    conversationId?: number | string;
    conversationReasonId: number;
    conversationName: string;
    internalTitle: string;
    internalContent: string;
    files?: File[];

    constructor(json: InternalTicketCreationDTO) {
        this.conversationId = json.conversationId;
        this.conversationReasonId = json.conversationReasonId;
        this.conversationName = json.conversationName;
        this.internalTitle = json.internalTitle;
        this.internalContent = json.internalContent;
        this.files = json.files;
    }
}

export class ExternalTicketCreationDTO {
    conversationReasonId: number | string;
    conversationName: string;
    externalTitle: string;
    externalContent: string;
    participantEmail: string;
    files?: File[];

    constructor(json: ExternalTicketCreationDTO) {
        this.conversationReasonId = json.conversationReasonId;
        this.conversationName = json.conversationName;
        this.externalTitle = json.externalTitle;
        this.externalContent = json.externalContent;
        this.participantEmail = json.participantEmail;
        this.files = json.files;
    }
}

export function AddConversation() {
    const navigate = useNavigate();
    const [conversationReasons, setConversationReasons] = useState<ConversationReasonDTO[]>([]);
    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        formState: { errors },
    } = useForm<InternalTicketCreationDTO>({
        defaultValues: {},
        mode: "onChange",
    });
    watch();

    useEffect(() => {
        ConversationReasonService.getAllConversationReasons().then(resp => {
            setConversationReasons(resp)
        })
    }, [])

    const {
        register: registerExternal,
        handleSubmit: handleSubmitExternal,
        setValue: setValueExternal,
        control: controlExternal,
        watch: watchExternal,
        getValues: getValuesExternal,
        formState: { errors: errorsExternal },
    } = useForm<ExternalTicketCreationDTO>({
        defaultValues: {},
        mode: "onChange",
    });
    watch();

    function getFileNames(): Array<ReactElement> {
        let list: Array<File> | undefined = getValues("files")
        let listStrings: Array<ReactElement> = []
        if (list !== undefined) {
            for (let i = 0; i < list.length; i++) {
                listStrings.push(<p key={list[i].name} style={{ color: "white", fontSize: "13px" }}>{
                    <>{list[i].name} &nbsp;&nbsp;</>
                } </p>)
            }
        }
        return listStrings
    }

    function getFileNamesExternal(): Array<ReactElement> {
        let listExternal: Array<File> | undefined = getValuesExternal("files")
        let listStringsExternal: Array<ReactElement> = []
        if (listExternal !== undefined) {
            for (let i = 0; i < listExternal.length; i++) {
                listStringsExternal.push(<p key={listExternal[i].name} style={{ color: "white", fontSize: "13px" }}>{
                    <>{listExternal[i].name} &nbsp;&nbsp;</>
                } </p>)
            }
        }
        return listStringsExternal
    }


    function addInternalConversation(data: InternalTicketCreationDTO) {
        let formInternal = new FormData()
        formInternal.append("conversationReasonId", data.conversationReasonId.toString())
        formInternal.append("conversationName", getValues("conversationName"))
        formInternal.append("internalTitle", getValues("internalTitle"))
        formInternal.append("internalContent", getValues("internalContent"))

        for (var i = 0; i < getValues("files")!.length; i++) {
            formInternal.append("files", getValues("files")![i])
        }

        InternalTicketService.createInternalConversation(formInternal).then(() => {
            SwalService.showSwalMessage(strings.successfullyAddedTicket, "", <CheckCircleIcon sx={{ transform: "scale(4)" }} />).then(() => {
                navigate(Routes.CONVERSATIONS_USER);
            })
        }).catch((error) => {
            if (error) {
                SwalService.showSwalMessage(strings.unsuccessfullyAddedTicket, "", <CancelIcon sx={{ transform: "scale(4)" }} />)
            }
        });
    }

    function addExternalConversation(data: ExternalTicketCreationDTO) {
        let formExternal = new FormData()
        formExternal.append("conversationName", getValuesExternal("conversationName"))
        formExternal.append("externalTitle", getValuesExternal("externalTitle"))
        formExternal.append("externalContent", getValuesExternal("externalContent"))
        formExternal.append("participantEmail", getValuesExternal("participantEmail"))
        formExternal.append("conversationReasonId", data.conversationReasonId.toString())
        for (var i = 0; i < getValuesExternal("files")!.length; i++) {
            formExternal.append("files", getValuesExternal("files")![i])
        }

        ExternalTicketService.createExternalConversation(formExternal).then(() => {
            SwalService.showSwalMessage(strings.successfullyAddedTicket, "", <CheckCircleIcon sx={{ transform: "scale(4)" }} />).then(() => {
                navigate(Routes.HOMEPAGE);
            })
        }).catch((error) => {
            if (error) {
                SwalService.showSwalMessage(strings.unsuccessfullyAddedTicket, "", <CancelIcon sx={{ transform: "scale(4)" }} />)
            }
        });
    }

    function handleCancel() {
        navigate(-1);
    }

    return (
        <>
            <Grid container alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"center"} spacing={0} rowGap={0}>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                    <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content" header={true} title="New ticket">
                        <Grid container className="mt-5 mb-5" justifyContent={"center"}>
                            {StorageService.hasAuthToken() &&
                            <div className="m-3">

                                <Form onSubmit={handleSubmit(addInternalConversation)} className="text-left mt-3 roundCorner">
                                <Paper sx={{background:"var(--gradient)"}} className="form" elevation={24}>
                                    <Grid className="roundCorner" container spacing={4}>
                                        <Grid item xs={12} lg={6} md={6} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.name}
                                                </InputLabel>
                                                <Tooltip title={strings.enterTicketName} placement="top-start" open={errors.conversationName !== undefined}>
                                                <TextField
                                                    {...register("conversationName", { required: true })}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.conversationName !== undefined}
                                                    sx={{ background: 'white', borderRadius: '6px' }}
                                                />
                                                </Tooltip>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} lg={6} md={6} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.title}
                                                </InputLabel>
                                                <Tooltip title={strings.enterTitle} placement="top-start" open={errors.internalTitle !== undefined}>
                                                <TextField
                                                    {...register("internalTitle", { required: true })}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errors.internalTitle !== undefined}
                                                    sx={{ background: 'white', borderRadius: '6px' }}
                                                />
                                                </Tooltip>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.content}
                                                </InputLabel>
                                                <Tooltip title={strings.enterContent} placement="top-start" open={errors.internalContent !== undefined}>
                                                <TextField
                                                    {...register("internalContent", { required: true })}
                                                    variant="outlined"
                                                    rows={3}
                                                    error={errors.internalContent !== undefined}
                                                    fullWidth={true}
                                                    multiline={true}
                                                    sx={{ background: 'white', borderRadius: '6px' }}
                                                />
                                                </Tooltip>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <InputLabel htmlFor="conversationReasonId" sx={{color: "white"}}>{strings.reason}</InputLabel>
                                            <Tooltip title={strings.chooseReason} placement="top-start" open={errors?.conversationReasonId?.type === "required"}>
                                                <FormControl className="relative" style={{ width: "100%" }} size="small">
                                                    <Controller
                                                        rules={{ required: true }}
                                                        control={control}
                                                        name="conversationReasonId"
                                                        render={({ field: { onChange, value } }) => (
                                                            <Select

                                                                onChange={onChange}
                                                                value={value ? value : ""}
                                                                error={errors?.conversationReasonId?.type === "required"}
                                                                fullWidth
                                                                native={false}
                                                                displayEmpty={true}
                                                                sx={{ backgroundColor: "white", textAlign: "start", borderRadius: "6px" }}
                                                            >
                                                                {conversationReasons?.map(item => {
                                                                    return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        )}></Controller>
                                                </FormControl>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.files}
                                                </InputLabel>
                                                <Grid display="flex" justifyContent="start">
                                                    <input
                                                        {...register("files")}
                                                        style={
                                                            { display: "none" }}
                                                        accept="*"
                                                        id="raised-button-file"
                                                        type="file"
                                                        multiple
                                                    />
                                                    {getFileNames()}
                                                </Grid>
                                                <label htmlFor="raised-button-file" className="w-100">
                                                    <Button component="span" style={{ width: "100%", height: "80%", display: "flex", flexDirection: "column", fontFamily: 'Varsity, sans-serif', border: "1px solid #ffffff", borderRadius: "2px" }}>
                                                        <p style={{ color: '#ffffff', marginBottom: "5px", fontSize: "10px" }}>{strings.clickHere}</p>
                                                        <p style={{ color: '#ffffff', marginBottom: "5px", fontSize: "10px" }}>{strings.to}</p>
                                                        <p style={{ color: '#22FF90', marginBottom: "5px", fontSize: "10px" }}>{strings.browseFiles}</p>
                                                    </Button>
                                                </label>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                    
                                    </Paper>
                                    <Grid className="createConversation d-flex justify-content-end">
                                        <Button onClick={handleCancel} className="purpleBtn yellowBtn cancel mt-3">{strings.cancel}</Button>
                                        <Button type="submit" className="greenBtn mt-3">{strings.cancel}</Button>
                                    </Grid>
                                    </Form>
                                </div>
                                }
                            {!StorageService.hasAuthToken() &&
                            <div style={{width:"85%"}}>
                                <Form onSubmit={handleSubmitExternal(addExternalConversation)} className="text-left mt-3 roundCorner">
                                <Paper sx={{background:"var(--gradient)"}} className="form" elevation={24}>
                                    <Grid className="roundCorner" container spacing={4}>
                                        <Grid item xs={12} lg={6} md={6} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.name}
                                                </InputLabel>
                                                <Tooltip title={strings.enterTicketName} placement="top-start" open={errorsExternal.conversationName !== undefined}>
                                                <TextField
                                                    {...registerExternal("conversationName", { required: true })}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errorsExternal.conversationName !== undefined}
                                                    style={{ background: 'white', borderRadius: '6px' }}
                                                />
                                                </Tooltip>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} lg={6} md={6} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.title}
                                                </InputLabel>
                                                <Tooltip title={strings.enterTitle} placement="top-start" open={errorsExternal.externalTitle !== undefined}>
                                                <TextField
                                                    {...registerExternal("externalTitle", { required: true })}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    error={errorsExternal.externalTitle !== undefined}
                                                    style={{ background: 'white', borderRadius: '6px' }}
                                                />
                                                </Tooltip>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.content}
                                                </InputLabel>
                                                <Tooltip title={strings.enterContent} placement="top-start" open={errorsExternal.externalContent !== undefined}>
                                                <TextField
                                                    {...registerExternal("externalContent", { required: true })}
                                                    variant="outlined"
                                                    rows={3}
                                                    error={errorsExternal.externalContent !== undefined}
                                                    fullWidth={true}
                                                    multiline={true}
                                                    style={{ background: 'white', borderRadius: '6px' }}
                                                />
                                                </Tooltip>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.participantEmail}
                                                </InputLabel>
                                                <Tooltip title={strings.enterParticipantEmail} placement="top-start" open={errorsExternal.participantEmail !== undefined}>
                                                <TextField
                                                    {...registerExternal("participantEmail", { required: true })}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type={"email"}
                                                    error={errorsExternal.participantEmail !== undefined}
                                                    style={{ background: 'white', borderRadius: '6px' }}
                                                />
                                                </Tooltip>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <InputLabel htmlFor="conversationReasonId" sx={{color: "white"}}>{strings.reason}</InputLabel>
                                            <Tooltip title={strings.chooseReason} placement="top-start" open={errorsExternal?.conversationReasonId?.type === "required"}>
                                                <FormControl className="relative" style={{ width: "100%" }} size="small">
                                                    <Controller
                                                        rules={{ required: true }}
                                                        control={controlExternal}
                                                        name="conversationReasonId"
                                                        render={({ field: { onChange, value } }) => (
                                                            <Select
                                                                onChange={onChange}
                                                                value={value ? value : ""}
                                                                error={errorsExternal?.conversationReasonId?.type === "required"}
                                                                fullWidth
                                                                native={false}
                                                                displayEmpty
                                                                sx={{ backgroundColor: "white", textAlign: "start", borderRadius: "6px" }}
                                                            >
                                                                {conversationReasons?.map(item => {
                                                                    return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        )}></Controller>
                                                </FormControl>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <Item>
                                                <InputLabel sx={{color: "white"}}>
                                                    {strings.files}
                                                </InputLabel>
                                                <Grid display="flex" justifyContent="start">
                                                    <input
                                                        {...registerExternal("files")}
                                                        style={
                                                            { display: "none" }}
                                                        accept="*"
                                                        id="raised-button-file"
                                                        type="file"
                                                        multiple
                                                    />
                                                    {getFileNamesExternal()}
                                                </Grid>
                                                <label htmlFor="raised-button-file" className="w-100">
                                                    <Button component="span" style={{ width: "100%", height: "80%", display: "flex", flexDirection: "column", fontFamily: 'Varsity, sans-serif', border: "1px solid #ffffff", borderRadius: "2px" }}>
                                                        <p style={{ color: '#ffffff', marginBottom: "5px", fontSize: "10px" }}>{strings.clickHere}</p>
                                                        <p style={{ color: '#ffffff', marginBottom: "5px", fontSize: "10px" }}>{strings.to}</p>
                                                        <p style={{ color: '#22FF90', marginBottom: "5px", fontSize: "10px" }}>{strings.browseFiles}</p>
                                                    </Button>
                                                </label>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                   
                                    </Paper>
                                    <Grid className="createConversation d-flex justify-content-end">
                                        <Button onClick={handleCancel} className="purpleBtn yellowBtn cancel mt-3">{strings.cancel}</Button>
                                        <Button type="submit" className="greenBtn mt-3">{strings.create}</Button>
                                    </Grid>
                                    </Form>
                                </div>
                                }
                        </Grid>
                    </CenteredCardLayout>
                </Grid>
            </Grid>
        </>
    )
}