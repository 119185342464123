import { FaqDTO } from "../../models/faq/FaqDTO";
import axios from "../../network/AxiosInstance";
import { Page } from "../../pagination/Page";


export class FAQService {

    static async getAllFAQPaged(page: number, size: number): Promise<Page<FaqDTO>> {
        const response = await axios.get<Page<FaqDTO>>(
            `/rest/faq/all-paged`, { params: { page, size } }
        );
        const data = response.data

        return new Page<FaqDTO>(
            data.content.map(faq => new FaqDTO(faq)),
            data.totalElements
        )
    }

    static async getAllFAQ(): Promise<Array<FaqDTO>> {
        const response = await axios.get<Array<FaqDTO>>(`/rest/faq/all`);
        const data = response.data;
        return data.map(faq => new FaqDTO(faq));
    }

    static async createFAQ(form: FormData): Promise<FaqDTO> {
        const response = await axios.post<FaqDTO>(`/rest/faq/create`, form);
        const data = response.data;
        return new FaqDTO(data);
    }

    static async downloadFileWithNoPrivileges(name: string, id: number) {
        await axios.get(
            `/rest/file/download-no-privileges/` + id, {
            responseType: 'blob'
        }
        ).then((response) => {
            var blob = response.data
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = name;
            alink.click();
        })
    }

    static async downloadFile(name: string, id: number) {
        await axios.get(
            `/rest/file/download/` + id, {
            responseType: 'blob'
        }
        ).then((response) => {
            var blob = response.data
            const fileURL = window.URL.createObjectURL(blob);
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = name;
            alink.click();
        })
    }

}

