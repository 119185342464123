import {parse, format} from "date-fns";
import dayjs, { Dayjs } from "dayjs";

export class DateTimeUtils {
    static parseTimeHHmm(value: string): Date {
        return parse(value, 'HH:mm', new Date());
    }

    static parseTimeWithOffset(value: string): Date {
        return parse(value, 'HH:mmxxxxx', new Date());
    }

    static formatTimeWithOffset(value: Date): string {
        return format(value, 'HH:mmxxxxx');
    }

    static formatDate(date: number | undefined | string) {
        if (date == null) {
            return '';
        }
        const d = new Date(date);
        return DateTimeUtils.formatDateObject(d);
    }

    static formatDateObject(date: Date | undefined): string {
        if (date === undefined){
            return "";
        }
        return format(date, "dd.MM.yyyy HH:mm");
    }
    
    static formatTime(date: string): string {
        return DateTimeUtils.formatTimeObject(DateTimeUtils.parseTimeWithOffset(date));
    }

    static formatTimeObject(time: Date): string {
        return format(time, "HH:mm");
    }

    static formatDateDayJs(date: Dayjs | null): string | null {
        if (!date) {
            return null;
        }
        return dayjs(date.toDate()).format("YYYY-MM-DD")
    }
}
