import { TicketDTO } from "../../models/internal-ticket/TicketDTO";
import axios from "../../network/AxiosInstance";


export class ExternalTicketService {

    static async createExternalConversation(form: FormData): Promise<TicketDTO> {
        const response = await axios.post<TicketDTO>(`/rest/external-ticket/create`, form);
        const data = response.data;
        return new TicketDTO(data);
    }

}