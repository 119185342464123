export class AffiliateInfoDTO {
   name:string;
   affiliateUserId: number;
   profilePicture: Uint8Array;
   percentage: number;
   euros:number;
   kixxTokens:number;
   constructor(json:AffiliateInfoDTO){
    this.name = json.name;
    this.affiliateUserId = json.affiliateUserId;
    this.profilePicture = json.profilePicture;
    this.percentage = json.percentage;
    this.euros = json.euros;
    this.kixxTokens = json.kixxTokens;
   }


}