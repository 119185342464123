import { Button, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import { strings } from "../../localization/Localization";
import { ConversationDTO } from "../../models/conversation/ConversationDTO";
import { ConversationStatusDTO } from "../../models/conversation/ConversationStatusDTO";
import { ConversationTypeDTO } from "../../models/conversation/ConversationTypeDTO";
import { UserConversationFilter } from "../../models/filters/conversation/UserConversationFilter";
import { Routes } from "../../router/Routes";
import { ConversationService } from "../../services/tickets/ConversationService";
import { ConversationStatusService } from "../../services/tickets/ConversationStatusService";
import { ConversationTypeService } from "../../services/tickets/ConversationTypeService";
import { UserService } from "../../services/users/UserService";
import { PageTable } from "../../shared-components/table/PageTable";
import { StorageKeys } from "../../storage/StorageKeys";
import "./conversations.css";

export function ConversationsUser() {
    const navigate = useNavigate();
    const token = localStorage.getItem(StorageKeys.AUTH_TOKEN);
    const [conversations, setConversations] = useState<ConversationDTO[]>([]);
    const [conversationStatuses, setConversationStatuses] = useState<ConversationStatusDTO[]>([]);
    const [conversationTypes, setConversationTypes] = useState<ConversationTypeDTO[]>([]);
    const [name, setConversationName] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [conversationStatusId, setConversationStatusId] = useState<number | undefined>(undefined);
    const [conversationTypeId, setConversationTypeId] = useState<number | undefined>(undefined);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [totalElements, setTotalElements] = useState<number>(0);

    useEffect(() => {
        async function getAllConversationStatuses() {
            const response =
                await ConversationStatusService.getAllConversationStatuses();
            response.unshift({id:0,description:"placeholder",name:strings.status})
            setConversationStatuses(response);
        }
        async function getAllConversationTypes() {
            const response =
                await ConversationTypeService.getAllConversationTypes();
            response.unshift({id:0,description:"placeholder",name:strings.type})
            setConversationTypes(response);
        }
        getAllConversationStatuses().then(() => { });
        getAllConversationTypes().then(() => { });
    }, []);

    useEffect(() => {
        async function getUserInfo(token: string | null) {
            if (token != null) {
                await UserService.getLoggedUser(token)
                    .then((res) => {
                        getAllConversationsForUser(res.id)
                    })
                    .catch((error) => {
                    });
            }
        }

        getUserInfo(token);
    }, [name, email, conversationStatusId, conversationTypeId])


    async function getAllConversationsForUser(id: number) {
        const response = await ConversationService.findAllPagedForUser(
            id!,
            page,
            rowsPerPage,
            new UserConversationFilter(
                name,
                email,
                conversationStatusId,
                conversationTypeId
            )
        );
        setConversations(response.content);
        setTotalElements(response.totalElements);
        setPage(page);
    }


    const headerRows = [
        {
            id: "name",
            label: strings.name,
            search: true,
            type: "text",
            handleChange: handleChangeConversationName,
            variant: "standard",
        },

        {
            search: true,
            type: "text",
            variant: "standard",
            id: "participantEmail",
            handleChange: handleChangeEmail,
            label: strings.emailAddress
        },

        {
            id: "conversationStatusId",
            value: conversationStatusId,
            label: strings.status,
            search: true,
            elements: conversationStatuses,
            styling: {
                "& .MuiSelect-select": {
                  borderBottom: '1px solid white',
                  textAlign:"start"
              },
              "&::before": {
                borderBottom: '0px !important'
            },
            "&::after":{
              border: "0px"
            },
              svg:{color:"white"}},
            handleChange: handleChangeConversationStatus,
            handleRemoveFilter: handleRemoveConversationStatusFilter,
            type: "dropdown",
            variant: "standard",
        },

        {
            search: true,
            type: "dropdown",
            value: conversationTypeId,
            variant: "standard",
            id: "conversationTypeId",
            label: strings.type,
            elements: conversationTypes,
            handleChange: handleChangeConversationType,
            handleRemoveFilter: handleRemoveConversationTypeFilter,
            styling: {
                "& .MuiSelect-select": {
                  borderBottom: '1px solid white',
                  textAlign:"start"
              },
              "&::before": {
                borderBottom: '0px !important'
            },
            "&::after":{
              border: "0px"
            },
              svg:{color:"white"}}
        }
    ];

    function handleChangeConversationName(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setConversationName(
            event.target.value !== "" ? event.target.value : undefined
        );
        setPage(0);
    }

    function handleChangeEmail(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setEmail(
            event.target.value !== "" ? event.target.value : undefined
        );
        setPage(0);
    }


    function handleChangeConversationStatus(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setConversationStatusId(+event.target.value);
        setPage(0);
    }


    function handleRemoveConversationStatusFilter() {
        setConversationStatusId(undefined);
        setPage(0);
    }

    function handleChangeConversationType(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setConversationTypeId(+event.target.value);
        setPage(0);
    }


    function handleRemoveConversationTypeFilter() {
        setConversationTypeId(undefined);
        setPage(0);
    }

    const rows = conversations.map((element) => {
        return {
            id: element.id,
            values: [
                {
                    type: "text",
                    name: "name",
                    value: element.name,
                },
                {
                    type: "text",
                    name: "participantEmail",
                    value: element.participantEmail,
                },
                {
                    type: "text",
                    name: "statusName",
                    value: element.conversationStatusDTO.name,
                },
                {
                    type: "text",
                    name: "typeName",
                    value: element.conversationTypeDTO.name,
                },
            ],
        };
    });

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setPage(newPage);
    }
    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    function handleOpen(id: number) {
        navigate(Routes.CONVERSATION_DETAILS.replace(":id", id.toString()));
    }

    function handleAddConversation() {
        navigate(Routes.ADD_NEW_CONVERSATION);
    }

    return (
        <>
            <Grid
                container
                alignItems={"flex-start"}
                alignContent={"flex-start"}
                justifyContent={"center"}
                spacing={0}
                rowGap={0}
            >
                <Grid item xs={12} lg={8} md={12} sm={12}>
                    <CenteredCardLayout footer={true} navbar={true} sidebar={true} className="main-content" header={true} title={strings.supportTickets}>
                        <Grid className="conversation mb-2">
                            <Button className="buttonConversation greenBtn mt-3" onClick={handleAddConversation}>{strings.createNewTicket}</Button>
                        </Grid>
                        <Container className="tableContainer">
                        <PageTable
                            headerRows={headerRows}
                            rows={rows}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            totalElements={totalElements}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            clickable={true}
                            handleOpen={handleOpen}
                        />
                        </Container>
                    </CenteredCardLayout>
                </Grid>
            </Grid>
        </>
    );
}
