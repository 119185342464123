export const deStrings = {
    login: "Einloggen",
    emailAddress: "E-mail Adresse",
    participantEmail: "Teilnehmer E-Mail Adresse",
    password: "Passwort",
    forgotYourPassword: "Passwort vergessen?",
    noAccount: "Haben Sie kein Konto?",
    register: "Registrieren",
    welcomeBack: "Willkommen zurück!",
    pleaseLogin: "Bitte loggen Sie sich in Ihr Konto ein.",
    invalidUserOrPassword: "Ungültiger Benutzername oder Passwort.",
    clubsAthletes: "Clubs / Athleten",
    club: "Club",
    athletes: "Athleten",
    athleteName: "Name des Athleten",
    fans: "Fans",
    alreadyHaveAnAccount: "Sie haben bereits ein Konto?",
    addYourDetailsToRegister: "Fügen Sie Ihre Daten hinzu, um sich zu registrieren",
    firstName: "Vorname",
    lastName: "Nachname",
    enterFirstName: "Bitte geben Sie Ihren Vornamen",
    enterLastName: "Bitte geben Sie Ihren Nachnamen",
    enterName: "Bitte geben Sie Ihren Namen",
    enterEmail: "Bitte geben Sie Ihren E-Mail Adresse",
    enterAddress: "Bitte geben Sie Ihren Adresse",
    dateOfBirth: "Geburtsdatum",
    enterDateOfBirth: "Bitte geben Sie Ihren Geburtsdatum",
    mobileNumber: "Telefonnummer",
    enterNumber: "Bitte geben Sie Ihren Telefonnummer",
    confirmPassword: "Passwort bestätigen",
    enterPassword: "Bitte geben Sie ein Passwort",
    matchPassword: "Bitte geben Sie ein passendes Passwort",
    continue: "kontinuieren",
    address: "Adresse",
    country: "Land",
    chooseCountry: "Bitte wählen Sie ein Land aus",
    male: "männlich",
    female: "weiblich",
    resetPassword: "Passwort zurücksetzen",
    resetLabel: "Passwort",
    passwordLabel: "zurücksetzen",
    chooseResetPassword: "Bitte wählen Sie eine Möglichkeit, Ihr Passwort zurückzusetzen",
    sms: "SMS",
    note: "Hinweis: Handynummer bitte ohne Ländervorwahl oder ohne führende Null eingeben.",
    new: "Neues",
    didntReceive: "Nicht erhalten?",
    clickHere: "klicken Sie hier",
    checkYourNumber: "Überprüfen Sie Ihre Handynummer",
    enterTheCode: "und geben Sie den Code ein",
    associatedSport: "Assoziierter Sport",
    chooseGender: "Bitte wählen Sie ein Geschlecht",
    yourPhoto: "Ihr Foto (max. 2b)",
    chooseSport: "Bitte wählen Sie eine Sportart aus", 
    attachPicture: "Bitte fügen Sie ein gültiges Foto bei",
    associatedClub: "Name des Clubs, mit dem Sie verbunden sind",
    chooseClub: "Bitte wählen Sie einen Verein aus",
    sportDescription: "Sport Beschreibung",
    shortDescription: "kurze Beschreibung",
    enterDescription: "Bitte geben Sie eine kurze Beschreibung ein",
    ImNotRobot: "Ich bin kein Roboter",
    checkCaptcha: "Bitte überprüfen Sie das Captcha",
    dragAndDrop: "Dateien hierher ziehen und ablegen",
    or: "oder",
    browseFiles: "Dateien durchsuchen",
    clubName: "Vereinsname",
    clubRepresentativeName: "Vereinsvertreter Name",
    clubAddress: "Vereinsadresse",
    clubMobile: "Telefonnummer des Vereins",
    enterClubName: "Bitte geben Sie einen Vereinsnamen ein",
    representativeName: "repräsentativer Name",
    enterRepresentativeName: "Bitte geben Sie einen repräsentativen Namen ein",
    captcha: "Captcha",
    logout: "Ausloggen",
    clubs: "Clubs",
    ambassadorName: "Botschaftername",
    sportAmbassadors: "Sportbotschafter",
    sportAmbassador: "Sportbotschafter",
    welcome: "Willkommen",
    myProfile: "mein Profil",
    accountingBalance: "Rechnungssaldo",
    transactionHistory: "Transaktionsgeschichte",
    myAffiliates: "My affiliates",
    more: "...mehr",
    athleteAddress: "Adresse des Sportlers",
    dateCreated: "Datum erstellt",
    dateModified: "Datum geändert",
    role: "Rolle",
    affiliateLink: "Affiliate-Link",
    saveChanges: "Änderungen speichern",
    update: "aktualisieren",
    affiliateName: "Affiliate-Name",
    supported: "unterstützt",
    noteAffiliates: "*Hinweis: Die Unterstützung von Affiliates wird in Prozent (%) angegeben. Die Summe aller Affiliates muss 100% ergeben",
    administrator: "Administrator",
    administrators: "Administratoren",
    user: "Benutzer",
    users: "Benutzer",
    athlete: "Athlet",
    support: "Unterstützung",
    supports: "Supports",
    both: "Beide",
    active: "Aktiv",
    inactive: "Inaktiv",
    enabled: "aktiviert",
    userProfile: "Benutzerprofil",
    supportAffiliate: "unterstützen",
    unsupportAffiliate: "ununterstützen",
    supportUser: "Benutzer unterstützen",
    unsupportUser: "Benutzer ununterstützen",
    confirmSupport: "Sind Sie sicher, dass Sie diesen Benutzer unterstützen möchten?",
    confirmUnsupport: "Sind Sie sicher, dass Sie diesen Benutzer nicht mehr unterstützen möchten?",
    cancel: "stornieren",
    confirm: "bestätigen",
    euroWallet: "Euro-Brieftasche",
    tradingWallet: "Trading-Brieftasche",
    shoppingWallet: "Einkaufstasche",
    wallets: "Brieftaschen",
    transactions: "Transaktionen",
    transactionId: "Transaktion ID",
    fromWallet: "aus der Brieftasche",
    toWallet: "zur Brieftasche",
    amount: "Menge",
    status: "Status",
    type: "Typ",
    dateOfCreation: "Erstelldatum",
    dateOfApproval: "Datum der Genehmigung",
    transactionStatus: "Transaktion Status",
    fromUser: "Vom Benutzer",
    toUser: "Zum Benutzer",
    newFaq: "Neue FAQ",
    createNewFaq: "neue FAQ erstellen",
    question: "Frage",
    enterQuestion: "Bitte geben Sie eine Frage ein",
    answer: "Antwort",
    enterAnswer: "Bitte geben Sie eine Antwort ein",
    files: "Dateien",
    to: "um",
    browseFilesFAQ: "Dateien zu durchsuchen",
    create: "erstellen",
    name: "Name",
    mine: "Meine",
    details: "Einzelheiten",
    assigned: "Zugewiesen",
    createNewTicket: "neues Ticket erstellen",
    content: "Inhalt",
    enterContent: "Bitte geben Sie den Inhalt ein",
    enterTicketName: "Bitte geben Sie den Ticketnamen ein",
    title: "Titel",
    enterTitle: "Bitte geben Sie einen Titel ein",
    reason: "Anlass",
    chooseReason: "Bitte wählen Sie einen Anlass aus",
    enterParticipantEmail: "Bitte geben Sie eine gültige Teilnehmer-E-Mail ein",
    assignTicket: "Ticket zuweisen",
    closeTicket: "Ticket schliessen",
    reassignTicket: "Ticket neu zuweisen",
    readMore: "... mehr lesen",  
    showLess: "weniger zeigen",
    confirmAssign: "Zuweisung bestätigen",
    confirmAssignTicket: "Sind Sie sicher, dass Sie dieses Ticket zuweisen möchten?",
    confirmClose: "Schliessen bestätigen",
    confirmCloseTicket: "Sind Sie sicher, dass Sie dieses Ticket schließen möchten?",
    supportTickets: "Unterstützungstickets",
    enterNewMessage: "Neue Nachricht eingeben*",
    cantUploadPhoto: "Foto kann nicht hochgeladen werden!",
    successfullyUpdate: "Erfolgreich aktualisiert!",
    sumPercentage: "Die Summe der Prozentsätze muss 100% betragen!",
    successfullyAddedTicket: "Neues Support-Ticket erfolgreich hinzugefügt!",
    unsuccessfullyAddedTicket: "Neues Support-Ticket erfolglos hinzugefügt!",
    successfullyAddedFAQ: "Neue FAQ erfolgreich hinzugefügt!",
    unsuccessfullyAddedFAQ: "Neue FAQ erfolglos hinzugefügt!",
    successfullyClosedTicket: "Ticket erfolgreich geschlossen!",
    unsuccessfullyClosedTicket: "Ticket erfolglos geschlossen!",
    successfullyAssignedTicket: "Ticket erfolgreich zugewiesen!",
    alreadyAssignedTicket: "Ticket ist bereits vergeben!",
    successfullyReassignedTicket: "Ticket erfolgreich neu zugewiesen!",
    unsuccessfullyReassignedTicket: "Ticket erfolglos neu zugewiesen!",
    successfullyAddedNewMessage: "Neue Nachricht erfolgreich hinzugefügt!",
    unsuccessfullyAddedNewMessage: "Neue Nachricht erfolglos hinzugefügt!",
    alreadySupportUser: "Sie unterstützen diesen Benutzer bereits!",
    alreadyDontSupportUser: "Sie unterstützen diesen Benutzer nicht!"
}