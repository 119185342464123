import { Page } from "../../components/Page";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Octagon } from "../../components/Octagon";
import ReactCodeInput from 'react-code-input';
import 'react-phone-input-2/lib/style.css'
import { Box, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import { Routes } from "../../router/Routes";
import './reset-password.css';
import { UserService } from "../../services/users/UserService";
import { strings } from "../../localization/Localization";


export function ResetPasswordCode() {
    const [setAnimation, setAnimationState] = useState(false);
    const [mobileCode, setMobileCode] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));   

    const sendCode = (e: React.MouseEvent<HTMLButtonElement>) =>{
        UserService.resetPasswordCode(parseInt(mobileCode)).then(resp => {
            localStorage.setItem("passwordResetCode",resp)
            setAnimationState(true)

            setTimeout(() => {
                setAnimationState(false)
                localStorage.setItem("verificationToken", resp)
                navigate(Routes.NEWPASSWORD);
            }, 500);
        })
    }

    const mobilePhoneForm = <div className="container">
        <h2 className="mt-3 mb-3" style={{ color: '#FFF', fontWeight: 'bold' }}>{strings.resetLabel} <br /> {strings.passwordLabel}</h2>
        <p className="h5 text-center mt-4" style={{ color: '#FFF' }}>
            {strings.checkYourNumber} <br /> 070 ***** 12 <br/> {strings.enterTheCode}
        </p>
        <form className="mt-2">
        <div className="d-flex justify-content-center align-items-center form-group mb-4">
                <div>
                    <ReactCodeInput inputMode="numeric" type="number" name="mobileCode" value={mobileCode} fields={6} autoFocus={true} onChange={e => setMobileCode(e)}/>
                </div>
            </div>
            <div className="mt-5 ml-2 form-group">
                <Button className="btn-sm borderedButton" onClick={(e) => sendCode(e)}>
                    <Box component="span" className="borderedButtonBox">
                        {strings.continue}
                    </Box>
                </Button>
            </div>
        </form>
        <p className="h5 text-center mt-4" style={{ color: '#FFF', fontSize: '12px' }}>
            {strings.didntReceive} <Link style={{ color: '#22FF90', textDecoration: 'none', fontWeight: 'bold', textTransform: "uppercase" }} to="/reset-password-mobile" className="cursor-pointer">
                {strings.clickHere}
            </Link>
        </p>
    </div>

    return (
        <Page 
            component={
                <>
                    <Logo className="mt-3" />
                    <div className="container">
                        <div className="d-flex justify-content-center mt-5">
                            {matches ? <Octagon elements={mobilePhoneForm} classAnimate={setAnimation ? "container-out" : "container-in"} size={450} /> : <>{mobilePhoneForm}</>}
                        </div>
                    </div>
                </>
            }
        />
    );
}
