import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Octagon } from "../../components/Octagon";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { Box, Button, ButtonGroup, Container, Grid, InputLabel, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Routes } from "../../router/Routes";
import { UserService } from "../../services/users/UserService";
import { CenteredCardLayout } from "../../components/CenteredLayout";
import TextField from "@mui/material/TextField";
import { strings } from "../../localization/Localization";


export function ResetPasswordMobile() {
    const [setAnimation, setAnimationState] = useState<boolean>(false);
    const [mobileNumber, setMobileNumber] = useState<string>("");
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));   
    const [fieldChosen, setField] = useState<boolean>(true)
    const [email,setEmail] = useState<string>("")

    const navigate = useNavigate();

    const resetPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(fieldChosen){
        UserService.sendSms(mobileNumber).then(resp => {
            setAnimationState(true)

            setTimeout(() => {
                setAnimationState(false)
                navigate(Routes.RESETPASSWORDCODE);
            }, 500);
        })
    }
    else{
        UserService.sendEmail(email).then(resp => {
            setAnimationState(true)

            setTimeout(() => {
                setAnimationState(false)
                navigate(Routes.RESETPASSWORDCODE);
            }, 500);
        })
    }
    }

    function emailChange(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void {
        setEmail(e.target.value)
    }

    const resetForm = <Grid container direction="column" width="75%" justifyContent="center">
        <Grid item marginTop={5} marginBottom={3}>
            <Typography variant="h3" color="white">
                {strings.resetPassword}
            </Typography>
            <Typography variant="body2" color="white">
                {strings.chooseResetPassword}
            </Typography>
        </Grid>
        <Grid item marginBottom={2}>
            <ButtonGroup variant="text" fullWidth sx={{ justifyContent: "space-around" }}>
                <Button sx={{ mr: 1 }} className="greenBtn" onClick={() => setField(true)}>
                    {strings.sms}
                </Button>
                <Button className="greenBtn" onClick={() => setField(false)}>
                    {strings.emailAddress}
                </Button>
            </ButtonGroup>
        </Grid>
        <Grid item sx={{ width: "75%", margin: "auto", textAlign: "left" }}>
            {fieldChosen ? <>
            <label className="d-flex" style={{ color: 'white' }}>{strings.mobileNumber}</label>
                <PhoneInput regions={"europe"}
                    inputClass={"form-control"}
                    inputStyle={{ width: "100%" }}
                    buttonStyle={{ borderRadius: "6px" }}
                    value={mobileNumber}
                    onChange={phone => setMobileNumber(phone)}></PhoneInput>
                <Typography variant="caption" color="white">
                    {strings.note}
                </Typography>
                </> :
                <>
                    <InputLabel sx={{ color: 'white', float: "left" }} htmlFor="email">{strings.emailAddress}</InputLabel>
                    <TextField
                        fullWidth
                        onChange={(e) => emailChange(e)}
                        sx={{backgroundColor:"white", borderRadius:"6px"}}
                        id="email"
                        type="email"
                        size="small"
                    />
                </>}
        </Grid>
        <Grid item marginTop={3}>
            <Button className="btn-sm greenBtn" onClick={(e) => resetPassword(e)}>
                {strings.continue}
            </Button>
        </Grid>
    </Grid>

    return (
        <Container disableGutters>
            <Logo className="mt-3" />
            <div className="container">
                <div className="d-flex justify-content-center mt-4">
                    {matches ?
                        <Octagon elements={resetForm} classAnimate={setAnimation ? "container-out" : "container-in"} size={550} />
                        :
                        <>{resetForm}</>}
                </div>
            </div>
        </Container>
    );
}
