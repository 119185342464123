import { Page } from "../../components/Page";
import { Logo } from "../../components/Logo";
import { useNavigate } from "react-router-dom";
import './Homepage.css'
import { Routes } from "../../router/Routes";
import { Button, Stack } from "@mui/material";
import { strings } from "../../localization/Localization";


export function Homepage() {

  const navigate = useNavigate();

  function navigateToLogin() {
    navigate(Routes.LOGIN);
  }

  function navigateToRegister() {
    navigate(Routes.REGISTER);
  }

  return (
    <Page
      component={
        <>
          <Logo width={"28%"} height={"36%"} marginTop={"15%"} />
          <br/><br/><br/><br/>
          <Stack className="buttons-stack" alignItems={"center"} justifyContent={"center"} direction="row">
            <Button className="greenBtn homepage-buttons" onClick={navigateToLogin}>
              {strings.login}
           </Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button className="greenBtn homepage-buttons" onClick={navigateToRegister} >
              {strings.register}
            </Button>
          </Stack>
        </>
      }
    />
  );
}
